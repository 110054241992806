//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

// * {
//     scroll-margin-top: 40px;
// }

body {
    color: $deep-blue;
    font-size: 1.8rem;
    font-family: $font-family;
    font-weight: 400;
}

p {
    line-height: 1.2;
    margin-bottom: 1.4em;
}

li {
    line-height: 1.2;
}

strong,
b {
    font-weight: 600;

    &.bolder {
        font-weight: 800;
    }
}

img {
    height: auto;
    max-width: 100%;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p {
    margin-top: 2rem;
}

a {
    text-decoration: underline;
    color: $deep-blue;

    &:hover {
        text-decoration: none;
        color: $navy;
    }

    &[href*="mailto:"] {
        overflow-wrap: break-word;
    }
}

a.button,
a.btn {
    &:hover {
        background-color: $cyan;

        .btn--arrow:after {
            content: url('../assets/images/arrow_forward_24_navy.svg');
            background-image: url('../assets/images/arrow_forward_24_navy.svg');
        }
    }
    
}

button:not(.site-search__btn, .accordion__header, .frm_button_submit, .slick-arrow, [role="tab"], .navbar-toggler, .video-container__play, .filter-toggle, .cli-modal-close) {
    background-color: transparent;
    color: $font-clr;
    padding: 7px 34px 7px;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid $font-clr;
    border-radius: 4px;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;

    + button {
        margin-left: 2rem;
        @include media-breakpoint-down(md) {
            margin-left: 0;
        }
    }

    &:hover {
        background-color: $font-clr;
        color: white;
    }
}

a.link {
    // text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        text-decoration: underline;
    }

    + .link {
        margin-left: 3rem;
    }
}


// FOCUS-VISIBLE
// -----------------------------------------------------------------------------
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
.js-focus-visible .focus-visible {
    outline: 1px dashed var(--primary);
}

html[data-useragent*="rv:11.0"] {
    
}

// OBJECTFIT
// -----------------------------------------------------------------------------
div {
    &.objectfit {
        height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: auto;
            
        }
    }
}

.full-background {
    position: relative;
    padding: 17rem 0;

    @include media-breakpoint-down(md) {
        padding: 10rem 0;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

svg.icon {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    fill: currentColor;
    position: relative;
    top: 3px;
    margin: -2px 5px 0 0;
}

.icon {
    svg,
    img {
        height: 4rem;
        width: 4rem;
    }
}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: 2rem;
        z-index: 1000;
        background: #000000;
        padding: 1rem 1.8rem;
        border: 4px solid white;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.alignright {
    float: right;
    margin: 5px 0px 10px 15px;
}
.alignleft {
    float: left;
    margin: 5px 15px 10px 0px;
}
.aligncenter {
    clear: both; 
    display: block; 
    margin-left: auto; 
    margin-right: auto;
    padding: 10px;
}
.alignnone {
    margin: 10px 0;
}

.line-mask {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;

    img {
        object-fit: revert;
        mix-blend-mode: overlay;
        transition: clip-path 1s;
        will-change: clip-path;
        height: 100%!important;
        width: 100%!important;
        pointer-events: none;
    }

    img:nth-of-type(1) {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    img:nth-of-type(2) {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
        position: absolute;
        left: 0;
    }

    &--animated {
        img {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
        }
    }

    @include media-breakpoint-down(lg) {
       left: -355px;
    }

    @include media-breakpoint-down(md) {
        display: none !important;

        .lines {
            display: none !important;
        }
    }
}

.lines-container {
    .lines {
        img {
            object-fit: revert;
            mix-blend-mode: overlay;
            transition: clip-path 1.5s ease-in;
            transition-delay: 0.5s;
            will-change: clip-path;
        }

        img:nth-of-type(1) {
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        }

        img:nth-of-type(2) {
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
            position: absolute;
            right: 0;
        }
    }

    &--animated {
        .lines {
            img {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .lines {
            display: none !important;
        }
    }
}

[role="region"]:not(.hero):not([aria-label="Map"]),
.content-image-blocks__section {

    @include media-breakpoint-up(md) {
        transition: opacity .4s;
        opacity: 0;

        &.fadein {
            opacity: 1;
        }
    }
}