//******************************************************************************
// FORMS & ERRORS
//******************************************************************************

// Base Inputs
// -----------------------------------------------------------------------------
.search-field {
    @extend .gamma;
    border: none!important;
    font-size: 4.8rem!important;
    color: #fff;
    padding-left: 0!important;

    &:focus-visible {
        outline: none!important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 1.8rem!important;
    }
}

.search-form {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff!important;

    > input {
        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }

        &:before {
            content: '';
            display: flex;
            width: 1px;
            height: 64px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}

.search-submit {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    &:after {
        content: '';
        background-image: url('../assets/images/arrow_forward_24_white.svg');
        height: 24px;
        width: 24px;
        display: flex;
        margin-left: 8px;
    }
}

#loading-spinner {
    position: absolute;
    bottom: -24px;
}

.frm_rootline_single {
    input {
        border: none!important;
    }
}

input:not([type="submit"], [type="radio"], [type="checkbox"]),
select,
textarea {
    border: 1px solid $navy;
    border-radius: 4px;
    width: 100%;
    min-width: 100px;
    font-size: 16px;
    padding: 14px 18px;
    background: transparent;
    transition: all 0.6s ease;

    &:hover {
        border: 1px solid $cyan;
    }
}

input:not([type="submit"], [type="radio"], [type="checkbox"]),
select {
    height: 64px;
}

textarea {
    height: auto;
}

select {
    font-size: 18px;
    padding: 12px 20px; 
    max-width: 25rem;
    height: 64px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 1px solid #cbcbc7;
    background: #fff;
    top: 3px;
    padding: .1rem;
    display: inline-block;
    vertical-align: middle;
    appearance: none;

    &:checked {
        background: red;
    }
}

.sf-field-search {
    input.sf-input-text {
        padding: 0 !important;
        border: none !important;
        border-radius: 0 !important;
        font-size: 18px !important;
    }

    > label {
        border-bottom: 1px solid $deep-blue !important;
        width: 100%;
    }
}




// Placeholder
// -----------------------------------------------------------------------------
::-webkit-input-placeholder {
    color: $font-clr;
}
::-moz-placeholder {
    color: $font-clr;
}
:-ms-input-placeholder {
    color: $font-clr;
}
:-moz-placeholder {
    color: $font-clr;
}

// Styles
// -----------------------------------------------------------------------------
.form {
    padding: 4rem;

    &--filter {
        ul {
            padding: 0;
        }
    }
}

.frm_fields_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    > .frm_form_field,
    > .frm_submit {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.frm_form_field {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 2rem;
}

.frm_none_container {
    .frm_primary_label {
        display: none;
    }
}

.frm_screen_reader,
.frm_verify {
    display: none;
}

.frm_error_style {
    margin-bottom: 3rem;
}

.frm_error,
.frm_error_style {
    color: #fff!important;
    background-color: #CC012F!important;
    padding: 15px!important;
    margin-top: 8px;
    display: flex;
    align-items: center;

    &:before {
        content: '';
        background-image: url(../assets/images/error.svg);
        display: flex;
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }
}

.frm_forms {
    .frm_message {
        background: none !important;
        border: none !important;
        margin: 0 !important;
        padding: 0 2rem !important;
        text-align: center !important;

        p {
            font-size: 24px !important;
            color: $blue-dark;
        }
    }
}

.frm_forms form {
    --font: $font-family;
    --border-color: #9B9B9B;
    --form-desc-size: 18px;
    --check-font-size: 18px;
    --check-label-color: $font-clr;

    input,
    select,
    textarea {
        background: white !important;
        border: 1px solid #9B9B9B;
        font-size: 16px !important;

        &:hover {
            border: 1px solid $cyan;
        }
    }

    input,
    select {
        // height: 43px !important;
    }

    textarea {
        height: auto !important;
    }

    input[type="checkbox"] {
        box-shadow: none;
        width: 20px !important;
        height: 20px !important;
        vertical-align: middle !important;
        margin: -4px 5px 0px 0 !important;
    }

    .blue-heading {
        > .frm_primary_label {
            font-weight: 600 !important;
            color: $blue-dark !important;
        }
    }

    .frm_opt_container {
        .frm_checkbox {
            margin-bottom: 2rem;
        }
    }

    .frm_primary_label {
        font-size: 16px !important;
        font-weight: 600;
        color: $navy !important;
        margin-bottom: 8px;
    }



    .frm_submit {
        margin-top: 6rem;

        [type="submit"] {
            font-weight: 600 !important;
            background: $navy !important;
            border: none !important;
            font-size: 16px !important;
            display: flex;
            align-items: center;

            &:hover {
                background: $cyan !important;
                color: $deep-blue !important;

                &.frm_button_submit:after,
                &.frm_prev_page:before {
                    background-image: url('../assets/images/arrow_forward_24_deepblue.svg');
                }
            }

            
        }

        .frm_button_submit {
            &:after {
                content: '';
                background-image: url('../assets/images/arrow_forward_24_white.svg');
                display: flex;
                height: 24px;
                width: 24px;
                margin-left: 8px;
            }
        }

        .frm_prev_page {
            color: #fff!important;
            display: flex!important;
            text-transform: none!important;
            padding: 10px 20px!important;
            &:before {
                content: '';
                background-image: url('../assets/images/arrow_forward_24_white.svg');
                transform: rotateY(180deg);
                display: flex;
                height: 24px;
                width: 24px;
                margin-right: 8px;
            }
        }
    }

    .frm_section_heading h3 {
        font-size: 4.8rem!important;
        line-height: 116.66%;
        color: $navy!important;
        border-bottom: 1px solid $navy!important;
    }

    .frm_html_container {
        strong.color {
            display: block;
            margin-top: 4rem;
        }
    }

    .separated-header {
        .frm_primary_label {
            margin: 3rem 0;
        }
    }

    .frm_radio {
        height: 88px;
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 420px;
        padding: 32px 20px;
        border: 1px solid #000;
        border-radius: 8px;
        margin-bottom: 20px!important;

        > label {
            display: flex;
            align-items: center;
        }

        &.blue-border {
            border: 1px solid $cyan;
            background-color: #fff;
            background: rgba(0, 186, 225, 0.1);
        }

        &:hover {
            border: 2px solid $cyan;
            background: rgba(0, 186, 225, 0.1);
        }

        @include media-breakpoint-down(lg) {
            width: 303px;
        }
    }
}

.frm_forms .frm_page_bar input {
    color: $steel!important;
    background-color: #DCDCDC!important;
}

.with_frm_style .frm_radio input[type=radio]:checked {
    border: 1px solid $cyan;
}

.with_frm_style .frm_input_group {
    flex-wrap: nowrap!important;
}

.with_frm_style .frm_inline_box {
    color: #fff!important;
    background-color: $navy!important;
}

// valid input trackers
body.page-template-template-form {

    .big-form .frm_fields_container {

        .frm_form_field .frm_primary_label {
            position: relative;
            transition: padding-left 0.5s ease;
            transition-delay: 1s;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 25px;
                height: 25px;
                transform: scale(0);
                background-image: url(/wp-content/themes/rouge-wp-start-theme/assets/images/valid-input.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 0.35s ease;
                transition-delay: 0.5s;
            }

        }

        // LABEL VALID INPUT
        .frm_form_field.valid-input .frm_primary_label {
            padding-left: 32px;
            transition-delay: 0s;
            
            &+input{
                border-color: #2A6508;
            }

            &:before {
                transform: scale(1) !important;
                background-image: url(/wp-content/themes/rouge-wp-start-theme/assets/images/valid-input.svg) !important;
            }

        }

        // LABEL BLANK
        .frm_form_field.frm_blank_field .frm_primary_label {
            padding-left: 32px;
            transition-delay: 0s;

            &+input{
                border-color: var(--color-red);
                color: $font-clr;
                border-width: 2px;
            }

            &:before {
                transform: scale(1);
                background-image: url(/wp-content/themes/rouge-wp-start-theme/assets/images/invalid-input.svg);
                animation: shake 0.25s;
                animation-delay: 0.75s;
                animation-iteration-count: 1;

                $smallShake: 0.25px;
                $mediumShake: 0.5px;
                $largeShake: 0.75px;

                @keyframes shake {
                  0% { transform: translate($smallShake, $smallShake) rotate(0deg); }
                  10% { transform: translate(- $smallShake, - $mediumShake) rotate(-1deg); }
                  20% { transform: translate(- $largeShake, 0px) rotate(1deg); }
                  30% { transform: translate($largeShake, $mediumShake) rotate(0deg); }
                  40% { transform: translate($smallShake, - $smallShake) rotate(1deg); }
                  50% { transform: translate(- $smallShake, $mediumShake) rotate(-1deg); }
                  60% { transform: translate(- $largeShake, $smallShake) rotate(0deg); }
                  70% { transform: translate($largeShake, $smallShake) rotate(-1deg); }
                  80% { transform: translate(- $smallShake, - $smallShake) rotate(1deg); }
                  90% { transform: translate($smallShake, $mediumShake) rotate(0deg); }
                  100% { transform: translate($smallShake, - $mediumShake) rotate(-1deg); }
                }

            }

        }

    }


}

// timeline

.frm_rootline_single {
    &:nth-child(1) {
        align-items: flex-start!important;

        > input {
            margin: 0 30px!important;

            @include media-breakpoint-down(sm) {
                 margin: 0 5px!important;
            }
        }
    }

    &:nth-child(3) {
        align-items: flex-end!important;

        > input {
            margin: 0 20px!important;

            @include media-breakpoint-down(sm) {
                 margin: 0 5px!important;
            }
        }
    }
    
}

.frm_rootline.frm_show_lines > .frm_rootline_single:after {
    left: 20%!important;
    width: 160%!important;

    @include media-breakpoint-down(sm) {
        left: 40%!important;
        width: 125%!important;
    }
}

// form submitted message

.frm_message {
    > h4 {
        font-weight: 800;
        color: $deep-blue;
        text-align: left!important;
        border-bottom: 1px solid $deep-blue;
        padding-bottom: 16px;
        margin-bottom: 32px;
        text-transform: uppercase;
    }

    > p {
        &:first-of-type {
            text-align: left;
            margin-bottom: 80px;
        }
    }
}