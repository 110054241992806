//******************************************************************************
// SECTIONS
// Styles for the various sections used throughout the site
//******************************************************************************

.twitter-feed-block {
	margin-bottom: 6.8rem;
	padding-bottom: 5rem;

	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
	}

	&__heading {
		padding: 5.5rem 0;
	}

	&__outer {
		margin-top: 10rem;
	}

	.twitter-feed__feed {
		.ctf-item {
			// padding: 0 !important;
			// margin: 0 !important;
			// border: none !important;
			// border-bottom: 2px solid $blue-dark !important;
			border-radius: 8px;

			.ctf-author-box {
				position: absolute;
				left: 0;
				bottom: 1rem;
				width: 7rem;

				.ctf-corner-logo {
					margin-top: -6px;
				}
			}

			@include media-breakpoint-only(md) {
				&:nth-of-type(3) {
					display: none;
				}
			}
		}

		.ctf-tweet-content {
			display: flex !important;
			flex-direction: column-reverse;
			margin-top: 0 !important;
			height: 100%;
			padding: 0 !important;
			background: white;
		}

		.ctf-tweet-media {
			flex-basis: 50%;
			flex-shrink: 0;
			margin: 0 !important;

			a:after {
				display: none !important;
			}

			.ctf-lightbox-link,
			.ctf-media-link {
				margin: 0 !important;
				height: 100% !important;
			}
		}

		#ctf p.ctf-tweet-text {
			padding: 5rem 2.2rem 4rem 2.2rem !important;
			font-size: 2rem;
			line-height: 1.1;
			color: $blue-dark;
			background: white;
			height: 50%;
			flex-grow: 1;
		}

		.ctf-context {
			position: absolute;
			top: 25px;
			left: 0;
			z-index: 1;
			background: white;
			width: auto !important;
			border-radius: 0 0px 10px 0px;
			opacity: 0.9;
		}

		#ctf .ctf-twitter-card .ctf-tc-summary-info {
			padding: 0rem 2%;
			margin-top: -7px;
			width: 76% !important;
		}

		#ctf .ctf-tc-image {
			width: 20% !important;
			height: 88px;
		}

		#ctf .ctf-tweet-media a {
			width: 100%;
			border-radius: 0;
		}

		#ctf .ctf-hide-author .ctf-tweet-meta a {
			font-size: 0;
		}

		#ctf .ctf-tweet-media img,
		#ctf .ctf-tweet-media video {
			height: 25rem;
			object-fit: cover;
			background: rgba(44, 44, 44, 0.17);
			margin: 0;
			margin-bottom: auto;
			border-radius: 0;
			border: none;
			padding: 4rem 2rem;
			font-size: 15px;
			width: 100%;
			flex-shrink: 0;
			opacity: 1 !important;
		}

		#ctf .ctf-twitter-card {
			height: 25rem;
			object-fit: cover;
			background: rgba(44, 44, 44, 0.17);
			margin: 0;
			margin-bottom: auto;
			border-radius: 0;
			border: none;
			padding: 4rem 2rem;
			font-size: 15px;
			width: 100%;
			flex-shrink: 0;

			@include media-breakpoint-down(sm) {
				height: auto;
			}
		}

		.ctf-corner-logo {
			background: $blue-dark;
			border-radius: 50%;
			padding: 0.8rem;
			line-height: 0;
			margin-top: -3rem !important;
			left: 24px !important;
			right: unset !important;
			z-index: 1;

			path {
				fill: white;
			}
		}

		.ctf-tweet-items {
			display: grid;
			grid-gap: 3rem;
			grid-template-columns: repeat(3, 1fr);
			width: 100%;

			@include media-breakpoint-only(md) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include media-breakpoint-down(sm) {
				grid-template-columns: 1fr;
			}
		}

		#ctf .ctf_more {
			display: none;
			// margin-top: 2rem;
			// font-size: 0;
			// text-decoration: none;

			// &:hover {
			// 	text-decoration: underline;
			// 	background: transparent;
			// 	border: none;
			// }

			// &:after {
			// 	content: "READ MORE >";
			// 	font-size: 18px;
			// 	font-weight: 600;
			// }
		}
	}
}

@include media-breakpoint-down(sm) {
	.twitter-feed-block__outer {
		background: rgba(99, 99, 99, .1);

		.bg--grey-light {
			background: transparent;
		}
	}
}

.intro-block {
	margin-top: 10rem;

	&__intro {
		text-align: center;

		.page-id-335 & {
			@extend .standfirst;
			font-weight: 400;
			margin-bottom: 32px;

			>p {
				margin-bottom: 0;
				text-align: left;
			}
		}

		>p {
			color: $navy;
		}

	}

	.page-id-335 & {
		margin-top: 32px;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 6rem;
	}

	&__content {
		margin: 0 auto;
		max-width: 1060px;

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	.video-container {
		margin-top: 7rem;

		@include media-breakpoint-down(sm) {
			margin-top: 5rem;
		}
	}

	.line {
		width: 23.5rem;
		height: 1px;
		background: currentColor;
		margin: 5rem auto;

		@include media-breakpoint-down(sm) {
			margin: 3.5rem auto;
		}
	}

	.members-edit {
		@extend .body-bold;
		text-decoration: none;
		font-weight: 600;
		display: flex;
		align-items: center;

		&:after {
			display: flex;
			content: '';
			background-image: url('../assets/images/arrow_forward_24_navy.svg');
			width: 24px;
			height: 24px;
			margin-left: 8px;
		}

		&:hover {
			&:after {
				background-image: url('../assets/images/arrow_forward_24_deepblue.svg');
			}
		}
	}
}

.intro-block__content-container+.intro-block__content-container {
	margin-top: 4rem;
}

.video-container {
	background: #f5f5f5;
	max-width: 70rem;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	@supports(aspect-ratio: 1 / 1) {
		aspect-ratio: 16 / 9;
	}

	button {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: none;
		border: none;
		font-size: 0;

		&:after {
			display: none;
		}

		&:hover {
			background: transparent;

			svg path {
				fill: $font-clr;
			}
		}
	}

	&__cover,
	iframe {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		object-fit: cover;

		@supports(aspect-ratio: 1 / 1) {
			aspect-ratio: 16 / 9;
			position: absolute;
		}
	}
}

.post-item-grid {
	margin-top: 10rem;
	padding: 10rem 0;

	@include media-breakpoint-down(md) {
		margin-top: 6rem;
		padding: 6rem 3rem;
	}

	.block-header__line {
		background: $blue-dark;
	}

	h3.heading {
		@include fluid-type(2.1rem, 2.4rem);
		line-height: 1.2;
		font-weight: 600;
	}

	&__row {
		.post-item-grid__post-card {
			margin-bottom: 3rem;
		}

		@supports(aspect-ratio: 1 / 1) {
			gap: 3rem 0;

			.post-item-grid__post-card {
				margin-bottom: 0;
			}
		}
	}
}

.filter-toggle {
	display: none !important;
	border: none;
	background: none;
	font-weight: 600;
	width: 100%;
	padding: 1rem 0;
	position: relative;
	text-align: left;
	color: $font-clr;

	&:before,
	&:after {
		content: "";
		height: 1px;
		width: 2.4rem;
		display: block;
		position: absolute;
		background: #707070;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		will-change: transform;
		transition: transform .2s ease-in;
	}

	&:after {
		transform: rotate(90deg);
	}

	@include media-breakpoint-down(md) {
		display: block !important;
	}

	&.open {
		&:after {
			transform: rotate(0);
		}
	}
}

.page-template-template-members-hub {

	.filter-toggle {
		display: none !important;
	}

	.the-hub__filters .searchandfilter h4 {
		display: block;
	}

	@media(max-width: 800px) {
		.filter-toggle {
			display: block !important;
			border-top: 1px solid currentColor;
		}

		.the-hub__filters .searchandfilter h4 {
			display: none;
		}

		.searchandfilter {
			display: none;
		}
	}
}

.cli_messagebar_head {
	font-weight: 600;
	color: $blue-dark;
	font-size: 24px;
	margin-bottom: 1em;
}

//hub section
.hub-section__body {
	.bg--blue {
		padding-bottom: 50px;
	}
}

.hub-section__btn {
	@include media-breakpoint-down(md) {
		display: none;
	}

	&--mobile {
		display: none;

		@include media-breakpoint-down(md) {
			display: flex;
			margin: 24px auto;
		}
	}
}

//Business Groups Contacts

.business-group-card {
	background-color: #fff;
	padding: 32px 24px;
	border-radius: 8px;
	max-width: 343px;
	flex-basis: 343px;
	margin-right: 40px;

	&:nth-child(1) {
		margin-top: 0 !important;
	}

	&:nth-child(2) {
		@include media-breakpoint-down(md) {
			margin-right: 0;
		}
	}

	&:nth-child(3) {
		@include media-breakpoint-down(sm) {
			margin-top: 40px;
		}
	}

	&__contact {
		margin-bottom: 0;
		font-weight: 600;
	}

	&__about {
		margin-bottom: 24px;
	}

	&__back-link {
		background-color: #fff;
		padding: 32px 24px;
		width: 100%;
		border-radius: 8px;
		margin-top: 48px;
	}

	.card-links {
		display: flex;
		align-items: center;
	}

	.card-email {
		display: flex;

		&:hover {
			color: $navy;

			>svg {
				path {
					fill: $navy;
				}
			}
		}
	}

	.card-number {
		display: flex;
		align-items: center;
		text-decoration: none;

		&:before {
			content: '';
			background-image: url('../assets/images/events-call_deepblue.svg');
			height: 20px;
			width: 20px;
			display: flex;
			margin-right: 8px;
			margin-left: 16px;
		}

		&:hover {
			text-decoration: underline;
			color: $navy;

			&:before {
				background-image: url('../assets/images/events-call_navy.svg');
			}
		}
	}

	&s {

		&__wrapper {
			padding-top: 80px;
			padding-bottom: 80px;
			background-color: $light-blue;
			border-radius: 8px;
			margin-top: 72px;
			margin-bottom: 72px;

			@include media-breakpoint-down(lg) {
				padding-bottom: 15px;
				padding-top: 15px;
			}

			@include media-breakpoint-down(sm) {
				border-radius: 0;
			}
		}

		&__container {
			display: flex;
			margin-bottom: 48px;
			flex-wrap: wrap;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&__title {
			text-transform: uppercase;
			font-weight: 800;
			color: $deep-blue;
			margin-bottom: 40px;
		}

		&__link-title {
			margin-bottom: 32px;
			text-transform: uppercase;
			font-weight: 800;
			color: $deep-blue;
		}
	}

	@include media-breakpoint-down(md) {
		max-width: calc(50% - 20px);
		flex-basis: calc(50% - 20px);

		&:last-of-type {
			margin-top: 40px;
		}
	}

	@include media-breakpoint-down(sm) {
		max-width: 100%;
		flex-basis: 100%;
		margin-right: 0;
		margin-top: 40px;
	}
}

.searchresults {
	&-head {
		.searchandfilter {
			display: flex;
			margin-bottom: 48px;

			>ul {
				padding: 0;
			}

			[data-sf-field-input-type=radio] {
				display: flex;
				align-items: center;

				>ul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					padding-left: 16px;
				}

				li {
					position: relative;
					margin-right: 16px;
					padding: 8px 12px;
					border: 1px solid #08384d;
					border-radius: 10px;
					cursor: pointer;

					&:hover {
						background: #00bae1;
						border: 1px solid transparent;
					}

					>input {
						display: none;
					}

					>label {
						padding-left: 0 !important;
						font-weight: 600;
						color: $navy;
						cursor: pointer;
					}
				}

				.sf-option-active {
					background: #00bae1;
					border: 1px solid transparent;
				}
			}

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	&-result {
		font-weight: 600;
		color: $steel;
		margin-bottom: 48px;

		@include media-breakpoint-down(md) {
			margin-top: 60px;
		}
	}

	&-post {
		display: flex;
		flex-direction: column;
	}

	&-title {
		padding-bottom: 28px;
		border-bottom: 1px solid $deep-blue;
		margin-bottom: 48px;
		display: flex;
		justify-content: space-between;

		.search-term {
			text-transform: capitalize;
			color: $deep-blue;
		}
	}
}

#search-filter-form-10476 {
	>ul {
		.sf-field-search {
			display: none;
		}

	}
}

.search__grid {
	a {
		text-decoration: none;
	}
}