//******************************************************************************
// ARTICLES
//******************************************************************************

.searchandfilter {
	[data-sf-field-input-type="checkbox"] {
		ul li {
			padding: 0.8rem 0;
		}
	}

	ul li {
		padding: 1.5rem 0;

		&:first-of-type {
			padding-top: 0;
		}

		&:last-of-type {
			padding-bottom: 0;
		}
	}
}

.the-hub {
	margin-bottom: 80px;

	&__wrapper {
		margin-bottom: 0;

		&.bg--blue {
			.container {
				@include media-breakpoint-up(xl) {
					background: $light-blue;
					border-radius: 8px;
				}

			}

			@include media-breakpoint-up(xl) {
				background: #fff;
			}
		}

		@include media-breakpoint-down(lg) {
			border-radius: 0;
		}
	}

	&__featured {
		background: $the-hub;
		border-radius: 10px;
		margin-top: 80px;

		.featured {
			&__img {
				width: calc(50% - 15px);
				max-height: 403px;
				object-fit: cover;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;

				@include media-breakpoint-down(md) {
					width: 100%;
					border-bottom-left-radius: 0;
					border-top-right-radius: 10px;
				}
			}

			&__category {
				margin-bottom: 0;
				font-weight: 700;
			}

			&__content {
				width: calc(50% + 15px);
				padding: 0 48px;

				.icon {
					margin-bottom: 16px;
				}

				@include media-breakpoint-down(md) {
					width: 100%;
					padding: 32px 24px;
				}
			}

			&__date {
				margin-bottom: 16px;
			}

			&__title {
				color: #fff;
			}
		}
	}

	&__eyebrow {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: #fff;
	}

	&__header {
		position: relative;
		padding-top: 32px;
		padding-bottom: 32px;

		.heading {
			color: $the-hub;


			.page-template-template-members-hub & {
				color: $mint;
				margin-bottom: 32px;
			}
		}

		strong {
			font-weight: 800;
		}

		&-links {
			position: absolute;
			top: 2rem;
			right: 2rem;
			width: 295px;
			display: flex;

			>a {
				margin-left: 0 !important;

				&:first-of-type {
					margin-right: 24px;
				}

				&:hover {
					text-decoration: none;
					color: #fff;
				}
			}

			.link-help {
				display: flex;

				&:after {
					content: "";
					background-image: url('../assets/images/help-navy.svg');
					width: 24px;
					height: 24px;
					margin-left: 8px;
				}
			}

			.link-logout {
				display: flex;

				&:after {
					content: "";
					background-image: url('../assets/images/logout-navy.svg');
					width: 24px;
					height: 24px;
					margin-left: 8px;
				}
			}

			@include media-breakpoint-between(lg, xl) {
				top: 3rem;
			}

			@include media-breakpoint-down(sm) {
				top: -100px;
				left: 15px;
			}
		}

		div {
			z-index: 1;
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: 0;
		}
	}

	&__heading {
		margin-bottom: 16px;
	}

	&__support {
		text-transform: uppercase;
		letter-spacing: 2px;
		color: $steel;
	}

	.the-hub__header__lines {
		z-index: 0;

		img {
			transition: clip-path 1.5s ease-in;
			transition-delay: 0.5s;
		}
	}

	.lines-container--animated {
		.the-hub__header__lines {
			img {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important;
			}
		}
	}

	.members-hub__background {
		background: linear-gradient(to left, rgba(255, 255, 255, 0.1), transparent) !important;
	}

	&__grid {
		display: grid;
		gap: 3rem;
		grid-template-columns: repeat(4, 1fr);
		// grid-template-rows: repeat(4, 1fr);
		grid-template-rows: min-content 1fr;
		grid-template-areas: "one one two three"
			"four five six seven"
			"eight nine ten ten"
			"eleven twelve thirteen fourteen";
		margin-top: 80px;

		.blog & {
			margin-bottom: 80px;
		}

		&-wrapper {
			border-radius: 8px;
			padding-bottom: 80px;


		}

		// > *:nth-child(4n-1):nth-last-of-type(1) {
		// 	grid-column: span 2;
		// }

		// > *:nth-child(4n-2):nth-last-of-type(1) {
		// 	grid-column: span 3;
		// }

		// > *:nth-child(4n-3):nth-last-of-type(1) {
		// 	grid-column: span 4;
		// }

		&-title {
			margin-top: 80px;
			margin-bottom: 32px;
			color: $mint;
			font-weight: 800;
		}


		@include media-breakpoint-down(md) {
			// grid-template-rows: repeat(4, 1fr);
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: min-content 1fr;
			grid-template-areas: "one one two"
				"three four five"
				"six seven eight"
				"nine ten ten"
				"eleven twelve thirteen"
				"fourteen fifteen sixteen";
			margin-top: 40px;
		}

		@media(max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas: "one two"
				"three four"
				"five six"
				"seven eight"
				"nine ten"
				"eleven twelve"
				"thirteen fourteen";
			margin-top: 40px;
		}

		@media(max-width: 600px) {
			grid-template-columns: repeat(1, 1fr);
			grid-template-areas: "one"
				"two"
				"three"
				"four"
				"five"
				"six"
				"seven"
				"eight"
				"nine"
				"ten"
				"eleven"
				"twelve"
				"thirteen"
				"fourteen";
			margin-top: 20px;
		}
	}

	.post-heading {
		color: $navy;
	}

	&__posts {
		>a {
			text-decoration: none;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.post-card {
		border-color: $purple;

		@media(max-width: 600px) {
			grid-column: 1 / -1;
		}
	}

	&__filters {
		@include media-breakpoint-down(sm) {
			.searchandfilter {
				display: none;

				&--open {
					display: block;
				}
			}
		}

		.searchandfilter {
			display: flex;

			@include media-breakpoint-down(xl) {
				font-size: 16px;
			}

			ul {
				padding: 0;
			}

			[data-sf-field-input-type="radio"] {
				display: flex;
				align-items: center;

				input[type=radio] {
					opacity: 0;
					position: fixed;
					width: 0;
				}

				ul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-left: 16px;
				}

				li {
					position: relative;
					margin-right: 16px;
					padding: 8px 12px;
					border: 1px solid $navy;
					border-radius: 10px;

					&:hover {
						background: $cyan;
						border: 1px solid transparent;
						color: $navy;
					}
				}

				label {
					padding-left: 0;
					cursor: pointer;
					font-weight: 600;

					.blog & {
						color: $navy;
					}
				}

				.sf-option-active {

					background: $cyan;
					border: 1px solid transparent;

				}

				li.sf-option-active {
					&:hover {
						label:after {
							transform: none;
						}
					}
				}
			}

			@include media-breakpoint-down(md) {
				display: none;

				&--open {
					display: block;
				}

				h4 {
					display: none;
				}

				[data-sf-field-input-type=radio] ul {
					margin-left: 0;

					li {
						margin-bottom: 1rem;
					}

					label {
						padding-left: 0;
					}


				}
			}

			@include media-breakpoint-only(lg) {
				[data-sf-field-input-type=radio] ul label {
					font-size: .8em;
				}
			}
		}
	}

	&__filters-inner {
		margin-bottom: 48px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 40px;
		}
	}

	.members-hub {
		&__card {
			&-date {
				font-weight: 500;
				margin-top: 16px;
			}
		}
	}

	&--members {
		padding: 0 0 10rem;
		background-image: linear-gradient(to left, $deep-blue, $navy);

		.the-hub__header {
			padding: 160px 0 120px 0;
		}

		.post-card {
			border-color: $blue-dark;
		}

		.the-hub__grid {
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: min-content 1fr;
			grid-template-areas: "one one two"
				"three four five"
				"six seven seven";

			@media(max-width: 1150px) {
				grid-template-columns: repeat(2, 1fr);
				grid-template-areas: "one two"
					"three four"
					"five six"
					"seven eight";
			}

			@media(max-width: 700px) {
				grid-template-columns: repeat(1, 1fr);
				grid-template-areas: "one"
					"two"
					"three"
					"four"
					"five"
					"six"
					"seven";
			}

		}

		.the-hub__body {
			.bg--blue {
				padding-bottom: 50px;
			}

			@media(max-width: 800px) {
				flex-basis: 100%;
				max-width: 100%;
			}
		}

		.the-hub__filters {

			@media(max-width: 800px) {
				flex-basis: 100%;
				max-width: 100%;
				margin-bottom: 4rem;

				ul {
					width: 100%;
				}
			}
		}

		.searchandfilter {
			display: flex;
			flex-direction: row;
			align-items: flex-start;

			[data-sf-field-input-type="radio"] {
				flex-direction: row;
				align-items: flex-start;

				h4 {
					margin-bottom: 1rem;
					width: 95px;
				}

				ul {
					flex-direction: row;
					align-items: flex-start;
					flex-wrap: wrap;
					margin-left: 16px;

					@media(max-width: 767px) {
						margin-left: 0;
					}
				}

				li {
					margin-left: 0;
					margin-bottom: 15px;
					margin-right: 14px;
					border: 1px solid #fff;

					&:last-of-type {
						margin-right: 0;
					}
				}

				label {
					display: flex;
					align-items: center;
				}
			}

			@media(max-width: 800px) {
				display: none;
			}
		}
	}

	.quick-links {
		margin: 0;
		padding-right: 0;

		&__card {
			flex-basis: calc(25% - 20px);
			max-width: calc(25% - 20px);
			margin-right: 26px;
			border-radius: 10px;
			border: #fff 1px solid;
			transition: all 0.6s ease;

			&:last-of-type {
				margin-right: 0;
			}

			&:nth-child(2) {
				@include media-breakpoint-down(md) {
					margin-right: 0;
				}
			}

			@include media-breakpoint-down(md) {
				flex-basis: calc(50% - 20px);
				max-width: calc(50% - 20px);
				margin-bottom: 26px;
			}

			@include media-breakpoint-down(sm) {
				flex-basis: 100%;
				max-width: 100%;
				margin-bottom: 26px;
				margin-right: 0;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.3);

				.quick-links__link .btn--white {
					background-color: $cyan;
					text-decoration: none;
					border: 1px solid $cyan;
					color: $navy !important;
				}
			}
		}

		&__filllink {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;

			&:hover {
				background: transparent;
			}
		}

		&:before {
			display: none;
		}

		@include media-breakpoint-down(md) {
			padding-left: 0;
			margin: 4rem -15px 0;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 15px;
		}
	}

	.filter-toggle {

		color: $navy;

		&:before,
		&:after {
			background: $navy;
		}

		.page-template-template-members-hub & {
			color: #fff;

			&:before,
			&:after {
				background: #fff;
			}
		}

	}

	@include media-breakpoint-down(sm) {
		>.container {
			max-width: 100%;
		}
	}


}

.hub-section {
	margin-top: 20px;
}

.pagination {
	flex: 1 0 100%;
	display: flex;
	justify-content: center;
	padding: 0 8rem;
	grid-column: 1 / -1;

	.nav-links {
		display: inline;

		.page-numbers {
			&:hover {
				color: $steel;

				&::before {
					background-image: url(../assets/images/arrow_forward_24_steel.svg);
				}

				&::after {
					background-image: url(../assets/images/arrow_forward_24_steel.svg);
				}
			}

		}

		.prev,
		.next {
			font-weight: 600;
			display: flex;
			align-items: center;
		}

		.prev {
			position: absolute;
			left: 0;

			&::before {
				content: '';
				background-image: url(../assets/images/arrow_forward_24.svg);
				display: block;
				width: 100%;
				transform: rotate(180deg);
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}
		}

		.next {
			position: absolute;
			right: 0;

			&::after {
				content: '';
				background-image: url(../assets/images/arrow_forward_24.svg);
				display: block;
				width: 100%;
				width: 24px;
				height: 24px;
				margin-left: 10px;
			}
		}
	}

	.prev,
	.next {
		font-weight: 600;
		display: flex;
		align-items: center;
	}

	.next {
		position: absolute;
		right: 0;

		&::after {
			content: '';
			background-image: url(../assets/images/arrow_forward_24_white.svg);
			display: block;
			width: 100%;
			width: 24px;
			height: 24px;
			margin-left: 10px;
		}
	}

	.prev {
		position: absolute;
		left: 0;

		&::before {
			content: '';
			background-image: url(../assets/images/arrow_forward_24_white.svg);
			display: block;
			width: 100%;
			transform: rotate(180deg);
			width: 24px;
			height: 24px;
			margin-right: 10px;
		}
	}

	a {
		display: block;
		float: left;
		padding: 4px 9px;
		margin-right: 7px;
		text-decoration: none;
	}

	span,
	a {
		&.current {
			display: block;
			float: left;
			padding: 4px 9px;
			margin-right: 7px;
			border: 1px solid #efefef;
		}

		&.dots {
			display: block;
			float: left;
			padding: 4px 9px;
			margin-right: 7px;
		}
	}
}

.divider {
	width: 23.5rem;
	height: 1px;
	background: currentColor;
	margin: 1rem 0;
}

.post-content {
	.divider {
		margin: 4rem 0;
	}

	.mobile-only {
		display: none;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	>p {
		color: $navy !important;
	}

	@include media-breakpoint-down(sm) {

		.mobile-only {
			display: inline-block !important;
		}
	}

	.single-post & {

		h2,
		h3,
		h4,
		h5 {
			color: $navy;
		}
	}
}

iframe {
	margin: 4rem 0;

	&[src*="vimeo"],
	&[src*="youtube"] {
		width: 100%;
		height: auto;
		aspect-ratio: 16/9;
	}
}

.post-body {

	&__right {
		>a {
			display: flex;
			font-weight: 600;
			text-decoration: none;
			align-items: center;
			margin-top: 64px;
		}
	}

	.row {
		.post-content {
			.theme--blue {
				.content-heading {
					color: $navy;
				}
			}
		}
	}

	.blockquote {
		margin: 9rem 0 4rem;
	}

	.accordion {
		margin-top: 5rem;
	}

	@include media-breakpoint-down(sm) {
		.post-back-btn {
			display: none !important;
		}

		.mobile-hide,
		.blockquote {
			display: none;
		}
	}

	@media(max-width: 800px) {
		.post-body__left {
			flex-basis: 100%;
			max-width: 100%;
			margin-bottom: 8rem;
		}

		.post-body__right {
			flex-basis: 100%;
			max-width: 100%;
		}
	}

	@media(max-width: 520px) {
		.post-body__left {
			margin-bottom: 0;
		}
	}
}

.post-meta {
	padding-bottom: 3rem;

	body.single-business_groups & {
		margin-bottom: 15rem;

		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
	}

	&__details {
		max-width: 30rem;
	}

	&.icon-big {
		.icon {
			margin-bottom: 5rem;

			svg,
			img {
				width: 12rem;
				height: 12rem;

				@include media-breakpoint-down(sm) {
					width: 8rem;
					height: 8rem;
				}
			}
		}
	}
}

.additional-text-box {
	margin: 6rem 0;

	p {
		overflow-wrap: anywhere;
	}
}

.sponsors {
	// margin-top: 6rem;

	img {
		width: 23rem;
		object-fit: contain;
		max-height: 76px;
		object-position: left;

		+p {
			margin-top: 4rem;

			@include media-breakpoint-down(sm) {
				margin-top: 2rem;
			}
		}
	}

	a {
		+p {
			margin-top: 4rem;

			@include media-breakpoint-down(sm) {
				margin-top: 2rem;
			}
		}
	}

	p {
		margin-bottom: 1.5rem;
	}
}

.icon-list {
	margin: 4rem 0;
	padding: 32px;
	background-color: $light-blue;
	border-radius: 10px;

	&__heading {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		>img {
			margin-right: 16px;
		}

		>p {
			font-weight: 700;
		}
	}

	ul {
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		>li {
			// margin: 0 16px 0 0;
			width: 31%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: underline;

			>a {
				font-size: 16px;
				width: 100%;
				font-weight: 600;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 16px;
			}
		}

		li:not(:last-of-type) {
			margin-bottom: 1.5rem;
		}

		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
			flex-direction: column;
		}
	}

	a {
		text-decoration: none;

		img {
			margin-right: 2px;
		}

		&:hover {
			span {
				text-decoration: underline;
			}
		}
	}
}

.members-hub-back {
	&:before {
		content: "";
		background-image: url('../assets/images/arrow_forward_24_navy.svg');
		height: 24px;
		width: 24px;
		display: flex;
		transform: rotateY(180deg);
		margin-right: 8px;
	}
}

.big-loop-list {
	padding-left: 3rem;
	padding-right: 3rem;

	&__post {
		margin-bottom: 4rem;
		padding-bottom: 3.2rem;
		border-bottom: 1px solid $blue-dark;

		.eyebrow {
			margin-bottom: 16px;
		}

		.heading {
			margin: 0;
		}
	}

	.p {
		margin-bottom: 3rem;
	}
}

.page-template-template-members-hub {
	.the-hub__filters .searchandfilter [data-sf-field-input-type=radio] {
		li.sf-option-active:hover label:after {
			//transform: none;
		}

		input:checked+label:after {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.the-hub__filters .searchandfilter [data-sf-field-input-type=radio] label:hover:after {
		@include media-breakpoint-down(md) {
			left: 13px;
			width: 2rem;
			height: 2rem;
		}
	}

	@include media-breakpoint-down(xl) {
		.the-hub__filters .searchandfilter [data-sf-field-input-type=radio] label:hover:after {
			top: 50%
		}
	}
}