// Post card
// -----------------------------------------------------------------------------

.post-card {
	position: relative;
	display: flex !important;
	flex-direction: column;
	height: 100%;
	border-radius: 10px;
	transition: all 0.6s ease;

	&.Events {
		.post-heading {
			margin-bottom: 16px;
		}
	}
	

	&__meta {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;
	}

	&__eyebrow {
		color: $steel;
		margin-bottom: 0;

		&:first-of-type {
			font-weight: 700;

			.Events & {
				margin-bottom: 16px;
			}
		}
		
	}

	&__header {
		height: 192px;
		position: relative;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;

			.event-logo & {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				object-position: left;
			}
		}

		.event-logo & {
			position: static;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			height: 110px;
		}

		&--logo {
			padding: 4rem;

			img {
				object-fit: contain;
			}

			.event-logo & {
				padding: 0 32px 32px 32px;
			}
		}

		.icon {

			.event-logo & {
				position: absolute;
				top: 32px;
				left: 24px;
			}

			.Events &,
			.Publications &,
			.News &,
			.Video &,
			.Blog &,
			.BriefsConsultations & {
				position: absolute;
				bottom: 24px;
				left: 24px;
			}
			
		}
	}

	&.PressRelease {
		.post-card__body {
			justify-content: center;
			
		}
	}

	.promo-article {
		border-radius: 8px;
	}

	&__body {
		padding: 32px 24px;
		flex-grow: 1;
		border-radius: 0 0 8px 8px;

		.promo-article-cat {
			color: $steel;
			margin-bottom: 16px;
		}

		.icon {
			margin-bottom: 24px;
		}

		.flex-row-reverse & {
			border-radius: 8px 8px 8px 8px;
		}

		a {
			text-decoration: none;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				height: 100%;
				width: 100%;
				display: block;
				background: transparent;
			}
		}



		.event-logo & {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			justify-content: end;

			&.PressRelease {
				justify-content: center;
			}
		}

		&:hover {
			.post-card__footer a {
				text-decoration: underline;
			}
		}
	}

	&__footer {
		margin-top: auto;

		
	}

	&:hover {
		filter: drop-shadow(rgba(0, 81, 112, 0.15) -4px 4px 24px);

		.post-heading {
			text-decoration: underline;
		}
	}
}