//******************************************************************************
// BRAND & SKINS
// colors, specific typography, logos
//******************************************************************************

$bg-white: #fff;

.link {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 150%;

    &--arrow {
        &:after {
            content: url('../assets/images/arrow_right_alt.svg');
            margin-left: 8px;
        }
    }
}

.button,
.btn {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 20px;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    width: fit-content;
    transition: all 0.3s ease;

    &--primary {
        background-color: #08384D;
        color: #fff;

        .modal-open & {
            background-color: #fff;
            color: #08384D;
        }
    }

    &--arrow {
        &:after {
            color: #fff;
            content: '';
            background-image: url('../assets/images/arrow_forward_24_white.svg');
            height: 24px;
            width: 24px;
            display: flex;
            margin-left: 8px;
        }
    }

    &--nav {
        margin-left: 56px;

        @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 24px;
        }
    }

    &--white {
        background-color: #fff;
        padding: 12px 20px;
        border-radius: 10px;
        border: 1px solid #fff;
        text-decoration: none;

        &:hover {
            background-color: $cyan;
            text-decoration: none;
            border: 1px solid $cyan;
            color: $navy!important;

            &.quick-links__link a:after {
                color: #fff;
                content: url('../assets/images/arrow_forward_24_navy.svg');
                margin-left: 8px;
            }
        }

        &.btn--arrow {
            &:after {
                color: #fff;
                content: '';
                background-image: url('../assets/images/arrow_forward_24_navy.svg');
                height: 24px;
                width: 24px;
                display: flex;
                margin-left: 8px;
            }
        }
    }

    &:hover {
        background-color: $cyan;
        text-decoration: none;
        color: $navy!important;

        &.btn--arrow:after,
        &.button--arrow:after {
            color: #fff;
            content: '';
            background-image: url('../assets/images/arrow_forward_24_navy.svg');
            margin-left: 8px;
            height: 24px;
            width: 24px;
            display: flex;  
        }
    }
}


.theme {
    &--purple {

        .heading,
        .color {
            color: $the-hub;
        }

        .line {
            background: $the-hub;
        }

        .blockquote__author svg {
            path {
                fill: $the-hub;
            }
        }

        .post-card,
        &.post-card {
            border-bottom-color: $the-hub;
        }
    }

    &--blue {

        .heading,
        .post-heading,
        .color,
        p strong {
            color: $navy;
            font-weight: 600;
        }


        .frm_form_title,
        .frm_description p {
            color: $blue-dark !important;
            font-family: $font-family !important;
        }

        .line {
            background: $blue-dark;
        }

        .post-card,
        &.post-card {
            border-bottom-color: #377997;
        }
    }
}

.bg {
    &--blue {
        background: $blue;
        border-radius: 10px;
    }

    &--blue,
    &--white {
        .slick-slider {
            .slick-dots button {
                border-color: $blue-dark;
                background: $blue-dark;
            }

            .slick-dots {
                .slick-active button {
                    background: $blue-dark;
                    transform: scale(1.4);
                }
            }
        }
    }

    &--blue-dark {
        background: $blue-dark;
        color: white;

        a {
            color: #fff;

            &:hover {
                color: inherit;
            }
        }
    }

    &--orange {
        background: $orange;
    }

    &--green {
        background: $green;
    }

    &--grey-light {
        background: $grey-light;
    }

    &--white {
        background-color: white;
    }
}

.font-clr {
    color: $font-clr !important;
}