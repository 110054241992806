//******************************************************************************
// FOOTER
//******************************************************************************
.site-footer {
	position: relative;
	padding: 80px 0;

	&__title {
		font-weight: 800;
		text-transform: uppercase;
	}

	&__logo {
		width: 137px;
		margin-bottom: 24px;

		@include media-breakpoint-down(sm) {
			width: 98px;
		}
	}

	&__logo-container {
		flex-basis: 100%;
		flex-shrink: 0;
	}

	&__top-right {
		padding: 0;

		.navbar--secondary .menu {
			display: flex;
			column-gap: 16px;
			row-gap: 16px;
			flex-wrap: wrap;
		}
	}

	&__top {
		@media(max-width: 1100px) {
			&-left {
				max-width: 50%;
				flex-basis: 50%;
			}

			&-middle {
				flex-basis: 100%;
				max-width: 100%;
				order: 2;
				justify-content: flex-start !important;
				margin-top: 4rem;
			}
		}

		@media(max-width: 768px) {
			&-left {
				max-width: 100%;
				flex-basis: 100%;
			}

			&-middle {
				margin-bottom: 4rem;
			}

			&-right {
				order: 3;
			}
		}
	}

	&__bottom {
		margin-top: 24px;

		&-container {
			position: relative;
			padding-top: 24px;

			&:before {
				content: "";
				width: calc(100% - 3rem);
				height: 1px;
				display: block;
				background: white;
				position: absolute;
				top: 0;
				left: 15px;
			}
		}
	}

	.social-icons {
		a {
			height: 4.5rem;
			width: 4.5rem;
			margin: 0 7px;
			display: inline-block;

			img {
				width: 100%;
				height: 100%;
			}

			&:hover {
				img {
					opacity: .8;
				}
			}
		}
	}

	h2 {
		display: inline-block;
	}

	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		&.cli_settings_button {
			color: white !important;
		}
	}

	.menu {
		padding: 0;
		margin: 0;
		list-style: none;
		margin-bottom: 24px;

		a {
			font-weight: 600;
			font-size: 1.6rem;
			line-height: 150%;
			
			&:after {
				content: url('../assets/images/arrow_right_alt.svg');
				margin-left: 8px;
			}
		}
	}

	.copyright {
		font-size: 1.4rem;

		@include media-breakpoint-down(sm) {
			text-align: left !important;
		}
	}

	&__btt {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		width: 7rem;
		height: 7rem;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid white;
		border-radius: 50%;

		&:hover {
			background: rgba(white, .5);
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	@include media-breakpoint-down(lg) {
		padding: 5rem 0;
	}
}