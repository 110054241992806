// Big CTA
// -----------------------------------------------------------------------------

.big-cta {
	position: relative;

	&__grid {
		display: grid;
		grid-template-columns: 41% 12% 47%;
		grid-template-rows: repeat(12, minmax(8.3%, 50px));

		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	&__image {
		grid-column: 1 / 3;
		grid-row: 2 / 12;

		img {
			border-radius: 16px;
			z-index: 3;
			position: relative;
			aspect-ratio: 16 / 9;
		}
	}

	&__text {
		font-weight: 400!important;
	}

	&__content {
		grid-column: 2 / 4;
		grid-row: 1 / 11;
		padding: 3rem 4rem 3rem 30%;
		border-radius: 16px;

		> h3 {
			text-transform: uppercase;
		}

		@include media-breakpoint-down(sm) {
			padding: 5rem 1.5rem;
			mix-blend-mode: normal;
		}
	}

	@include media-breakpoint-down(sm) {
		&:before {
			content: "";
			background: $orange;
			width: 100%;
			height: 75%;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			border-radius: 8px;
		}
	}
}