// Block form
// -----------------------------------------------------------------------------

.block-form {
	padding: 8.5rem 0;

	&__pre {
		line-height: 1.2;
	    padding-top: 1rem;
	    padding-right: 3rem;
	    border-top: 1px solid currentColor;
	    display: inline-block;
	}

	&__container {
		position: relative;

		@include media-breakpoint-up(md) {
			&:before {
				content: "";
				height: 100%;
				width: 1px;
				background: $blue-dark;
				display: block;
				position: absolute;
				left: -3rem;
				top: 0;
			}
		}

		@include media-breakpoint-up(lg) {
			&:before {
				left: -6rem;
			}
		}
	}

	&__addons {
		@include media-breakpoint-up(lg) {
			position: absolute;
			bottom: 3.3rem;
			right: 1.5rem;
		}

		a {
			color: $font-clr;
		}

		.sign-up {
			margin-left: 3rem;
		}
	}

	&--event {
        background-color: $blue;
    }

    &--login {
    	overflow: hidden;
    	padding: 0;
    	position: relative;
    	z-index: 4;

    	.container {
    		&:before {
	    		content: "";
	    		background: $blue;
	    		position: absolute;
	    		top: 0;
	    		left: 0;
	    		height: 100%;
	    		width: 50vw;
	    	}

	    	@include media-breakpoint-only(md) {
	    		&:before {
	    			width: 100%;
	    		}
	    	}
    	}
    }

    &__main-area {
    	padding-top: 8rem;
    	padding-bottom: 8rem;
    }


}

.form__wrapper {
	justify-content: space-around;

	@include media-breakpoint-up(md) {
        background: $light-blue;
        padding-top: 56px;
        padding-bottom: 56px;
        margin-top: 34px;
        border-radius: 8px;
    }

	@include media-breakpoint-down(md) {
        flex-direction: column-reverse;
        padding: 0 15px;
        padding-top: 56px;
        margin-top: 20px;
        background: $light-blue;
    }
}

.form-info-side {
	background-color: #fff;
	border-radius: 8px;
	padding: 40px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	max-width: 420px;
	position: sticky;
	top: 200px;
	filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px);

	> a {
		text-decoration: none;
		color: $navy;

		&:hover {
			text-decoration: underline;
			color: $cyan;

			&.info-side__email:before {
				background-image: url(../assets/images/events-mail.svg);
			}
		}
	}

	> p {
		margin-bottom: 0;
		color: $navy;

		&:nth-child(4) {
			margin-bottom: 32px;
		}
	}

	@include media-breakpoint-down(md) {
        position: relative;
        top: 0;
        max-width: 100%;
        margin-bottom: 60px;
    }
}

.info-side {
	&__title {
		color: $steel!important;
		font-weight: 500;
		font-size: 12px;
		text-transform: uppercase;
		margin-bottom: 16px!important;
		letter-spacing: 2px;
	}
	&__help {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 8px;
	}
	&__email {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		&:before {
			content: '';
			background-image: url(../assets/images/events-mail.svg);
			display: flex;
			height: 20px;
			width: 20px;
			margin-right: 8px;
		}
	}
	&__tel {
		display: flex;
		align-items: center;
		&:before {
			content: '';
			background-image: url(../assets/images/events-call.svg);
			display: flex;
			height: 20px;
			width: 20px;
			margin-right: 8px;
		}
	}
}


.big-form {
	padding-bottom: 72px;


	&.bg--blue {
		@include media-breakpoint-up(lg) {
			background: #ffffff;
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: 0;
		display: flex;
		justify-content: center;
		border-radius: 0;
		margin-bottom: 48px;
	}

	// @include media-breakpoint-down(sm) {
	// 	padding-top: 6rem;
	// 	padding-bottom: 3rem;
	// }

	.frm_fields_container {
		// margin-top: 6.5rem;
		// padding: 0 3rem;

		// @include media-breakpoint-up(lg) {
		// 	padding: 0 8rem;
		// }

		.frm_section_heading.frm_half:first-of-type {
			position: relative;

			@media(min-width: 586px) {
				&:after {
					content: "";
					background: $blue-dark;
					width: 1px;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					right: -2%;
				}
			}
		}

		@media(min-width: 568px) {
			.frm_form_field,
			.frm_submit {
				// padding-left: 2rem;
				// padding-right: 2rem;
				margin-bottom: 30px;

			}
		}
	}

	.frm_form_title,
	.frm_description,
	.frm_description p {
		font-size: 2.4rem !important;
		margin-bottom: 0 !important;
		text-align: center !important;
	}

	.frm_description p {
		max-width: 40ch;
		margin: auto;
	}

	.frm_form_title {
		font-weight: 600;
	}

	.frm_forms form .frm_submit {
		margin-top: 0;
		display: flex;
		flex-wrap: wrap;
	}

	.frm_style_formidable-style.with_frm_style .frm_rootline input[type="button"] {
	    border-radius: 50%;
	    max-width: 30px;
	    min-width: unset;
	    max-height: 30px;
	    width: 30px;
	    height: 30px;
	    padding: 0;
	    margin-bottom: 0!important;
	    
	}

	.frm_style_formidable-style.with_frm_style .frm_rootline .frm_current_page input[type="button"] {
		border-color: $navy!important;
	    background-color: $navy!important;
	}

	.frm_current_page .frm_rootline_title {
		color: $navy;
	}

	.frm_rootline.frm_show_lines > .frm_rootline_single:after {
		background-color: $steel;
	}

	.frm_two_col {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.frm_primary_label {
			width: 100%;
		}
	}

	.frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading h3[class*="frm_pos_"] {
	    border-top: none!important;
	    font-size: 32px!important;
	    font-weight: 800;
	    text-transform: uppercase;
	    color: $deep-blue!important;
	    margin-bottom: 30px;
	}

	.with_frm_style .frm_checkbox label {
		color: $navy!important;
	}

	.with_frm_style .frm_checkbox input[type=checkbox]:checked {
		background: $cyan!important;
	}

	.frm_style_formidable-style.with_frm_style p.description, .frm_style_formidable-style.with_frm_style div.description, .frm_style_formidable-style.with_frm_style div.frm_description, .frm_style_formidable-style.with_frm_style .frm-show-form > div.frm_description, .frm_style_formidable-style.with_frm_style .frm_error, .frm_style_formidable-style.with_frm_style .frm_pro_max_limit_desc {
		font-family: $font-family;
		font-size: 16px;
		font-weight: 400;
	}

}