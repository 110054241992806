@import "../bootstrap_overrides/_variables";

// Feature section
// -----------------------------------------------------------------------------

.feature-section {
	overflow: hidden;

	&__container {
		position: relative;
		padding-bottom: 5rem;

		&:before {
			content: "";
			background: $green;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 70%;
			border-radius: 10px;

			@media(max-width: 768px) {
				height: 89%;
				left: -50%;
				width: 200%;
			}
		}
	}

	.p-middle {
		max-width: 23ch;
	}

	&__outer {
		@media(max-width: 1025px) {
			margin-bottom: 4rem;
			flex-grow: 1 !important;
			flex-basis: 100% !important;
			max-width: 50%!important;

			&:nth-of-type(3) {
				max-width: 100%!important;
			}
		}

		@media(max-width: 768px) {
			margin-bottom: 4rem;
			flex-grow: 1 !important;
			flex-basis: 100% !important;
			max-width: 100%!important;

			&:nth-of-type(3) {
				margin-bottom: 0;
			}
		}
	}

	&__inner {
		background: $blue-dark;
		color: white;
		padding: 3rem 4rem 5rem;
		position: relative;
		height: 100%;
		border-radius: 0 0 8px 8px;

		a {
			color: inherit;
			text-decoration: none;
			margin-top: auto;

			&:hover {
				text-decoration: underline;
				color: inherit;
			}
		}

		h2 {
			color: $green;
		}
	}

	img {
		object-fit: cover;
		flex-shrink: 0;
		border-radius: 8px 8px 0 0;
		aspect-ratio: 16 / 9;
	}

	@media(max-width: 1366px) {
		img {
			height: 27rem;
		}
	}

	@media(max-width: 768px) {
		img {
			display: none;
		}
	}

	&__lines {
		position: absolute;
		bottom: 0;
		right: 0;
		height: 75%;
		width: 10rem;
		max-height: 24rem;
		overflow: hidden;

		img {
			position: absolute;
			bottom: 0;
			right: 0;
			height: 100%;
			mix-blend-mode: overlay;
		}
	}
}