//******************************************************************************
// HEADER
//******************************************************************************
.site-header {
	width: 100%;
	background-color: #fff;
	padding: 24px 0;
	position: sticky;
	z-index: 99;
	top: 0;
	// @include fade-transition(0.3s);

	&.site-header-modal {
		padding-right: 5px;
	}

	&-modal {
		background-color: transparent!important;
	}

	.navbar {
		padding: 0 15px;

		.navbar-brand {
			.logo {
				@include fade-transition(0.3s);
				width: 137px;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		padding: 20px 0;

		.navbar {
			.navbar-brand {
				.logo {
					width: 110px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.navbar {
			.navbar-brand {
				.logo {
					width: 81px;
				}
			}
		}
	}

	

	&.scroll {
		padding: 20px 0;
		filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px);

		.navbar-brand {
			.logo {
				width: 81px;
			}
		}
	}
}

body {
	main {
		@include fade-transition(0.3s);
	}
}

.page-template-template-members-hub-php {
	main {
		padding-bottom: 0!important;
	}
	
}