//******************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//******************************************************************************

// Content
// -----------------------------------------------------------------------------
.block {
    margin-top: 40px;

    @media(max-width: 1366px) {
        margin-top: 30px;
    }

    // @media(max-width: 768px) {
    //     margin-top: 0;
    // }

    .home & {
        margin-top: 180px;

        @media(max-width: 1366px) {
            margin-top: 80px!important;
        }
    }

    &.News {
        margin-top: 80px!important;

        @media(max-width: 1366px) {
            margin-top: 30px!important;
        }
    }

    &.Events {
        @include media-breakpoint-down(md) {
            margin-top: 120px!important;
        }
    }

    .single-post &,
    .single & {
        margin-top: 140px;

        @include media-breakpoint-down(md) {
            margin-top: 80px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 120px;
        }
    }

    .single-members & {
        margin-top: 250px;
        padding-top: 48px;
    }

    &.Publications {
        margin-top: 58px;

        @include media-breakpoint-down(md) {
            margin-top: 58px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 58px;
        }
    }

    &.sitemap {
        margin-bottom: 6.5rem;
    }

    .search & {
        margin-top: 72px;
    }
}

.inner-spacing {
    // a {
    //     display: inline-block;
    // }

    >*+* {
        margin-top: 3.4rem;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            margin-top: 2.4rem;
        }
    }

    &--larger {
        >*+* {
            margin-top: 5rem;
            margin-bottom: 0;

            @include media-breakpoint-down(lg) {
                margin-top: 2.8rem;
            }
        }
    }
}

.main {
    flex: 1 0 auto;
}

.content {
    position: relative;
}

// Spacing
// -----------------------------------------------------------------------------
.spacer {
    margin: 32px 0;
}

// Search
// -----------------------------------------------------------------------------

body.modal-open {
    // TODO: make body overflow hidden when modal is open
    // So the user cannot scroll down
}

.site-search {
    position: relative;
    padding: 0.6rem;

    &__container {
        display: none;
        position: absolute;
        top: calc(100% - 5rem);
        right: 4.7rem;
        width: 25rem;

        input {
            border: 1px solid #9b9b9b !important;
            border-radius: 4px !important;
            background-color: white !important;
        }
    }

    &__btn {
        padding: 0;
        border: none;
        background-color: transparent;
        margin-left: 56px;

        img {
            width: 18px;
            height: 18px;
        }

        @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 24px;
        }
    }

    &__modal {
        position: fixed;
        top: 50%;
        left: calc(50% - 364px);
        z-index: 6;
        pointer-events: none;

        .modal-open & {
            pointer-events: fill;
        }

        @include media-breakpoint-down(sm) {
            width: 90%;
            left: 15px;
        }
    }

    &--open {
        .site-search__container {
            display: block;
        }
        .site-search__modal {
            opacity: 1;
        }
    }

    > svg {
        width: 18px;
        height: 18px;
         path {
            .modal-open & {
                fill: #fff;
            }
        }
    }

}

html:has(.modal-open) {
    overflow: hidden!important;   
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: #D9D9D9; 
    height: 69px;
}

#site-search__modal {

    .search-form {
        position: relative;
    }

    .search-results {
        display: none;
        margin-top: -1px;
        width: 100%;
        background: #ffffff;
        z-index: 99;
        position: absolute;
        top: 100%;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(rgba(0, 0, 0, 0), $navy);
            z-index: 3;
            pointer-events: none;
        }

        &.open {
            display: block;
        }

        ul.search-results-list {
            margin-top: 10px;
            max-height: 250px;
            overflow-y: scroll;
            direction: rtl;


            li.search-result-item {
                padding: 24px 10px 0;
                margin: 0;

                a.search-result-link,
                a.view-all {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 600;
                    color: #fff;
                    white-space: normal;
                    padding: 0;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline !important;
                        color: $cream;
                    }
                }

                &:last-child {
                    margin-bottom: 30px;
                }

            }
        }

    }

    .spinner {
        position: relative;
        display: block;
        background-color: #ffffff;
        height: 1px;
        width: 0;
        animation: fillLine 1s ease-in-out infinite;
    }

}

@keyframes fillLine {
    0% {
        width: 0;
    }

    50% {
        width: 133px;
    }

    100% {
        width: 0;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $navy;
    z-index: 3;
    display: none;

    &-show {
        display: block;
        z-index: 5;
    }
}

// Utility
// -----------------------------------------------------------------------------

.m-t-auto {
    margin-top: auto;
}

.m-t-50 {
    margin-top: 5rem;
}

.m-b-20 {
    margin-bottom: 2rem;
}

.m-b-24 {
    margin-bottom: 24px;
}

.m-b-30 {
    margin-bottom: 3rem;
}

.m-b-50 {
    margin-bottom: 5rem;
}