@import "../bootstrap_overrides/_variables";

// Feature section
// -----------------------------------------------------------------------------

.content-image-blocks {
	position: relative;
	$imageGap: 5vw;
	--block-gap: 10rem;

	@include media-breakpoint-down(lg) {
		--block-gap: 6rem;
	}

	&__section {
		display: grid;
		grid-template-columns: 33% 13% 8% 13% 33%;
		grid-template-rows: 1fr;

		> div {
			grid-row: 1;
		}

		&:not(:last-of-type) {
			margin-bottom: var(--block-gap);
		}

		&:nth-of-type(odd) {
			.content-image-blocks__image {
				grid-column: 1 / 4;
			}

			.content-image-blocks__content {
				grid-column: 5 / 6;
			}
		}

		&:nth-of-type(even) {
			.content-image-blocks__image {
				grid-column: 3 / 6;
				justify-content: flex-start;

				img {
					margin-left: 0;
				}

				div {
					position: relative;

					&:before {
						content: '';
						left: revert;
						right: 0;

						@include media-breakpoint-down(sm) {
							right: -15px;
						}
					}
				}
			}

			.content-image-blocks__content {
				grid-column: 1 / 2;
			}
		}

		&:nth-of-type(3n+1) {
			.content-image-blocks__image div:before {
				background: $orange;
			}
		}
		&:nth-of-type(3n+2) {
			.content-image-blocks__image div:before {
				background: $green;
			}
		}
		&:nth-of-type(3n+3) {
			.content-image-blocks__image div:before {
				background: $blue;
			}
		}
	}

	&__content {
		a.icon {
			display: inline-block;
			text-decoration: none;
			
			span {
				position: relative;
				top: 2px;
			}

			&:hover {
				span {
					text-decoration: underline;
				}
			}

			img {
				margin-right: 5px;
			}
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	&__image {
		padding-bottom: $imageGap;
		position: relative;
		display: flex;
		align-items: flex-start;

		img {
			width: calc(100% - #{$imageGap});
			margin-left: $imageGap;
		}

		div {
			position: relative;
			width: 100%;

			&:before {
				content: '';
				display: block;
				width: calc(100% - #{$imageGap});
				height: 100%;
				position: absolute;
				z-index: -1;
				top: $imageGap;
				left: 0;

				@include media-breakpoint-down(sm) {
					left: -15px;
					width: calc(100% - 3.5rem);
				}
			}
		}
	}

	&__break {
		text-align: center;
		display: inline-block;
		margin: 0 auto var(--block-gap);
		padding: 0 5.5rem 1.2rem;
		border-bottom: 1px solid currentColor;
		background-color: #fff;
		position: relative;

		&:before {
			content: "";
			width: calc(100% + var(--block-gap));
			height: calc(calc(100% + 1px) + calc(var(--block-gap) * 2));
			display: block;
			position: absolute;
			z-index: -1;
			left: calc(-1 * calc(var(--block-gap) / 2));
			top: calc(-1 * var(--block-gap));
			background: white;
		}
	}

	&:before {
		content: "";
		background: $blue-dark;
		width: 1px;
		height: 0;
		display: block;
		position: absolute;
		top: calc(-1 * var(--block-gap));
		left: 50%;
		transform: translate(-50%);
		z-index: -1;
		transition: height 5s ease-in-out;
	}

	&--animated {
		&:before {
			height: calc(100% + var(--block-gap));
		}
	}

	@media(max-width: 1000px) {
		&--animated {
			&:before {
				display: none;
			}
		}

		&__section {
			display: block;

			+ .content-image-blocks__section {
				margin-top: 3rem;
			}
		}

		&__image {
			margin-bottom: 5rem;
		}
	}

	@include media-breakpoint-down(md) {
		padding-bottom: 80px;
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 40px;
	}
}

.goto-block {
	margin-top: 10rem;
	margin-bottom: 6rem;

	a {
		text-decoration: none;
		color: $font-clr;
		font-size: 1.5rem;

		&:hover {
			text-decoration: underline;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			&:not(:last-of-type) {
				margin-right: 15px;

				&:after {
					content: "|";
					margin-left: 15px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}