// Hero
// -----------------------------------------------------------------------------

.hero {
	padding-top: 121px;
	padding-bottom: 121px;
	position: relative;

	.page-template-template-members-directory & {
		padding-top: 160px;
		padding-bottom: 160px;
		@include media-breakpoint-down(sm) {
			padding-top: 48px;
			padding-bottom: 0;
		}
	}

	.full-width {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		@include media-breakpoint-down(md) {
			position: relative;
			max-height: 200px;
			height: 100%;
			border-radius: 0;
		}
		@include media-breakpoint-down(sm) {
			z-index: 2;
			position: relative;
			margin-top: 24px;
		}
	}

	.hero-img {
		max-height: 485px;
		z-index: 4;
		margin-bottom: -100px;
		background-color: #fff;
	}

	> img {
		width: 47%;

		@include media-breakpoint-down(md) {
			width: 92%;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 58px;
		padding-bottom: 58px;
	}

	@include media-breakpoint-down(sm) {
		padding-top: 48px;
	}

	.page-child &,
	.page-template-template-general-content-php & {
		@include media-breakpoint-down(md) {
			padding-top: 58px;
			padding-bottom: 0;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 48px;
		}
	}

	.heading {
		color: $mint;
		font-weight: 800;
		margin-bottom: 24px;
		text-transform: uppercase;

		.single-members & {
			margin-bottom: 16px;
		}

		.single & {
			font-weight: 600;
		}

		.page-child & {
			
			@include media-breakpoint-down(md) {
				margin-bottom: 58px;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 48px;
			}
		}

		.search & {
			margin-bottom: 0;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}

	}

	.icon {
		margin-bottom: 16px;
	}

	.post-cat {
		margin-bottom: 0;
	}

	.hub-eyebrow {
		margin-bottom: 0;
		font-weight: 700;

		.page-child & {
			@include media-breakpoint-down(md) {
				margin-bottom: 24px;
			}
		}
	}

	.tax-eyebrow {
		font-weight: 500;

		.hero--member-hub & {
			margin-bottom: 16px;
		}
	}

	&__row {
		z-index: 4;
		position: relative;
	}

	&__overlay {
		// background: radial-gradient(100% 709.26% at 100% 100%, rgba(27, 27, 51, 0.5) 0%, rgba(0, 81, 112, 0.5) 100%);
		background: linear-gradient(to right, rgba(27, 27, 51, 0.7), rgba(0, 81, 112, 0.7));
		background-blend-mode: multiply;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.events-details {
		&__wrapper {
			display: flex;
			background-color: #fff;
			border-radius: 10px;
			padding: 32px;
			filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px);
			width: 85%;
			margin-bottom: -140px;

			@include media-breakpoint-down(lg) {
				width: 100%;
			}
			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}

		&-book {
			width: fit-content;
			color: $navy;
			display: flex;
			align-items: center;
			margin-bottom: 48px;
			font-weight: 600;

			&:after {
				content: "";
				background-image: url(../assets/images/arrow_forward_24_navy.svg);
				height: 20px;
				width: 24px;
				display: flex;
				margin-left: 8px;
			}
		}

		&__details {
			padding-right: 32px;
			border-right: 1px solid #000;

			&-title {
				color: $steel!important;
				text-transform: uppercase;
				font-weight: 500;
				
			}
			&-date {
				font-weight: 600;
				margin-bottom: 0!important;
			}
			> p,
			> span {
				margin-bottom: 8px;
				color: $navy;
			}

			@include media-breakpoint-down(md) {
				border-right: none;
				border-bottom: 1px solid #000;
				padding-bottom: 20px;
				padding-right: 0;
			}
		}

		&__contact {
			padding-left: 32px;
			&-email {
				display: flex;
				align-items: center;

				&:before {
					content: "";
					background-image: url(../assets/images/events-mail.svg);
					height: 24px;
					width: 24px;
					display: flex;
					margin-right: 8px;
					background-repeat: no-repeat;
				}
			}
			&-tel {
				display: flex;
				align-items: center;

				&:before {
					content: "";
					background-image: url(../assets/images/events-call.svg);
					height: 20px;
					width: 20px;
					display: flex;
					margin-right: 8px;
					background-repeat: no-repeat;
				}
			}
			&-web {
				display: flex;
				align-items: center;

				&:before {
					content: "";
					background-image: url(../assets/images/events-website.svg);
					height: 20px;
					width: 20px;
					display: flex;
					margin-right: 8px;
					background-repeat: no-repeat;
				}
			}
			> p,
			> a {
				margin-bottom: 8px;
				color: $navy;
				text-decoration: none;

				&:last-of-type {
					margin-bottom: 0;
				}

				&:hover {
					color: $deep-blue;
					text-decoration: underline;
				}
			}

			@include media-breakpoint-down(md) {
				border-right: none;
				padding-top: 20px;
				padding-left: 0;
			}
		}
	}

	&--bg {
		color: white;
		display: flex;
		flex-direction: column;
		align-items: center;

		.heading,
		.post-heading {
			color: #DCF2E3;
		}

		.line {
			background: currentColor;
		}

		.container {
			position: relative;
			z-index: 2;
		}

		// &:after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	display: block;
		// 	z-index: 1;
		// 	background: rgba(black, .2);
		// }

		> img {
			border-radius: 8px;
		}

	}

	&__side-image {
		position: absolute;
		left: 0;
		top: 0;
		width: 40%;
		height: 100%;
	}

	&__member-logo {
		padding-right: 8rem;

		img {
			object-position: left;
			object-fit: contain !important;
			width: auto !important;
			height: auto !important;
		}

		@include media-breakpoint-down(sm) {
			margin: 3rem 0;
		}
	}

}

// .post-template-default {
// 	.hero {
// 		padding-bottom: 7.6rem;
// 		min-height: 55rem;
// 	}
// }

.single-members {
	.hero {
		padding: 120px 0 0!important;

		@include media-breakpoint-down(sm) {
			padding: 120px 0 0!important;
		}
	}
	
}

.single-post,
.single-members,
.single-business_groups {
	.hero {
		min-height: 295px;
		padding: 48px 0 48px;

		&.Events {
			min-height: 386px;
		}
	}

	@include media-breakpoint-down(sm) {
		.hero {
			min-height: 0;

			.hero__side-image {
				position: relative;
				width: 100%;
				height: auto;
				max-height: 50rem;
				margin-top: 3rem;
			}

			&__row {
				flex-direction: column-reverse;
			}

			h1 {
				overflow-wrap: break-word;
			}
		}
	}
}

.eyebrow {
	font-size: 1.2rem !important;
	line-height: 125%;
	text-transform: uppercase;
	display: inline-block;
	margin-bottom: 16px;
	letter-spacing: 2px;
	color: #fff;

}

.home-hero-slider {
	color: white;
	margin-bottom: 120px;
	@include media-breakpoint-down(md) {
		margin-bottom: 80px;
	}
}

.member-visit {
	color: $navy!important;
	display: flex;
	align-items: center;
	width: fit-content;
	margin-bottom: 32px;
	font-weight: 600;

	&:after {
		content: '';
		background-image: url(../assets/images/arrow_forward_24_navy.svg);
		width: 24px;
		height: 24px;
		display: flex;
		margin-left: 8px;
	}
}

.members-information {
	&__wrapper {
		color: $navy;
		background-color: #fff;
		padding: 32px;
		border-radius: 10px;
		position: relative;
		filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px);
		flex-direction: column;
		margin-bottom: -250px;

		.divider-verticle-line {
			width: 1px;
			height: 190px;
			background-color: #000;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.member-accreditations {
			margin-top: 24px;
			max-width: 50%;

			.accreditation-title {
				text-transform: uppercase;
				color: $steel;
				font-weight: 500;
				margin-bottom: 8px;
			}

			.accreditations {
				margin-bottom: 0;
			}

			@include media-breakpoint-down(sm) {
				max-width: 100%;
			}
		}

		@include media-breakpoint-up(lg) {
			width: 100%;
		}
		
	}

	&__top {
		display: flex;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> img {
			max-height: 60px;
			margin-top: 24px;
			max-width: 150px;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__contact-info {
		width: 50%;
		flex-basis: 50%;
		border-bottom: 1px solid black;

		> p {
			margin-bottom: 8px;
		}

		> a {
			text-decoration: none;
			margin-bottom: 8px;
			color: $navy;
		}

		.member-name {
			font-weight: 600;
			margin-bottom: 0;
		}

		.member-email {
			display: flex;
			align-items: center;
			&:before {
				content: '';
				background-image: url(../assets/images/events-mail.svg);
				width: 20px;
				height: 20px;
				display: flex;
				margin-right: 8px;
			}

			&:hover {
				color: $deep-blue;
				text-decoration: underline;
			}
		}

		.member-tel {
			display: flex;
			align-items: center;
			&:before {
				content: '';
				background-image: url(../assets/images/events-call.svg);
				width: 20px;
				height: 20px;
				display: flex;
				margin-right: 8px;
			}

			&:hover {
				color: $deep-blue;
				text-decoration: underline;
			}
		}

		.member-web {
			display: flex;
			align-items: center;
			&:before {
				content: '';
				background-image: url(../assets/images/events-website.svg);
				width: 20px;
				height: 20px;
				display: flex;
				margin-right: 8px;
			}

			&:hover {
				color: $deep-blue;
				text-decoration: underline;
			}
		}

		.members__social-icons {
			margin-bottom: 24px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			flex-basis: 100%;
		}

	}



	&__location {
		width: 50%;
		padding-left: 32px;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		border-bottom: 1px solid black;
		color: $navy;

		.location {
			&-title {
				color: $steel;
				text-transform: uppercase;
				font-weight: 500;
				margin-bottom: 8px;
			}

			
		}

		.location-descript {
			margin-bottom: 64px;
		}

		.member-google {
			text-decoration: none;
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			position: absolute;
			bottom: 110px;
			color: $navy;

			&:before {
				content: '';
				background-image: url(../assets/images/location_on.svg);
				width: 20px;
				height: 20px;
				display: flex;
				margin-right: 8px;
			}

			&:hover {
				color: $deep-blue;
				text-decoration: underline;
			}

			@include media-breakpoint-down(lg) {
				bottom: 125px;
			}

			@include media-breakpoint-down(sm) {
				bottom: 200px;
			}
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			flex-basis: 100%;
			padding-left: 0;
			padding-top: 24px;
		}

	}


}
