/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1350px;
  --breakpoint-xl: 1540px;
  --breakpoint-xxl: 1820px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 480px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 95vw; } }

@media (min-width: 1350px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1296px; } }

@media (min-width: 1540px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1480px; } }

@media (min-width: 1820px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1770px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1350px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1540px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1820px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 480px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1350px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1540px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1820px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 479.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl,
    .navbar-expand-sm > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl,
    .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1349.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1350px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl,
    .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1539.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1540px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl,
    .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1819.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1820px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid,
    .navbar-expand-xxl > .container-sm,
    .navbar-expand-xxl > .container-md,
    .navbar-expand-xxl > .container-lg,
    .navbar-expand-xxl > .container-xl,
    .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl,
  .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl,
  .navbar-expand > .container-xxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1350px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1540px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1820px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1350px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1540px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1820px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1350px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1540px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1820px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1350px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1540px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1820px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent; }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1350px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1540px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1820px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.link {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 150%; }
  .link--arrow:after {
    content: url("../assets/images/arrow_right_alt.svg");
    margin-left: 8px; }

.button,
.btn {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 20px;
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  width: fit-content;
  transition: all 0.3s ease; }
  .button--primary,
  .btn--primary {
    background-color: #08384D;
    color: #fff; }
    .modal-open .button--primary, .modal-open
    .btn--primary {
      background-color: #fff;
      color: #08384D; }
  .button--arrow:after,
  .btn--arrow:after {
    color: #fff;
    content: '';
    background-image: url("../assets/images/arrow_forward_24_white.svg");
    height: 24px;
    width: 24px;
    display: flex;
    margin-left: 8px; }
  .button--nav,
  .btn--nav {
    margin-left: 56px; }
    @media (max-width: 1349.98px) {
      .button--nav,
      .btn--nav {
        margin-left: 0;
        margin-right: 24px; } }
  .button--white,
  .btn--white {
    background-color: #fff;
    padding: 12px 20px;
    border-radius: 10px;
    border: 1px solid #fff;
    text-decoration: none; }
    .button--white:hover,
    .btn--white:hover {
      background-color: #00BAE1;
      text-decoration: none;
      border: 1px solid #00BAE1;
      color: #08384D !important; }
      .button--white:hover.quick-links__link a:after,
      .btn--white:hover.quick-links__link a:after {
        color: #fff;
        content: url("../assets/images/arrow_forward_24_navy.svg");
        margin-left: 8px; }
    .button--white.btn--arrow:after,
    .btn--white.btn--arrow:after {
      color: #fff;
      content: '';
      background-image: url("../assets/images/arrow_forward_24_navy.svg");
      height: 24px;
      width: 24px;
      display: flex;
      margin-left: 8px; }
  .button:hover,
  .btn:hover {
    background-color: #00BAE1;
    text-decoration: none;
    color: #08384D !important; }
    .button:hover.btn--arrow:after, .button:hover.button--arrow:after,
    .btn:hover.btn--arrow:after,
    .btn:hover.button--arrow:after {
      color: #fff;
      content: '';
      background-image: url("../assets/images/arrow_forward_24_navy.svg");
      margin-left: 8px;
      height: 24px;
      width: 24px;
      display: flex; }

.theme--purple .heading,
.theme--purple .color {
  color: #7452E3; }

.theme--purple .line {
  background: #7452E3; }

.theme--purple .blockquote__author svg path {
  fill: #7452E3; }

.theme--purple .post-card, .theme--purple.post-card {
  border-bottom-color: #7452E3; }

.theme--blue .heading,
.theme--blue .post-heading,
.theme--blue .color,
.theme--blue p strong {
  color: #08384D;
  font-weight: 600; }

.theme--blue .frm_form_title,
.theme--blue .frm_description p {
  color: #005170 !important;
  font-family: "Work Sans", sans-serif !important; }

.theme--blue .line {
  background: #005170; }

.theme--blue .post-card, .theme--blue.post-card {
  border-bottom-color: #377997; }

.bg--blue {
  background: #DCE7ED;
  border-radius: 10px; }

.bg--blue .slick-slider .slick-dots button, .bg--white .slick-slider .slick-dots button {
  border-color: #005170;
  background: #005170; }

.bg--blue .slick-slider .slick-dots .slick-active button, .bg--white .slick-slider .slick-dots .slick-active button {
  background: #005170;
  transform: scale(1.4); }

.bg--blue-dark {
  background: #005170;
  color: white; }
  .bg--blue-dark a {
    color: #fff; }
    .bg--blue-dark a:hover {
      color: inherit; }

.bg--orange {
  background: #FAE9C4; }

.bg--green {
  background: #DCF2E3; }

.bg--grey-light {
  background: rgba(99, 99, 99, 0.1); }

.bg--white {
  background-color: white; }

.font-clr {
  color: #636363 !important; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden; }

body {
  color: #005170;
  font-size: 1.8rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 400; }

p {
  line-height: 1.2;
  margin-bottom: 1.4em; }

li {
  line-height: 1.2; }

strong,
b {
  font-weight: 600; }
  strong.bolder,
  b.bolder {
    font-weight: 800; }

img {
  height: auto;
  max-width: 100%; }

h2 + p,
h3 + p,
h4 + p,
h5 + p {
  margin-top: 2rem; }

a {
  text-decoration: underline;
  color: #005170; }
  a:hover {
    text-decoration: none;
    color: #08384D; }
  a[href*="mailto:"] {
    overflow-wrap: break-word; }

a.button:hover,
a.btn:hover {
  background-color: #00BAE1; }
  a.button:hover .btn--arrow:after,
  a.btn:hover .btn--arrow:after {
    content: url("../assets/images/arrow_forward_24_navy.svg");
    background-image: url("../assets/images/arrow_forward_24_navy.svg"); }

button:not(.site-search__btn):not(.accordion__header):not(.frm_button_submit):not(.slick-arrow):not([role="tab"]):not(.navbar-toggler):not(.video-container__play):not(.filter-toggle):not(.cli-modal-close) {
  background-color: transparent;
  color: #636363;
  padding: 7px 34px 7px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #636363;
  border-radius: 4px;
  display: inline-block;
  font-weight: 400;
  cursor: pointer; }
  button:not(.site-search__btn):not(.accordion__header):not(.frm_button_submit):not(.slick-arrow):not([role="tab"]):not(.navbar-toggler):not(.video-container__play):not(.filter-toggle):not(.cli-modal-close) + button {
    margin-left: 2rem; }
    @media (max-width: 1349.98px) {
      button:not(.site-search__btn):not(.accordion__header):not(.frm_button_submit):not(.slick-arrow):not([role="tab"]):not(.navbar-toggler):not(.video-container__play):not(.filter-toggle):not(.cli-modal-close) + button {
        margin-left: 0; } }
  button:not(.site-search__btn):not(.accordion__header):not(.frm_button_submit):not(.slick-arrow):not([role="tab"]):not(.navbar-toggler):not(.video-container__play):not(.filter-toggle):not(.cli-modal-close):hover {
    background-color: #636363;
    color: white; }

a.link {
  text-decoration: none;
  font-weight: 600; }
  a.link:hover {
    text-decoration: underline; }
  a.link + .link {
    margin-left: 3rem; }

.js-focus-visible :focus:not(.focus-visible) {
  outline: none; }

.js-focus-visible .focus-visible {
  outline: 1px dashed var(--primary); }

div.objectfit {
  height: 100%; }
  div.objectfit img {
    -o-object-fit: cover;
    object-fit: cover;
    height: auto; }

.full-background {
  position: relative;
  padding: 17rem 0; }
  @media (max-width: 1349.98px) {
    .full-background {
      padding: 10rem 0; } }
  .full-background__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0; }

.icon svg,
.icon img {
  height: 4rem;
  width: 4rem; }

.skiplink {
  position: absolute;
  left: -9999em; }
  .skiplink:focus {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: 2rem;
    z-index: 1000;
    background: #000000;
    padding: 1rem 1.8rem;
    border: 4px solid white; }

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; }

.alignright {
  float: right;
  margin: 5px 0px 10px 15px; }

.alignleft {
  float: left;
  margin: 5px 15px 10px 0px; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px; }

.alignnone {
  margin: 10px 0; }

.line-mask {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none; }
  .line-mask img {
    object-fit: revert;
    mix-blend-mode: overlay;
    transition: clip-path 1s;
    will-change: clip-path;
    height: 100% !important;
    width: 100% !important;
    pointer-events: none; }
  .line-mask img:nth-of-type(1) {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
  .line-mask img:nth-of-type(2) {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    position: absolute;
    left: 0; }
  .line-mask--animated img {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important; }
  @media (max-width: 1539.98px) {
    .line-mask {
      left: -355px; } }
  @media (max-width: 1349.98px) {
    .line-mask {
      display: none !important; }
      .line-mask .lines {
        display: none !important; } }

.lines-container .lines img {
  object-fit: revert;
  mix-blend-mode: overlay;
  transition: clip-path 1.5s ease-in;
  transition-delay: 0.5s;
  will-change: clip-path; }

.lines-container .lines img:nth-of-type(1) {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }

.lines-container .lines img:nth-of-type(2) {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  position: absolute;
  right: 0; }

.lines-container--animated .lines img {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important; }

@media (max-width: 1349.98px) {
  .lines-container .lines {
    display: none !important; } }

@media (min-width: 768px) {
  [role="region"]:not(.hero):not([aria-label="Map"]),
  .content-image-blocks__section {
    transition: opacity .4s;
    opacity: 0; }
    [role="region"]:not(.hero):not([aria-label="Map"]).fadein,
    .content-image-blocks__section.fadein {
      opacity: 1; } }

.block {
  margin-top: 40px; }
  @media (max-width: 1366px) {
    .block {
      margin-top: 30px; } }
  .home .block {
    margin-top: 180px; }
    @media (max-width: 1366px) {
      .home .block {
        margin-top: 80px !important; } }
  .block.News {
    margin-top: 80px !important; }
    @media (max-width: 1366px) {
      .block.News {
        margin-top: 30px !important; } }
  @media (max-width: 1349.98px) {
    .block.Events {
      margin-top: 120px !important; } }
  .single-post .block,
  .single .block {
    margin-top: 140px; }
    @media (max-width: 1349.98px) {
      .single-post .block,
      .single .block {
        margin-top: 80px; } }
    @media (max-width: 767.98px) {
      .single-post .block,
      .single .block {
        margin-top: 120px; } }
  .single-members .block {
    margin-top: 250px;
    padding-top: 48px; }
  .block.Publications {
    margin-top: 58px; }
    @media (max-width: 1349.98px) {
      .block.Publications {
        margin-top: 58px; } }
    @media (max-width: 767.98px) {
      .block.Publications {
        margin-top: 58px; } }
  .block.sitemap {
    margin-bottom: 6.5rem; }
  .search .block {
    margin-top: 72px; }

.inner-spacing > * + * {
  margin-top: 3.4rem;
  margin-bottom: 0; }
  @media (max-width: 1539.98px) {
    .inner-spacing > * + * {
      margin-top: 2.4rem; } }

.inner-spacing--larger > * + * {
  margin-top: 5rem;
  margin-bottom: 0; }
  @media (max-width: 1539.98px) {
    .inner-spacing--larger > * + * {
      margin-top: 2.8rem; } }

.main {
  flex: 1 0 auto; }

.content {
  position: relative; }

.spacer {
  margin: 32px 0; }

.site-search {
  position: relative;
  padding: 0.6rem; }
  .site-search__container {
    display: none;
    position: absolute;
    top: calc(100% - 5rem);
    right: 4.7rem;
    width: 25rem; }
    .site-search__container input {
      border: 1px solid #9b9b9b !important;
      border-radius: 4px !important;
      background-color: white !important; }
  .site-search__btn {
    padding: 0;
    border: none;
    background-color: transparent;
    margin-left: 56px; }
    .site-search__btn img {
      width: 18px;
      height: 18px; }
    @media (max-width: 1349.98px) {
      .site-search__btn {
        margin-left: 0;
        margin-right: 24px; } }
  .site-search__modal {
    position: fixed;
    top: 50%;
    left: calc(50% - 364px);
    z-index: 6;
    pointer-events: none; }
    .modal-open .site-search__modal {
      pointer-events: fill; }
    @media (max-width: 767.98px) {
      .site-search__modal {
        width: 90%;
        left: 15px; } }
  .site-search--open .site-search__container {
    display: block; }
  .site-search--open .site-search__modal {
    opacity: 1; }
  .site-search > svg {
    width: 18px;
    height: 18px; }
    .modal-open .site-search > svg path {
      fill: #fff; }

html:has(.modal-open) {
  overflow: hidden !important; }

::-webkit-scrollbar {
  width: 7px; }

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 8px;
  border-radius: 8px; }

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #D9D9D9;
  height: 69px; }

#site-search__modal .search-form {
  position: relative; }

#site-search__modal .search-results {
  display: none;
  margin-top: -1px;
  width: 100%;
  background: #ffffff;
  z-index: 99;
  position: absolute;
  top: 100%; }
  #site-search__modal .search-results:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(transparent, #08384D);
    z-index: 3;
    pointer-events: none; }
  #site-search__modal .search-results.open {
    display: block; }
  #site-search__modal .search-results ul.search-results-list {
    margin-top: 10px;
    max-height: 250px;
    overflow-y: scroll;
    direction: rtl; }
    #site-search__modal .search-results ul.search-results-list li.search-result-item {
      padding: 24px 10px 0;
      margin: 0; }
      #site-search__modal .search-results ul.search-results-list li.search-result-item a.search-result-link,
      #site-search__modal .search-results ul.search-results-list li.search-result-item a.view-all {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        white-space: normal;
        padding: 0;
        text-decoration: none; }
        #site-search__modal .search-results ul.search-results-list li.search-result-item a.search-result-link:hover,
        #site-search__modal .search-results ul.search-results-list li.search-result-item a.view-all:hover {
          text-decoration: underline !important;
          color: #FAE9C4; }
      #site-search__modal .search-results ul.search-results-list li.search-result-item:last-child {
        margin-bottom: 30px; }

#site-search__modal .spinner {
  position: relative;
  display: block;
  background-color: #ffffff;
  height: 1px;
  width: 0;
  animation: fillLine 1s ease-in-out infinite; }

@keyframes fillLine {
  0% {
    width: 0; }
  50% {
    width: 133px; }
  100% {
    width: 0; } }

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #08384D;
  z-index: 3;
  display: none; }
  .modal-overlay-show {
    display: block;
    z-index: 5; }

.m-t-auto {
  margin-top: auto; }

.m-t-50 {
  margin-top: 5rem; }

.m-b-20 {
  margin-bottom: 2rem; }

.m-b-24 {
  margin-bottom: 24px; }

.m-b-30 {
  margin-bottom: 3rem; }

.m-b-50 {
  margin-bottom: 5rem; }

h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.search-field,
.delta,
.epsilon,
.zeta,
.post-content blockquote {
  font-weight: 300;
  margin-bottom: 0; }

.display-1 {
  font-size: 9.6rem;
  line-height: 100%; }

.display-2 {
  font-size: 8rem;
  line-height: 100%; }
  @media (max-width: 1349.98px) {
    .display-2 {
      font-size: 4.4rem; } }

.display-3 {
  font-size: 7.2rem;
  line-height: 105.55%; }

h1,
.alpha {
  font-size: 6.4rem;
  line-height: 106.25%;
  font-size: calc(3.6rem + 1.4 * ( (100vw - 540px) / 620)); }
  @media screen and (max-width: 540px) {
    h1,
    .alpha {
      font-size: 3.6rem; } }
  @media screen and (min-width: 1160px) {
    h1,
    .alpha {
      font-size: 5rem; } }

h2,
.beta {
  font-size: 5.6rem;
  line-height: 107.14%;
  font-weight: 300; }
  h2--bold,
  .beta--bold {
    font-weight: 800; }
  h2:hover a,
  .beta:hover a {
    text-decoration: none !important; }

.home-hero-slider__header {
  text-transform: uppercase;
  font-size: calc(3.6rem + 4.4 * ( (100vw - 540px) / 620));
  font-weight: 800;
  color: #DCF2E3; }
  @media screen and (max-width: 540px) {
    .home-hero-slider__header {
      font-size: 3.6rem; } }
  @media screen and (min-width: 1160px) {
    .home-hero-slider__header {
      font-size: 8rem; } }
  .home-hero-slider__header strong {
    font-weight: 800; }

h3,
.gamma,
.search-field {
  font-size: 4.8rem;
  line-height: 116.66%;
  font-weight: 600; }
  h3--bold,
  .gamma--bold {
    font-weight: 800; }
  h3.heading,
  .gamma.heading,
  .heading.search-field {
    font-weight: 800; }
  h3 strong,
  .gamma strong, .search-field strong {
    font-weight: 800; }
  h3.post-heading,
  .gamma.post-heading,
  .post-heading.search-field {
    font-weight: 600;
    font-size: calc(2rem + 0 * ( (100vw - 540px) / 620)); }
    @media screen and (max-width: 540px) {
      h3.post-heading,
      .gamma.post-heading,
      .post-heading.search-field {
        font-size: 2rem; } }
    @media screen and (min-width: 1160px) {
      h3.post-heading,
      .gamma.post-heading,
      .post-heading.search-field {
        font-size: 2rem; } }
  @media (max-width: 1349.98px) {
    h3,
    .gamma,
    .search-field {
      font-size: 3.6rem; } }

h4,
.delta {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 125%; }

h5,
.epsilon {
  font-size: 2.8rem;
  line-height: 128.57%;
  font-size: calc(1.8rem + 1 * ( (100vw - 540px) / 620));
  line-height: 1.2; }
  @media screen and (max-width: 540px) {
    h5,
    .epsilon {
      font-size: 1.8rem; } }
  @media screen and (min-width: 1160px) {
    h5,
    .epsilon {
      font-size: 2.8rem; } }

h6,
.zeta,
.post-content blockquote {
  font-size: 2.4rem;
  line-height: 133.33%; }

.font-size--twenty {
  font-size: calc(2rem + 0 * ( (100vw - 540px) / 620)); }
  @media screen and (max-width: 540px) {
    .font-size--twenty {
      font-size: 2rem; } }
  @media screen and (min-width: 1160px) {
    .font-size--twenty {
      font-size: 2rem; } }

h7,
.jones {
  font-size: 2rem;
  line-height: 140%; }

.p {
  font-size: 1.8rem; }
  .p-middle {
    line-height: 1.3; }
  .p-max {
    max-width: 60ch; }
  .p--large {
    font-size: calc(2rem + 0.4 * ( (100vw - 540px) / 620)); }
    @media screen and (max-width: 540px) {
      .p--large {
        font-size: 2rem; } }
    @media screen and (min-width: 1160px) {
      .p--large {
        font-size: 2.4rem; } }

.content-heading {
  margin-bottom: 24px; }

.standfirst, .page-id-335 .intro-block__intro {
  font-size: 2.4rem;
  line-height: 133.33%; }

.caption {
  font-size: 1.4rem;
  line-height: 142.85%;
  font-weight: 700; }

.body-large {
  font-size: 1.8rem;
  line-height: 111.11%; }

.body {
  font-size: 1.6rem;
  line-height: 150%; }

.body-bold, .intro-block .members-edit {
  font-size: 1.6rem;
  line-height: 150%; }

.searchresults-eyebrow {
  color: #525252 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 15px !important;
  margin-bottom: 16px !important; }

.font-weight--semibold {
  font-weight: 600; }

.font-weight--bolder {
  font-weight: 700; }

.post-content p strong {
  color: #005170; }

.post-content ul,
.post-content ol {
  padding-left: 2rem;
  margin-bottom: 4rem; }
  .post-content ul ul,
  .post-content ul ol,
  .post-content ol ul,
  .post-content ol ol {
    padding-left: 4rem; }

.post-content ol {
  list-style: none;
  counter-reset: numbers;
  padding-left: 32px; }
  .post-content ol li {
    counter-increment: numbers; }
    .post-content ol li:before {
      content: counters(numbers, ".") ". "; }

.post-content blockquote {
  color: #08384D; }
  .post-content blockquote p {
    font-weight: 600;
    text-indent: -0.5em; }

.site-header {
  width: 100%;
  background-color: #fff;
  padding: 24px 0;
  position: sticky;
  z-index: 99;
  top: 0; }
  .site-header.site-header-modal {
    padding-right: 5px; }
  .site-header-modal {
    background-color: transparent !important; }
  .site-header .navbar {
    padding: 0 15px; }
    .site-header .navbar .navbar-brand .logo {
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      width: 137px; }
  @media (max-width: 1539.98px) {
    .site-header {
      padding: 20px 0; }
      .site-header .navbar .navbar-brand .logo {
        width: 110px; } }
  @media (max-width: 1349.98px) {
    .site-header .navbar .navbar-brand .logo {
      width: 81px; } }
  .site-header.scroll {
    padding: 20px 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px); }
    .site-header.scroll .navbar-brand .logo {
      width: 81px; }

body main {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out; }

.page-template-template-members-hub-php main {
  padding-bottom: 0 !important; }

.site-footer {
  position: relative;
  padding: 80px 0; }
  .site-footer__title {
    font-weight: 800;
    text-transform: uppercase; }
  .site-footer__logo {
    width: 137px;
    margin-bottom: 24px; }
    @media (max-width: 767.98px) {
      .site-footer__logo {
        width: 98px; } }
  .site-footer__logo-container {
    flex-basis: 100%;
    flex-shrink: 0; }
  .site-footer__top-right {
    padding: 0; }
    .site-footer__top-right .navbar--secondary .menu {
      display: flex;
      column-gap: 16px;
      row-gap: 16px;
      flex-wrap: wrap; }
  @media (max-width: 1100px) {
    .site-footer__top-left {
      max-width: 50%;
      flex-basis: 50%; }
    .site-footer__top-middle {
      flex-basis: 100%;
      max-width: 100%;
      order: 2;
      justify-content: flex-start !important;
      margin-top: 4rem; } }
  @media (max-width: 768px) {
    .site-footer__top-left {
      max-width: 100%;
      flex-basis: 100%; }
    .site-footer__top-middle {
      margin-bottom: 4rem; }
    .site-footer__top-right {
      order: 3; } }
  .site-footer__bottom {
    margin-top: 24px; }
    .site-footer__bottom-container {
      position: relative;
      padding-top: 24px; }
      .site-footer__bottom-container:before {
        content: "";
        width: calc(100% - 3rem);
        height: 1px;
        display: block;
        background: white;
        position: absolute;
        top: 0;
        left: 15px; }
  .site-footer .social-icons a {
    height: 4.5rem;
    width: 4.5rem;
    margin: 0 7px;
    display: inline-block; }
    .site-footer .social-icons a img {
      width: 100%;
      height: 100%; }
    .site-footer .social-icons a:hover img {
      opacity: .8; }
  .site-footer h2 {
    display: inline-block; }
  .site-footer a {
    text-decoration: none; }
    .site-footer a:hover {
      text-decoration: underline; }
    .site-footer a.cli_settings_button {
      color: white !important; }
  .site-footer .menu {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 24px; }
    .site-footer .menu a {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 150%; }
      .site-footer .menu a:after {
        content: url("../assets/images/arrow_right_alt.svg");
        margin-left: 8px; }
  .site-footer .copyright {
    font-size: 1.4rem; }
    @media (max-width: 767.98px) {
      .site-footer .copyright {
        text-align: left !important; } }
  .site-footer__btt {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 7rem;
    height: 7rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%; }
    .site-footer__btt:hover {
      background: rgba(255, 255, 255, 0.5); }
    @media (max-width: 1349.98px) {
      .site-footer__btt {
        display: none; } }
  @media (max-width: 1539.98px) {
    .site-footer {
      padding: 5rem 0; } }

.main-nav .navbar-brand {
  padding: 0; }
  @media (max-width: 1349.98px) {
    .main-nav .navbar-brand {
      margin-right: 37rem; }
      .logged-in .main-nav .navbar-brand {
        margin-right: 25rem; } }
  @media (max-width: 767.98px) {
    .main-nav .navbar-brand {
      margin-right: 58px; }
      .logged-in .main-nav .navbar-brand {
        margin-right: 35px; } }

.main-nav .navbar {
  width: 100%;
  position: initial;
  justify-content: flex-start; }
  .main-nav .navbar .navbar--primary {
    height: 100%;
    align-items: baseline;
    column-gap: 56px; }
    .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a {
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 155.55%; }
      .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a .modal {
        color: #fff; }
      .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a:hover {
        text-decoration: underline; }
        .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a:hover svg circle {
          fill: #636363; }
      .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a img,
      .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a svg {
        margin-right: 1rem; }
        .modal-open .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a img path, .modal-open
        .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0 a svg path {
          fill: #fff; }
    .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0.current_page_item > a, .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0.current_page_parent > a {
      font-weight: 600; }
  .main-nav .navbar .mobile-pull-out {
    display: none; }
    .main-nav .navbar .mobile-pull-out > a svg {
      margin-right: 1rem; }
      .modal-open .main-nav .navbar .mobile-pull-out > a svg path {
        fill: #fff; }
    .main-nav .navbar .mobile-pull-out .members-area-link > svg {
      margin-right: 0; }
  @media (max-width: 767.98px) {
    .main-nav .navbar .mobile-pull-out > a > p {
      display: none; }
    .main-nav .navbar .mobile-hide {
      display: none; } }
  @media (max-width: 1349.98px) {
    .main-nav .navbar .mobile-pull-out {
      position: revert;
      display: flex;
      margin-right: 24px; }
      .main-nav .navbar .mobile-pull-out > a {
        display: flex;
        align-items: center;
        text-decoration: none; }
        .main-nav .navbar .mobile-pull-out > a > p {
          font-weight: 600;
          margin-bottom: 0;
          margin-left: 8px; }
          .modal-open .main-nav .navbar .mobile-pull-out > a > p {
            color: #fff; }
    .main-nav .navbar .mobile-hide {
      display: none; } }

@media (min-width: 1350px) {
  .main-nav .dropdown {
    position: initial; }
    .main-nav .dropdown-menu {
      background: transparent;
      padding: 0;
      margin: 0;
      list-style: none;
      font-weight: 300;
      width: -webkit-fill-available;
      width: -moz-available;
      top: 99%; }
      .main-nav .dropdown-menu li,
      .main-nav .dropdown-menu ul {
        list-style: none;
        font-size: 2.4rem;
        flex-direction: column-reverse; }
      .main-nav .dropdown-menu li:not(:last-of-type) {
        margin-bottom: 1.4rem; } }

.search .current_page_parent a,
.search-results .current_page_parent a {
  font-weight: 400 !important; }

.modal-open {
  color: #fff; }

.menu-cta {
  max-width: 85%;
  margin: 0 auto; }
  .menu-cta > h2 > a {
    font-size: 4.8rem !important;
    font-weight: 800 !important;
    text-transform: uppercase; }

@media (min-width: 1350px) {
  .fixed-nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 6rem 0 7rem; } }
  @media (min-width: 1350px) and (min-width: 1350px) {
    .fixed-nav__heading {
      margin-left: calc(41.66667% + 1.5rem); } }

@media (min-width: 1350px) {
    .fixed-nav .menu-item {
      padding: 0; }
    .fixed-nav .nav-megamenu__menu {
      padding: 0; }
      .fixed-nav .nav-megamenu__menu ul {
        padding: 0; }
    .fixed-nav .row {
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      padding: 0; } }

@media (max-width: 1349.98px) {
  .fixed-nav__heading,
  .fixed-nav h2 {
    display: none; }
  #primary-navigation {
    background: #005170;
    color: white;
    width: -webkit-fill-available;
    width: -moz-available;
    position: absolute;
    top: 100%;
    left: 0;
    height: calc(100vh - 145px) !important;
    overflow: scroll; }
    #primary-navigation > div {
      padding: 4rem 3rem; }
    #primary-navigation a {
      color: inherit;
      text-decoration: none; }
    #primary-navigation .dropdown.show .mega-dropdown-toggle {
      border-bottom: 1px solid white; }
    #primary-navigation .dropdown-menu {
      background: transparent;
      padding: 0;
      margin: 0;
      color: inherit;
      border: none;
      font-size: 18px;
      display: none; }
      #primary-navigation .dropdown-menu ul {
        margin-top: 1rem;
        margin-left: 0; }
    #primary-navigation .col-lg-7 {
      padding: 0;
      margin: 0; }
    #primary-navigation ul {
      margin: 0; }
    #primary-navigation .nav-megamenu__menu {
      margin-bottom: 2rem; }
    #primary-navigation ul,
    #primary-navigation li {
      list-style: none;
      padding: 0; }
    #primary-navigation li {
      margin-bottom: 1rem;
      margin-right: 0;
      width: 100%;
      position: relative; }
    #primary-navigation > div > ul > li {
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
      padding-top: 20px; }
      #primary-navigation > div > ul > li:after {
        content: ">";
        margin-left: 7px;
        position: absolute;
        right: 0;
        top: 20px;
        transition: all 0.6s ease; }
      #primary-navigation > div > ul > li.open:after {
        transform: rotateZ(90deg); } }

.navbar-toggler {
  display: none !important; }
  @media (max-width: 1349.98px) {
    .navbar-toggler {
      padding: 0;
      border: 0;
      border-radius: 0;
      margin: auto 0;
      height: 3rem;
      top: 6.8rem;
      right: 4.5rem;
      display: block !important; }
      .navbar-toggler .navbar-toggler-icon {
        position: relative;
        display: inline-block;
        width: 2rem;
        height: 0.25rem;
        background: #005170;
        transition: all .2s ease-in-out; }
        .modal-open .navbar-toggler .navbar-toggler-icon {
          background: #fff; }
        .navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
          background: #005170;
          content: '';
          display: block;
          width: 2rem;
          height: 0.25rem;
          position: absolute;
          transition: all .2s ease-in-out; }
          .modal-open .navbar-toggler .navbar-toggler-icon:before, .modal-open .navbar-toggler .navbar-toggler-icon:after {
            background: #fff; }
        .navbar-toggler .navbar-toggler-icon:before {
          top: -0.9rem; }
        .navbar-toggler .navbar-toggler-icon:after {
          top: 0.9rem; }
      .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
        background: transparent; }
        .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before, .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
          top: 0; }
        .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
      .navbar-toggler:hover {
        background: transparent; } }

.single-business_groups .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0.current_page_parent > a {
  font-weight: 400; }

.single-business_groups .main-nav .navbar .navbar--primary .menu-item.menu-item-685 > a {
  font-weight: 600; }

.hero {
  padding-top: 121px;
  padding-bottom: 121px;
  position: relative; }
  .page-template-template-members-directory .hero {
    padding-top: 160px;
    padding-bottom: 160px; }
    @media (max-width: 767.98px) {
      .page-template-template-members-directory .hero {
        padding-top: 48px;
        padding-bottom: 0; } }
  .hero .full-width {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    @media (max-width: 1349.98px) {
      .hero .full-width {
        position: relative;
        max-height: 200px;
        height: 100%;
        border-radius: 0; } }
    @media (max-width: 767.98px) {
      .hero .full-width {
        z-index: 2;
        position: relative;
        margin-top: 24px; } }
  .hero .hero-img {
    max-height: 485px;
    z-index: 4;
    margin-bottom: -100px;
    background-color: #fff; }
  .hero > img {
    width: 47%; }
    @media (max-width: 1349.98px) {
      .hero > img {
        width: 92%; } }
  @media (max-width: 1349.98px) {
    .hero {
      padding-top: 58px;
      padding-bottom: 58px; } }
  @media (max-width: 767.98px) {
    .hero {
      padding-top: 48px; } }
  @media (max-width: 1349.98px) {
    .page-child .hero,
    .page-template-template-general-content-php .hero {
      padding-top: 58px;
      padding-bottom: 0; } }
  @media (max-width: 767.98px) {
    .page-child .hero,
    .page-template-template-general-content-php .hero {
      padding-top: 48px; } }
  .hero .heading {
    color: #DCF2E3;
    font-weight: 800;
    margin-bottom: 24px;
    text-transform: uppercase; }
    .single-members .hero .heading {
      margin-bottom: 16px; }
    .single .hero .heading {
      font-weight: 600; }
    @media (max-width: 1349.98px) {
      .page-child .hero .heading {
        margin-bottom: 58px; } }
    @media (max-width: 767.98px) {
      .page-child .hero .heading {
        margin-bottom: 48px; } }
    .search .hero .heading {
      margin-bottom: 0; }
    @media (max-width: 767.98px) {
      .hero .heading {
        margin-bottom: 0; } }
  .hero .icon {
    margin-bottom: 16px; }
  .hero .post-cat {
    margin-bottom: 0; }
  .hero .hub-eyebrow {
    margin-bottom: 0;
    font-weight: 700; }
    @media (max-width: 1349.98px) {
      .page-child .hero .hub-eyebrow {
        margin-bottom: 24px; } }
  .hero .tax-eyebrow {
    font-weight: 500; }
    .hero--member-hub .hero .tax-eyebrow {
      margin-bottom: 16px; }
  .hero__row {
    z-index: 4;
    position: relative; }
  .hero__overlay {
    background: linear-gradient(to right, rgba(27, 27, 51, 0.7), rgba(0, 81, 112, 0.7));
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; }
    @media (max-width: 1349.98px) {
      .hero__overlay {
        display: none; } }
  .hero .events-details__wrapper {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    padding: 32px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px);
    width: 85%;
    margin-bottom: -140px; }
    @media (max-width: 1539.98px) {
      .hero .events-details__wrapper {
        width: 100%; } }
    @media (max-width: 1349.98px) {
      .hero .events-details__wrapper {
        flex-direction: column; } }
  .hero .events-details-book {
    width: fit-content;
    color: #08384D;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    font-weight: 600; }
    .hero .events-details-book:after {
      content: "";
      background-image: url(../assets/images/arrow_forward_24_navy.svg);
      height: 20px;
      width: 24px;
      display: flex;
      margin-left: 8px; }
  .hero .events-details__details {
    padding-right: 32px;
    border-right: 1px solid #000; }
    .hero .events-details__details-title {
      color: #525252 !important;
      text-transform: uppercase;
      font-weight: 500; }
    .hero .events-details__details-date {
      font-weight: 600;
      margin-bottom: 0 !important; }
    .hero .events-details__details > p,
    .hero .events-details__details > span {
      margin-bottom: 8px;
      color: #08384D; }
    @media (max-width: 1349.98px) {
      .hero .events-details__details {
        border-right: none;
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        padding-right: 0; } }
  .hero .events-details__contact {
    padding-left: 32px; }
    .hero .events-details__contact-email {
      display: flex;
      align-items: center; }
      .hero .events-details__contact-email:before {
        content: "";
        background-image: url(../assets/images/events-mail.svg);
        height: 24px;
        width: 24px;
        display: flex;
        margin-right: 8px;
        background-repeat: no-repeat; }
    .hero .events-details__contact-tel {
      display: flex;
      align-items: center; }
      .hero .events-details__contact-tel:before {
        content: "";
        background-image: url(../assets/images/events-call.svg);
        height: 20px;
        width: 20px;
        display: flex;
        margin-right: 8px;
        background-repeat: no-repeat; }
    .hero .events-details__contact-web {
      display: flex;
      align-items: center; }
      .hero .events-details__contact-web:before {
        content: "";
        background-image: url(../assets/images/events-website.svg);
        height: 20px;
        width: 20px;
        display: flex;
        margin-right: 8px;
        background-repeat: no-repeat; }
    .hero .events-details__contact > p,
    .hero .events-details__contact > a {
      margin-bottom: 8px;
      color: #08384D;
      text-decoration: none; }
      .hero .events-details__contact > p:last-of-type,
      .hero .events-details__contact > a:last-of-type {
        margin-bottom: 0; }
      .hero .events-details__contact > p:hover,
      .hero .events-details__contact > a:hover {
        color: #005170;
        text-decoration: underline; }
    @media (max-width: 1349.98px) {
      .hero .events-details__contact {
        border-right: none;
        padding-top: 20px;
        padding-left: 0; } }
  .hero--bg {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .hero--bg .heading,
    .hero--bg .post-heading {
      color: #DCF2E3; }
    .hero--bg .line {
      background: currentColor; }
    .hero--bg .container {
      position: relative;
      z-index: 2; }
    .hero--bg > img {
      border-radius: 8px; }
  .hero__side-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    height: 100%; }
  .hero__member-logo {
    padding-right: 8rem; }
    .hero__member-logo img {
      object-position: left;
      object-fit: contain !important;
      width: auto !important;
      height: auto !important; }
    @media (max-width: 767.98px) {
      .hero__member-logo {
        margin: 3rem 0; } }

.single-members .hero {
  padding: 120px 0 0 !important; }
  @media (max-width: 767.98px) {
    .single-members .hero {
      padding: 120px 0 0 !important; } }

.single-post .hero,
.single-members .hero,
.single-business_groups .hero {
  min-height: 295px;
  padding: 48px 0 48px; }
  .single-post .hero.Events,
  .single-members .hero.Events,
  .single-business_groups .hero.Events {
    min-height: 386px; }

@media (max-width: 767.98px) {
  .single-post .hero,
  .single-members .hero,
  .single-business_groups .hero {
    min-height: 0; }
    .single-post .hero .hero__side-image,
    .single-members .hero .hero__side-image,
    .single-business_groups .hero .hero__side-image {
      position: relative;
      width: 100%;
      height: auto;
      max-height: 50rem;
      margin-top: 3rem; }
    .single-post .hero__row,
    .single-members .hero__row,
    .single-business_groups .hero__row {
      flex-direction: column-reverse; }
    .single-post .hero h1,
    .single-members .hero h1,
    .single-business_groups .hero h1 {
      overflow-wrap: break-word; } }

.eyebrow {
  font-size: 1.2rem !important;
  line-height: 125%;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 16px;
  letter-spacing: 2px;
  color: #fff; }

.home-hero-slider {
  color: white;
  margin-bottom: 120px; }
  @media (max-width: 1349.98px) {
    .home-hero-slider {
      margin-bottom: 80px; } }

.member-visit {
  color: #08384D !important;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 32px;
  font-weight: 600; }
  .member-visit:after {
    content: '';
    background-image: url(../assets/images/arrow_forward_24_navy.svg);
    width: 24px;
    height: 24px;
    display: flex;
    margin-left: 8px; }

.members-information__wrapper {
  color: #08384D;
  background-color: #fff;
  padding: 32px;
  border-radius: 10px;
  position: relative;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px);
  flex-direction: column;
  margin-bottom: -250px; }
  .members-information__wrapper .divider-verticle-line {
    width: 1px;
    height: 190px;
    background-color: #000; }
    @media (max-width: 767.98px) {
      .members-information__wrapper .divider-verticle-line {
        display: none; } }
  .members-information__wrapper .member-accreditations {
    margin-top: 24px;
    max-width: 50%; }
    .members-information__wrapper .member-accreditations .accreditation-title {
      text-transform: uppercase;
      color: #525252;
      font-weight: 500;
      margin-bottom: 8px; }
    .members-information__wrapper .member-accreditations .accreditations {
      margin-bottom: 0; }
    @media (max-width: 767.98px) {
      .members-information__wrapper .member-accreditations {
        max-width: 100%; } }
  @media (min-width: 1350px) {
    .members-information__wrapper {
      width: 100%; } }

.members-information__top {
  display: flex; }
  @media (max-width: 767.98px) {
    .members-information__top {
      flex-direction: column; } }

.members-information__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .members-information__bottom > img {
    max-height: 60px;
    margin-top: 24px;
    max-width: 150px; }
  @media (max-width: 767.98px) {
    .members-information__bottom {
      flex-direction: column;
      align-items: flex-start; } }

.members-information__contact-info {
  width: 50%;
  flex-basis: 50%;
  border-bottom: 1px solid black; }
  .members-information__contact-info > p {
    margin-bottom: 8px; }
  .members-information__contact-info > a {
    text-decoration: none;
    margin-bottom: 8px;
    color: #08384D; }
  .members-information__contact-info .member-name {
    font-weight: 600;
    margin-bottom: 0; }
  .members-information__contact-info .member-email {
    display: flex;
    align-items: center; }
    .members-information__contact-info .member-email:before {
      content: '';
      background-image: url(../assets/images/events-mail.svg);
      width: 20px;
      height: 20px;
      display: flex;
      margin-right: 8px; }
    .members-information__contact-info .member-email:hover {
      color: #005170;
      text-decoration: underline; }
  .members-information__contact-info .member-tel {
    display: flex;
    align-items: center; }
    .members-information__contact-info .member-tel:before {
      content: '';
      background-image: url(../assets/images/events-call.svg);
      width: 20px;
      height: 20px;
      display: flex;
      margin-right: 8px; }
    .members-information__contact-info .member-tel:hover {
      color: #005170;
      text-decoration: underline; }
  .members-information__contact-info .member-web {
    display: flex;
    align-items: center; }
    .members-information__contact-info .member-web:before {
      content: '';
      background-image: url(../assets/images/events-website.svg);
      width: 20px;
      height: 20px;
      display: flex;
      margin-right: 8px; }
    .members-information__contact-info .member-web:hover {
      color: #005170;
      text-decoration: underline; }
  .members-information__contact-info .members__social-icons {
    margin-bottom: 24px; }
  @media (max-width: 767.98px) {
    .members-information__contact-info {
      width: 100%;
      flex-basis: 100%; } }

.members-information__location {
  width: 50%;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  color: #08384D; }
  .members-information__location .location-title {
    color: #525252;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 8px; }
  .members-information__location .location-descript {
    margin-bottom: 64px; }
  .members-information__location .member-google {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: absolute;
    bottom: 110px;
    color: #08384D; }
    .members-information__location .member-google:before {
      content: '';
      background-image: url(../assets/images/location_on.svg);
      width: 20px;
      height: 20px;
      display: flex;
      margin-right: 8px; }
    .members-information__location .member-google:hover {
      color: #005170;
      text-decoration: underline; }
    @media (max-width: 1539.98px) {
      .members-information__location .member-google {
        bottom: 125px; } }
    @media (max-width: 767.98px) {
      .members-information__location .member-google {
        bottom: 200px; } }
  @media (max-width: 767.98px) {
    .members-information__location {
      width: 100%;
      flex-basis: 100%;
      padding-left: 0;
      padding-top: 24px; } }

.block-header {
  margin-bottom: 48px; }
  .block-header__line {
    flex-shrink: 0;
    flex-basis: 100%;
    height: 1px;
    display: block;
    margin: 0 0 17px 0; }
  .block-header__extra {
    font-weight: 700;
    color: #525252;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 24px;
    max-width: none;
    letter-spacing: 2px; }
  .block-header .heading {
    flex-grow: 0;
    flex-basis: 65%;
    margin-bottom: 16px;
    text-transform: uppercase; }
  .block-header .p-large {
    flex-grow: 0;
    flex-basis: 35%;
    margin-top: 0; }
  @media (max-width: 767.98px) {
    .block-header {
      margin-bottom: 3rem; }
      .block-header .heading {
        flex-basis: 100%;
        flex-grow: 1;
        margin-bottom: 2rem;
        order: 1; }
      .block-header .btn {
        order: 1; }
      .block-header .p-large {
        flex-basis: 100%;
        flex-grow: 1;
        order: 3; }
      .block-header .block-header__extra {
        order: 3; }
      .block-header .block-header__line {
        order: 2; } }

.big-cta {
  position: relative; }
  .big-cta__grid {
    display: grid;
    grid-template-columns: 41% 12% 47%;
    grid-template-rows: repeat(12, minmax(8.3%, 50px)); }
    @media (max-width: 767.98px) {
      .big-cta__grid {
        display: block; } }
  .big-cta__image {
    grid-column: 1 / 3;
    grid-row: 2 / 12; }
    .big-cta__image img {
      border-radius: 16px;
      z-index: 3;
      position: relative;
      aspect-ratio: 16 / 9; }
  .big-cta__text {
    font-weight: 400 !important; }
  .big-cta__content {
    grid-column: 2 / 4;
    grid-row: 1 / 11;
    padding: 3rem 4rem 3rem 30%;
    border-radius: 16px; }
    .big-cta__content > h3 {
      text-transform: uppercase; }
    @media (max-width: 767.98px) {
      .big-cta__content {
        padding: 5rem 1.5rem;
        mix-blend-mode: normal; } }
  @media (max-width: 767.98px) {
    .big-cta:before {
      content: "";
      background: #FAE9C4;
      width: 100%;
      height: 75%;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 8px; } }

.testimonial-block {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  color: #fff;
  padding: 210px 450px;
  margin-top: 0; }
  .testimonial-block__wrapper {
    margin-bottom: 180px; }
    @media (max-width: 1349.98px) {
      .testimonial-block__wrapper {
        margin-bottom: 100px; } }
    @media (max-width: 767.98px) {
      .testimonial-block__wrapper {
        margin-bottom: 80px; } }
  .testimonial-block__overlay {
    background: linear-gradient(278.03deg, rgba(27, 27, 51, 0.8) 0%, rgba(0, 81, 112, 0.8) 98.79%);
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    @media (max-width: 767.98px) {
      .testimonial-block__overlay {
        background: none; } }
  .testimonial-block__content {
    padding: 0x 0;
    text-align: center; }
    @media (max-width: 767.98px) {
      .testimonial-block__content {
        padding-top: 4rem; } }
  .testimonial-block__image {
    position: absolute;
    z-index: 2;
    right: 0;
    height: 100%; }
    .testimonial-block__image:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10rem;
      mix-blend-mode: multiply;
      background: #DCF2E3; }
    .testimonial-block__image:before {
      content: "";
      background: url("../assets/images/testimonial-line-mask.png") no-repeat right bottom;
      height: 394px;
      width: 209px;
      display: block;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 1px;
      transition: all .4s;
      transition-delay: 0.6s; }
    @media (max-width: 767.98px) {
      .testimonial-block__image {
        display: none; } }
  .testimonial-block blockquote {
    color: #DCF2E3;
    text-align: center; }
    .bg--green .testimonial-block blockquote {
      color: #08384D; }
    .testimonial-block blockquote .quote {
      font-size: 3.2rem;
      text-transform: uppercase;
      font-weight: 800; }
    @media (max-width: 767.98px) {
      .testimonial-block blockquote {
        color: #005170; } }
  .testimonial-block--slider {
    padding: 0;
    margin: 0 auto; }
    .testimonial-block--slider blockquote {
      margin-bottom: auto; }
      .testimonial-block--slider blockquote + p {
        margin-top: 5rem;
        overflow-wrap: break-word;
        display: flex;
        align-items: flex-end;
        color: #08384D; }
    .testimonial-block--slider .blockquote {
      padding: 0 15px;
      height: 100%; }
      @media (max-width: 767.98px) {
        .testimonial-block--slider .blockquote {
          padding-right: 0; } }
      .testimonial-block--slider .blockquote > div {
        padding: 3.5rem 3.5rem 2.5rem;
        height: 100%;
        display: flex;
        flex-direction: column; }
    .testimonial-block--slider .slick-list {
      margin-left: -15px;
      margin-bottom: 2.5rem; }
    .testimonial-block--slider .slick-slide > div {
      height: 100%; }
    @media (max-width: 1349.98px) {
      .testimonial-block--slider {
        display: none; } }
  .testimonial-block--standard.testimonial-block--standard--animated .testimonial-block__image:before {
    left: -209px; }
  .testimonial-block .line-mask {
    position: absolute;
    z-index: 3;
    top: 0;
    right: -240px;
    left: unset;
    height: 100%;
    transform: rotateY(180deg);
    overflow: hidden;
    scale: 1.5; }
  @media (max-width: 1539.98px) {
    .testimonial-block {
      padding: 105px 225px; } }
  @media (max-width: 1349.98px) {
    .testimonial-block {
      padding: 55px 110px; } }
  @media (max-width: 767.98px) {
    .testimonial-block {
      background-image: none !important;
      padding: 0; } }

.post-content blockquote p {
  margin-bottom: 1em !important; }

.blockquote img,
.blockquote svg {
  width: 53px;
  margin-bottom: 32px; }
  @media (max-width: 767.98px) {
    .blockquote img path,
    .blockquote svg path {
      fill: #005170; } }

.blockquote__author {
  margin-bottom: 0;
  text-align: center; }

.blockquote .bg--green > blockquote {
  color: #08384D; }

.content-image-blocks {
  position: relative;
  --block-gap: 10rem; }
  @media (max-width: 1539.98px) {
    .content-image-blocks {
      --block-gap: 6rem; } }
  .content-image-blocks__section {
    display: grid;
    grid-template-columns: 33% 13% 8% 13% 33%;
    grid-template-rows: 1fr; }
    .content-image-blocks__section > div {
      grid-row: 1; }
    .content-image-blocks__section:not(:last-of-type) {
      margin-bottom: var(--block-gap); }
    .content-image-blocks__section:nth-of-type(odd) .content-image-blocks__image {
      grid-column: 1 / 4; }
    .content-image-blocks__section:nth-of-type(odd) .content-image-blocks__content {
      grid-column: 5 / 6; }
    .content-image-blocks__section:nth-of-type(even) .content-image-blocks__image {
      grid-column: 3 / 6;
      justify-content: flex-start; }
      .content-image-blocks__section:nth-of-type(even) .content-image-blocks__image img {
        margin-left: 0; }
      .content-image-blocks__section:nth-of-type(even) .content-image-blocks__image div {
        position: relative; }
        .content-image-blocks__section:nth-of-type(even) .content-image-blocks__image div:before {
          content: '';
          left: revert;
          right: 0; }
          @media (max-width: 767.98px) {
            .content-image-blocks__section:nth-of-type(even) .content-image-blocks__image div:before {
              right: -15px; } }
    .content-image-blocks__section:nth-of-type(even) .content-image-blocks__content {
      grid-column: 1 / 2; }
    .content-image-blocks__section:nth-of-type(3n+1) .content-image-blocks__image div:before {
      background: #FAE9C4; }
    .content-image-blocks__section:nth-of-type(3n+2) .content-image-blocks__image div:before {
      background: #DCF2E3; }
    .content-image-blocks__section:nth-of-type(3n+3) .content-image-blocks__image div:before {
      background: #DCE7ED; }
  .content-image-blocks__content a.icon {
    display: inline-block;
    text-decoration: none; }
    .content-image-blocks__content a.icon span {
      position: relative;
      top: 2px; }
    .content-image-blocks__content a.icon:hover span {
      text-decoration: underline; }
    .content-image-blocks__content a.icon img {
      margin-right: 5px; }
  .content-image-blocks__content p:last-of-type {
    margin-bottom: 0; }
  .content-image-blocks__image {
    padding-bottom: 5vw;
    position: relative;
    display: flex;
    align-items: flex-start; }
    .content-image-blocks__image img {
      width: calc(100% - 5vw);
      margin-left: 5vw; }
    .content-image-blocks__image div {
      position: relative;
      width: 100%; }
      .content-image-blocks__image div:before {
        content: '';
        display: block;
        width: calc(100% - 5vw);
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 5vw;
        left: 0; }
        @media (max-width: 767.98px) {
          .content-image-blocks__image div:before {
            left: -15px;
            width: calc(100% - 3.5rem); } }
  .content-image-blocks__break {
    text-align: center;
    display: inline-block;
    margin: 0 auto var(--block-gap);
    padding: 0 5.5rem 1.2rem;
    border-bottom: 1px solid currentColor;
    background-color: #fff;
    position: relative; }
    .content-image-blocks__break:before {
      content: "";
      width: calc(100% + var(--block-gap));
      height: calc(calc(100% + 1px) + calc(var(--block-gap) * 2));
      display: block;
      position: absolute;
      z-index: -1;
      left: calc(-1 * calc(var(--block-gap) / 2));
      top: calc(-1 * var(--block-gap));
      background: white; }
  .content-image-blocks:before {
    content: "";
    background: #005170;
    width: 1px;
    height: 0;
    display: block;
    position: absolute;
    top: calc(-1 * var(--block-gap));
    left: 50%;
    transform: translate(-50%);
    z-index: -1;
    transition: height 5s ease-in-out; }
  .content-image-blocks--animated:before {
    height: calc(100% + var(--block-gap)); }
  @media (max-width: 1000px) {
    .content-image-blocks--animated:before {
      display: none; }
    .content-image-blocks__section {
      display: block; }
      .content-image-blocks__section + .content-image-blocks__section {
        margin-top: 3rem; }
    .content-image-blocks__image {
      margin-bottom: 5rem; } }
  @media (max-width: 1349.98px) {
    .content-image-blocks {
      padding-bottom: 80px; } }
  @media (max-width: 767.98px) {
    .content-image-blocks {
      padding-bottom: 40px; } }

.goto-block {
  margin-top: 10rem;
  margin-bottom: 6rem; }
  .goto-block a {
    text-decoration: none;
    color: #636363;
    font-size: 1.5rem; }
    .goto-block a:hover {
      text-decoration: underline; }
  .goto-block ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .goto-block ul li:not(:last-of-type) {
      margin-right: 15px; }
      .goto-block ul li:not(:last-of-type):after {
        content: "|";
        margin-left: 15px; }
  @media (max-width: 1349.98px) {
    .goto-block {
      display: none; } }

.quick-links {
  margin-top: 10rem;
  margin-bottom: 11rem;
  position: relative; }
  .quick-links:before {
    content: "";
    background: rgba(99, 99, 99, 0.1);
    bottom: -1rem;
    left: 0;
    height: calc(50% + 3rem);
    width: 100%;
    display: block;
    position: absolute; }
    @media (max-width: 1349.98px) {
      .quick-links:before {
        height: 75%; } }
    @media (max-width: 767.98px) {
      .quick-links:before {
        height: 91%; } }
  .quick-links-section__title {
    color: #DCF2E3;
    text-transform: uppercase;
    margin-bottom: 48px;
    font-weight: 800; }
  .quick-links__title {
    color: #DCF2E3;
    margin-bottom: 16px;
    min-height: 56px;
    display: block;
    font-weight: 600; }
    .quick-links__top .quick-links__title {
      margin-bottom: 50px; }
      @media (max-width: 767.98px) {
        .quick-links__top .quick-links__title {
          margin-bottom: 0; } }
  .quick-links__description {
    color: #fff; }
    .quick-links__description > p {
      color: #fff; }
  .quick-links__inner {
    padding: 0; }
  @media (max-width: 767.98px) {
    .quick-links {
      margin-top: 4rem; } }
  .quick-links__row-item {
    max-width: calc(25% - 20px);
    flex-basis: calc(25% - 20px);
    margin-right: 25px;
    height: 220px; }
    .quick-links__row-item:last-of-type {
      margin-right: 0; }
    .single-members .quick-links__row-item {
      height: 345px; }
    @media (min-width: 480px) and (max-width: 1539.98px) {
      .quick-links__row-item {
        display: flex; } }
    @media (max-width: 1349.98px) {
      .quick-links__row-item {
        max-width: calc(50% - 20px);
        flex-basis: calc(50% - 20px); }
        .quick-links__row-item:nth-of-type(2) {
          margin-right: 0;
          margin-bottom: 30px; } }
    @media (max-width: 767.98px) {
      .quick-links__row-item {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
        margin-right: 0;
        height: 175px; } }
  .quick-links__link {
    padding: 32px 25px;
    position: relative;
    height: auto;
    width: 100%;
    height: 100%; }
    .single-members .quick-links__link {
      border-radius: 0; }
    .search .quick-links__link > a {
      color: #fff !important; }
    @media (max-width: 767.98px) {
      .quick-links__link {
        margin-bottom: 0;
        padding: 20px; } }
    .quick-links__link > h3 {
      margin-bottom: 36px;
      font-size: 3rem; }
    .quick-links__link > p {
      color: #fff;
      margin-bottom: 90px; }
    .quick-links__link .why-nuclear,
    .quick-links__link .what-is-nuclear-energy,
    .quick-links__link .people-in-nuclear,
    .quick-links__link .downloads,
    .quick-links__link .industry-opportunities {
      color: #fff !important; }
    .quick-links__link a {
      text-decoration: none;
      display: flex;
      color: #08384D;
      align-items: center;
      width: fit-content;
      position: absolute;
      bottom: 32px;
      transition: all 0.6s ease; }
      .single-members .quick-links__link a {
        color: #fff; }
      .quick-links__link a:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        background: 0 0; }
      @media (max-width: 767.98px) {
        .quick-links__link a {
          position: relative;
          bottom: 0; } }
    .quick-links__link__lines {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 75%;
      width: 10rem;
      max-height: 24rem;
      overflow: hidden; }
      .quick-links__link__lines img {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        mix-blend-mode: overlay; }
  @media (max-width: 1345px) {
    .quick-links h3 {
      margin-bottom: 3rem; } }
  @media (max-width: 1819.98px) {
    .quick-links h3 {
      font-size: calc(2.4rem + 0.6 * ( (100vw - 540px) / 620)); } }
  @media screen and (max-width: 1819.98px) and (max-width: 540px) {
    .quick-links h3 {
      font-size: 2.4rem; } }
  @media screen and (max-width: 1819.98px) and (min-width: 1160px) {
    .quick-links h3 {
      font-size: 3rem; } }
  @media (max-width: 1819.98px) {
    .quick-links a {
      font-size: 1.6rem; } }
  .quick-links-bottom__wrapper {
    margin-top: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
    border-radius: 8px; }

.map-block {
  margin-top: 20rem; }
  .map-block #map {
    margin: -10rem 0 10rem; }
  @media (max-width: 767.98px) {
    .map-block {
      margin-top: 10rem; }
      .map-block #map {
        margin: -4rem 2rem 4rem;
        height: 30rem !important; } }

.contact-sections {
  margin-top: 10rem; }
  @media (max-width: 1539.98px) {
    .contact-sections {
      margin-top: 6rem; } }
  .contact-sections__details {
    margin-left: 0;
    margin-right: 0; }
  .contact-sections__section {
    padding-left: 3rem;
    border-left: 1px solid #636363;
    min-width: 28rem; }
    .contact-sections__section:nth-of-type(1) {
      padding-left: 0;
      border-left: 0;
      min-width: auto; }
    @media (max-width: 1349.98px) {
      .contact-sections__section:nth-of-type(1) {
        padding-left: 3rem;
        border-left: 1px solid #636363; } }
    @media (min-width: 768px) and (max-width: 1349.98px) {
      .contact-sections__section {
        min-width: 50%;
        max-width: 50%; }
        .contact-sections__section:nth-of-type(1), .contact-sections__section:nth-of-type(2) {
          margin-bottom: 3rem; } }
  @media (max-width: 767.98px) {
    .contact-sections .col {
      min-width: 100%; }
    .contact-sections .row {
      flex-direction: column;
      align-items: center; }
    .contact-sections__section {
      margin-top: 3rem;
      padding-left: 15px;
      min-width: 0 !important;
      width: 28rem; } }

.error404 .hero {
  padding-top: 26rem;
  padding-bottom: 10rem; }
  @media (max-width: 1539.98px) {
    .error404 .hero {
      padding-top: 8rem;
      padding-bottom: 8rem; } }

.error404 .hero__error404 a {
  margin-top: 12rem; }

.page-template-template-general-content .hub-section {
  margin-bottom: 8rem; }
  @media (max-width: 1539.98px) {
    .page-template-template-general-content .hub-section {
      margin-bottom: 4rem; } }

.twitter-feed-block {
  margin-bottom: 6.8rem;
  padding-bottom: 5rem; }
  @media (max-width: 767.98px) {
    .twitter-feed-block {
      margin-bottom: 0; } }
  .twitter-feed-block__heading {
    padding: 5.5rem 0; }
  .twitter-feed-block__outer {
    margin-top: 10rem; }
  .twitter-feed-block .twitter-feed__feed .ctf-item {
    border-radius: 8px; }
    .twitter-feed-block .twitter-feed__feed .ctf-item .ctf-author-box {
      position: absolute;
      left: 0;
      bottom: 1rem;
      width: 7rem; }
      .twitter-feed-block .twitter-feed__feed .ctf-item .ctf-author-box .ctf-corner-logo {
        margin-top: -6px; }
    @media (min-width: 768px) and (max-width: 1349.98px) {
      .twitter-feed-block .twitter-feed__feed .ctf-item:nth-of-type(3) {
        display: none; } }
  .twitter-feed-block .twitter-feed__feed .ctf-tweet-content {
    display: flex !important;
    flex-direction: column-reverse;
    margin-top: 0 !important;
    height: 100%;
    padding: 0 !important;
    background: white; }
  .twitter-feed-block .twitter-feed__feed .ctf-tweet-media {
    flex-basis: 50%;
    flex-shrink: 0;
    margin: 0 !important; }
    .twitter-feed-block .twitter-feed__feed .ctf-tweet-media a:after {
      display: none !important; }
    .twitter-feed-block .twitter-feed__feed .ctf-tweet-media .ctf-lightbox-link,
    .twitter-feed-block .twitter-feed__feed .ctf-tweet-media .ctf-media-link {
      margin: 0 !important;
      height: 100% !important; }
  .twitter-feed-block .twitter-feed__feed #ctf p.ctf-tweet-text {
    padding: 5rem 2.2rem 4rem 2.2rem !important;
    font-size: 2rem;
    line-height: 1.1;
    color: #005170;
    background: white;
    height: 50%;
    flex-grow: 1; }
  .twitter-feed-block .twitter-feed__feed .ctf-context {
    position: absolute;
    top: 25px;
    left: 0;
    z-index: 1;
    background: white;
    width: auto !important;
    border-radius: 0 0px 10px 0px;
    opacity: 0.9; }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-twitter-card .ctf-tc-summary-info {
    padding: 0rem 2%;
    margin-top: -7px;
    width: 76% !important; }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-tc-image {
    width: 20% !important;
    height: 88px; }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-tweet-media a {
    width: 100%;
    border-radius: 0; }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-hide-author .ctf-tweet-meta a {
    font-size: 0; }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-tweet-media img,
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-tweet-media video {
    height: 25rem;
    object-fit: cover;
    background: rgba(44, 44, 44, 0.17);
    margin: 0;
    margin-bottom: auto;
    border-radius: 0;
    border: none;
    padding: 4rem 2rem;
    font-size: 15px;
    width: 100%;
    flex-shrink: 0;
    opacity: 1 !important; }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf-twitter-card {
    height: 25rem;
    object-fit: cover;
    background: rgba(44, 44, 44, 0.17);
    margin: 0;
    margin-bottom: auto;
    border-radius: 0;
    border: none;
    padding: 4rem 2rem;
    font-size: 15px;
    width: 100%;
    flex-shrink: 0; }
    @media (max-width: 767.98px) {
      .twitter-feed-block .twitter-feed__feed #ctf .ctf-twitter-card {
        height: auto; } }
  .twitter-feed-block .twitter-feed__feed .ctf-corner-logo {
    background: #005170;
    border-radius: 50%;
    padding: 0.8rem;
    line-height: 0;
    margin-top: -3rem !important;
    left: 24px !important;
    right: unset !important;
    z-index: 1; }
    .twitter-feed-block .twitter-feed__feed .ctf-corner-logo path {
      fill: white; }
  .twitter-feed-block .twitter-feed__feed .ctf-tweet-items {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    width: 100%; }
    @media (min-width: 768px) and (max-width: 1349.98px) {
      .twitter-feed-block .twitter-feed__feed .ctf-tweet-items {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 767.98px) {
      .twitter-feed-block .twitter-feed__feed .ctf-tweet-items {
        grid-template-columns: 1fr; } }
  .twitter-feed-block .twitter-feed__feed #ctf .ctf_more {
    display: none; }

@media (max-width: 767.98px) {
  .twitter-feed-block__outer {
    background: rgba(99, 99, 99, 0.1); }
    .twitter-feed-block__outer .bg--grey-light {
      background: transparent; } }

.intro-block {
  margin-top: 10rem; }
  .intro-block__intro {
    text-align: center; }
    .page-id-335 .intro-block__intro {
      font-weight: 400;
      margin-bottom: 32px; }
      .page-id-335 .intro-block__intro > p {
        margin-bottom: 0;
        text-align: left; }
    .intro-block__intro > p {
      color: #08384D; }
  .page-id-335 .intro-block {
    margin-top: 32px; }
  @media (max-width: 767.98px) {
    .intro-block {
      margin-top: 6rem; } }
  .intro-block__content {
    margin: 0 auto;
    max-width: 1060px; }
    .intro-block__content p:last-of-type {
      margin-bottom: 0; }
  .intro-block .video-container {
    margin-top: 7rem; }
    @media (max-width: 767.98px) {
      .intro-block .video-container {
        margin-top: 5rem; } }
  .intro-block .line {
    width: 23.5rem;
    height: 1px;
    background: currentColor;
    margin: 5rem auto; }
    @media (max-width: 767.98px) {
      .intro-block .line {
        margin: 3.5rem auto; } }
  .intro-block .members-edit {
    text-decoration: none;
    font-weight: 600;
    display: flex;
    align-items: center; }
    .intro-block .members-edit:after {
      display: flex;
      content: '';
      background-image: url("../assets/images/arrow_forward_24_navy.svg");
      width: 24px;
      height: 24px;
      margin-left: 8px; }
    .intro-block .members-edit:hover:after {
      background-image: url("../assets/images/arrow_forward_24_deepblue.svg"); }

.intro-block__content-container + .intro-block__content-container {
  margin-top: 4rem; }

.video-container {
  background: #f5f5f5;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @supports (aspect-ratio: 1/1) {
    .video-container {
      aspect-ratio: 16 / 9; } }
  .video-container button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: none;
    border: none;
    font-size: 0; }
    .video-container button:after {
      display: none; }
    .video-container button:hover {
      background: transparent; }
      .video-container button:hover svg path {
        fill: #636363; }
  .video-container__cover,
  .video-container iframe {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover; }
    @supports (aspect-ratio: 1/1) {
      .video-container__cover,
      .video-container iframe {
        aspect-ratio: 16 / 9;
        position: absolute; } }

.post-item-grid {
  margin-top: 10rem;
  padding: 10rem 0; }
  @media (max-width: 1349.98px) {
    .post-item-grid {
      margin-top: 6rem;
      padding: 6rem 3rem; } }
  .post-item-grid .block-header__line {
    background: #005170; }
  .post-item-grid h3.heading {
    font-size: calc(2.1rem + 0.3 * ( (100vw - 540px) / 620));
    line-height: 1.2;
    font-weight: 600; }
    @media screen and (max-width: 540px) {
      .post-item-grid h3.heading {
        font-size: 2.1rem; } }
    @media screen and (min-width: 1160px) {
      .post-item-grid h3.heading {
        font-size: 2.4rem; } }
  .post-item-grid__row .post-item-grid__post-card {
    margin-bottom: 3rem; }
  @supports (aspect-ratio: 1/1) {
    .post-item-grid__row {
      gap: 3rem 0; }
      .post-item-grid__row .post-item-grid__post-card {
        margin-bottom: 0; } }

.filter-toggle {
  display: none !important;
  border: none;
  background: none;
  font-weight: 600;
  width: 100%;
  padding: 1rem 0;
  position: relative;
  text-align: left;
  color: #636363; }
  .filter-toggle:before, .filter-toggle:after {
    content: "";
    height: 1px;
    width: 2.4rem;
    display: block;
    position: absolute;
    background: #707070;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    will-change: transform;
    transition: transform .2s ease-in; }
  .filter-toggle:after {
    transform: rotate(90deg); }
  @media (max-width: 1349.98px) {
    .filter-toggle {
      display: block !important; } }
  .filter-toggle.open:after {
    transform: rotate(0); }

.page-template-template-members-hub .filter-toggle {
  display: none !important; }

.page-template-template-members-hub .the-hub__filters .searchandfilter h4 {
  display: block; }

@media (max-width: 800px) {
  .page-template-template-members-hub .filter-toggle {
    display: block !important;
    border-top: 1px solid currentColor; }
  .page-template-template-members-hub .the-hub__filters .searchandfilter h4 {
    display: none; }
  .page-template-template-members-hub .searchandfilter {
    display: none; } }

.cli_messagebar_head {
  font-weight: 600;
  color: #005170;
  font-size: 24px;
  margin-bottom: 1em; }

.hub-section__body .bg--blue {
  padding-bottom: 50px; }

@media (max-width: 1349.98px) {
  .hub-section__btn {
    display: none; } }

.hub-section__btn--mobile {
  display: none; }
  @media (max-width: 1349.98px) {
    .hub-section__btn--mobile {
      display: flex;
      margin: 24px auto; } }

.business-group-card {
  background-color: #fff;
  padding: 32px 24px;
  border-radius: 8px;
  max-width: 343px;
  flex-basis: 343px;
  margin-right: 40px; }
  .business-group-card:nth-child(1) {
    margin-top: 0 !important; }
  @media (max-width: 1349.98px) {
    .business-group-card:nth-child(2) {
      margin-right: 0; } }
  @media (max-width: 767.98px) {
    .business-group-card:nth-child(3) {
      margin-top: 40px; } }
  .business-group-card__contact {
    margin-bottom: 0;
    font-weight: 600; }
  .business-group-card__about {
    margin-bottom: 24px; }
  .business-group-card__back-link {
    background-color: #fff;
    padding: 32px 24px;
    width: 100%;
    border-radius: 8px;
    margin-top: 48px; }
  .business-group-card .card-links {
    display: flex;
    align-items: center; }
  .business-group-card .card-email {
    display: flex; }
    .business-group-card .card-email:hover {
      color: #08384D; }
      .business-group-card .card-email:hover > svg path {
        fill: #08384D; }
  .business-group-card .card-number {
    display: flex;
    align-items: center;
    text-decoration: none; }
    .business-group-card .card-number:before {
      content: '';
      background-image: url("../assets/images/events-call_deepblue.svg");
      height: 20px;
      width: 20px;
      display: flex;
      margin-right: 8px;
      margin-left: 16px; }
    .business-group-card .card-number:hover {
      text-decoration: underline;
      color: #08384D; }
      .business-group-card .card-number:hover:before {
        background-image: url("../assets/images/events-call_navy.svg"); }
  .business-group-cards__wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #DCE7ED;
    border-radius: 8px;
    margin-top: 72px;
    margin-bottom: 72px; }
    @media (max-width: 1539.98px) {
      .business-group-cards__wrapper {
        padding-bottom: 15px;
        padding-top: 15px; } }
    @media (max-width: 767.98px) {
      .business-group-cards__wrapper {
        border-radius: 0; } }
  .business-group-cards__container {
    display: flex;
    margin-bottom: 48px;
    flex-wrap: wrap; }
    .business-group-cards__container:last-of-type {
      margin-bottom: 0; }
  .business-group-cards__title {
    text-transform: uppercase;
    font-weight: 800;
    color: #005170;
    margin-bottom: 40px; }
  .business-group-cards__link-title {
    margin-bottom: 32px;
    text-transform: uppercase;
    font-weight: 800;
    color: #005170; }
  @media (max-width: 1349.98px) {
    .business-group-card {
      max-width: calc(50% - 20px);
      flex-basis: calc(50% - 20px); }
      .business-group-card:last-of-type {
        margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .business-group-card {
      max-width: 100%;
      flex-basis: 100%;
      margin-right: 0;
      margin-top: 40px; } }

.searchresults-head .searchandfilter {
  display: flex;
  margin-bottom: 48px; }
  .searchresults-head .searchandfilter > ul {
    padding: 0; }
  .searchresults-head .searchandfilter [data-sf-field-input-type=radio] {
    display: flex;
    align-items: center; }
    .searchresults-head .searchandfilter [data-sf-field-input-type=radio] > ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 16px; }
    .searchresults-head .searchandfilter [data-sf-field-input-type=radio] li {
      position: relative;
      margin-right: 16px;
      padding: 8px 12px;
      border: 1px solid #08384d;
      border-radius: 10px;
      cursor: pointer; }
      .searchresults-head .searchandfilter [data-sf-field-input-type=radio] li:hover {
        background: #00bae1;
        border: 1px solid transparent; }
      .searchresults-head .searchandfilter [data-sf-field-input-type=radio] li > input {
        display: none; }
      .searchresults-head .searchandfilter [data-sf-field-input-type=radio] li > label {
        padding-left: 0 !important;
        font-weight: 600;
        color: #08384D;
        cursor: pointer; }
    .searchresults-head .searchandfilter [data-sf-field-input-type=radio] .sf-option-active {
      background: #00bae1;
      border: 1px solid transparent; }
  @media (max-width: 1349.98px) {
    .searchresults-head .searchandfilter {
      display: none; } }

.searchresults-result {
  font-weight: 600;
  color: #525252;
  margin-bottom: 48px; }
  @media (max-width: 1349.98px) {
    .searchresults-result {
      margin-top: 60px; } }

.searchresults-post {
  display: flex;
  flex-direction: column; }

.searchresults-title {
  padding-bottom: 28px;
  border-bottom: 1px solid #005170;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between; }
  .searchresults-title .search-term {
    text-transform: capitalize;
    color: #005170; }

#search-filter-form-10476 > ul .sf-field-search {
  display: none; }

.search__grid a {
  text-decoration: none; }

.post-card {
  position: relative;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  transition: all 0.6s ease; }
  .post-card.Events .post-heading {
    margin-bottom: 16px; }
  .post-card__meta {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px; }
  .post-card__eyebrow {
    color: #525252;
    margin-bottom: 0; }
    .post-card__eyebrow:first-of-type {
      font-weight: 700; }
      .Events .post-card__eyebrow:first-of-type {
        margin-bottom: 16px; }
  .post-card__header {
    height: 192px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
    .post-card__header img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .event-logo .post-card__header img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        object-position: left; }
    .event-logo .post-card__header {
      position: static;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 110px; }
    .post-card__header--logo {
      padding: 4rem; }
      .post-card__header--logo img {
        object-fit: contain; }
      .event-logo .post-card__header--logo {
        padding: 0 32px 32px 32px; }
    .event-logo .post-card__header .icon {
      position: absolute;
      top: 32px;
      left: 24px; }
    .Events .post-card__header .icon,
    .Publications .post-card__header .icon,
    .News .post-card__header .icon,
    .Video .post-card__header .icon,
    .Blog .post-card__header .icon,
    .BriefsConsultations .post-card__header .icon {
      position: absolute;
      bottom: 24px;
      left: 24px; }
  .post-card.PressRelease .post-card__body {
    justify-content: center; }
  .post-card .promo-article {
    border-radius: 8px; }
  .post-card__body {
    padding: 32px 24px;
    flex-grow: 1;
    border-radius: 0 0 8px 8px; }
    .post-card__body .promo-article-cat {
      color: #525252;
      margin-bottom: 16px; }
    .post-card__body .icon {
      margin-bottom: 24px; }
    .flex-row-reverse .post-card__body {
      border-radius: 8px 8px 8px 8px; }
    .post-card__body a {
      text-decoration: none; }
      .post-card__body a:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        background: transparent; }
    .event-logo .post-card__body {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      justify-content: end; }
      .event-logo .post-card__body.PressRelease {
        justify-content: center; }
    .post-card__body:hover .post-card__footer a {
      text-decoration: underline; }
  .post-card__footer {
    margin-top: auto; }
  .post-card:hover {
    filter: drop-shadow(rgba(0, 81, 112, 0.15) -4px 4px 24px); }
    .post-card:hover .post-heading {
      text-decoration: underline; }

.searchandfilter [data-sf-field-input-type="checkbox"] ul li {
  padding: 0.8rem 0; }

.searchandfilter ul li {
  padding: 1.5rem 0; }
  .searchandfilter ul li:first-of-type {
    padding-top: 0; }
  .searchandfilter ul li:last-of-type {
    padding-bottom: 0; }

.the-hub {
  margin-bottom: 80px; }
  .the-hub__wrapper {
    margin-bottom: 0; }
    @media (min-width: 1540px) {
      .the-hub__wrapper.bg--blue .container {
        background: #DCE7ED;
        border-radius: 8px; } }
    @media (min-width: 1540px) {
      .the-hub__wrapper.bg--blue {
        background: #fff; } }
    @media (max-width: 1539.98px) {
      .the-hub__wrapper {
        border-radius: 0; } }
  .the-hub__featured {
    background: #7452E3;
    border-radius: 10px;
    margin-top: 80px; }
    .the-hub__featured .featured__img {
      width: calc(50% - 15px);
      max-height: 403px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
      @media (max-width: 1349.98px) {
        .the-hub__featured .featured__img {
          width: 100%;
          border-bottom-left-radius: 0;
          border-top-right-radius: 10px; } }
    .the-hub__featured .featured__category {
      margin-bottom: 0;
      font-weight: 700; }
    .the-hub__featured .featured__content {
      width: calc(50% + 15px);
      padding: 0 48px; }
      .the-hub__featured .featured__content .icon {
        margin-bottom: 16px; }
      @media (max-width: 1349.98px) {
        .the-hub__featured .featured__content {
          width: 100%;
          padding: 32px 24px; } }
    .the-hub__featured .featured__date {
      margin-bottom: 16px; }
    .the-hub__featured .featured__title {
      color: #fff; }
  .the-hub__eyebrow {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff; }
  .the-hub__header {
    position: relative;
    padding-top: 32px;
    padding-bottom: 32px; }
    .the-hub__header .heading {
      color: #7452E3; }
      .page-template-template-members-hub .the-hub__header .heading {
        color: #DCF2E3;
        margin-bottom: 32px; }
    .the-hub__header strong {
      font-weight: 800; }
    .the-hub__header-links {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 295px;
      display: flex; }
      .the-hub__header-links > a {
        margin-left: 0 !important; }
        .the-hub__header-links > a:first-of-type {
          margin-right: 24px; }
        .the-hub__header-links > a:hover {
          text-decoration: none;
          color: #fff; }
      .the-hub__header-links .link-help {
        display: flex; }
        .the-hub__header-links .link-help:after {
          content: "";
          background-image: url("../assets/images/help-navy.svg");
          width: 24px;
          height: 24px;
          margin-left: 8px; }
      .the-hub__header-links .link-logout {
        display: flex; }
        .the-hub__header-links .link-logout:after {
          content: "";
          background-image: url("../assets/images/logout-navy.svg");
          width: 24px;
          height: 24px;
          margin-left: 8px; }
      @media (min-width: 1350px) and (max-width: 1819.98px) {
        .the-hub__header-links {
          top: 3rem; } }
      @media (max-width: 767.98px) {
        .the-hub__header-links {
          top: -100px;
          left: 15px; } }
    .the-hub__header div {
      z-index: 1; }
    @media (max-width: 767.98px) {
      .the-hub__header {
        padding-bottom: 0; } }
  .the-hub__heading {
    margin-bottom: 16px; }
  .the-hub__support {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #525252; }
  .the-hub .the-hub__header__lines {
    z-index: 0; }
    .the-hub .the-hub__header__lines img {
      transition: clip-path 1.5s ease-in;
      transition-delay: 0.5s; }
  .the-hub .lines-container--animated .the-hub__header__lines img {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%) !important; }
  .the-hub .members-hub__background {
    background: linear-gradient(to left, rgba(255, 255, 255, 0.1), transparent) !important; }
  .the-hub__grid {
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: min-content 1fr;
    grid-template-areas: "one one two three" "four five six seven" "eight nine ten ten" "eleven twelve thirteen fourteen";
    margin-top: 80px; }
    .blog .the-hub__grid {
      margin-bottom: 80px; }
    .the-hub__grid-wrapper {
      border-radius: 8px;
      padding-bottom: 80px; }
    .the-hub__grid-title {
      margin-top: 80px;
      margin-bottom: 32px;
      color: #DCF2E3;
      font-weight: 800; }
    @media (max-width: 1349.98px) {
      .the-hub__grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content 1fr;
        grid-template-areas: "one one two" "three four five" "six seven eight" "nine ten ten" "eleven twelve thirteen" "fourteen fifteen sixteen";
        margin-top: 40px; } }
    @media (max-width: 800px) {
      .the-hub__grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "one two" "three four" "five six" "seven eight" "nine ten" "eleven twelve" "thirteen fourteen";
        margin-top: 40px; } }
    @media (max-width: 600px) {
      .the-hub__grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: "one" "two" "three" "four" "five" "six" "seven" "eight" "nine" "ten" "eleven" "twelve" "thirteen" "fourteen";
        margin-top: 20px; } }
  .the-hub .post-heading {
    color: #08384D; }
  .the-hub__posts > a {
    text-decoration: none; }
    @media (max-width: 1349.98px) {
      .the-hub__posts > a {
        display: none; } }
  .the-hub .post-card {
    border-color: #654EAF; }
    @media (max-width: 600px) {
      .the-hub .post-card {
        grid-column: 1 / -1; } }
  @media (max-width: 767.98px) {
    .the-hub__filters .searchandfilter {
      display: none; }
      .the-hub__filters .searchandfilter--open {
        display: block; } }
  .the-hub__filters .searchandfilter {
    display: flex; }
    @media (max-width: 1819.98px) {
      .the-hub__filters .searchandfilter {
        font-size: 16px; } }
    .the-hub__filters .searchandfilter ul {
      padding: 0; }
    .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] {
      display: flex;
      align-items: center; }
      .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] input[type=radio] {
        opacity: 0;
        position: fixed;
        width: 0; }
      .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 16px; }
      .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] li {
        position: relative;
        margin-right: 16px;
        padding: 8px 12px;
        border: 1px solid #08384D;
        border-radius: 10px; }
        .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] li:hover {
          background: #00BAE1;
          border: 1px solid transparent;
          color: #08384D; }
      .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] label {
        padding-left: 0;
        cursor: pointer;
        font-weight: 600; }
        .blog .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] label {
          color: #08384D; }
      .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] .sf-option-active {
        background: #00BAE1;
        border: 1px solid transparent; }
      .the-hub__filters .searchandfilter [data-sf-field-input-type="radio"] li.sf-option-active:hover label:after {
        transform: none; }
    @media (max-width: 1349.98px) {
      .the-hub__filters .searchandfilter {
        display: none; }
        .the-hub__filters .searchandfilter--open {
          display: block; }
        .the-hub__filters .searchandfilter h4 {
          display: none; }
        .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] ul {
          margin-left: 0; }
          .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] ul li {
            margin-bottom: 1rem; }
          .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] ul label {
            padding-left: 0; } }
    @media (min-width: 1350px) and (max-width: 1539.98px) {
      .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] ul label {
        font-size: .8em; } }
  .the-hub__filters-inner {
    margin-bottom: 48px; }
    @media (max-width: 767.98px) {
      .the-hub__filters-inner {
        margin-bottom: 40px; } }
  .the-hub .members-hub__card-date {
    font-weight: 500;
    margin-top: 16px; }
  .the-hub--members {
    padding: 0 0 10rem;
    background-image: linear-gradient(to left, #005170, #08384D); }
    .the-hub--members .the-hub__header {
      padding: 160px 0 120px 0; }
    .the-hub--members .post-card {
      border-color: #005170; }
    .the-hub--members .the-hub__grid {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: min-content 1fr;
      grid-template-areas: "one one two" "three four five" "six seven seven"; }
      @media (max-width: 1150px) {
        .the-hub--members .the-hub__grid {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas: "one two" "three four" "five six" "seven eight"; } }
      @media (max-width: 700px) {
        .the-hub--members .the-hub__grid {
          grid-template-columns: repeat(1, 1fr);
          grid-template-areas: "one" "two" "three" "four" "five" "six" "seven"; } }
    .the-hub--members .the-hub__body .bg--blue {
      padding-bottom: 50px; }
    @media (max-width: 800px) {
      .the-hub--members .the-hub__body {
        flex-basis: 100%;
        max-width: 100%; } }
    @media (max-width: 800px) {
      .the-hub--members .the-hub__filters {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 4rem; }
        .the-hub--members .the-hub__filters ul {
          width: 100%; } }
    .the-hub--members .searchandfilter {
      display: flex;
      flex-direction: row;
      align-items: flex-start; }
      .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] {
        flex-direction: row;
        align-items: flex-start; }
        .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] h4 {
          margin-bottom: 1rem;
          width: 95px; }
        .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] ul {
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          margin-left: 16px; }
          @media (max-width: 767px) {
            .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] ul {
              margin-left: 0; } }
        .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] li {
          margin-left: 0;
          margin-bottom: 15px;
          margin-right: 14px;
          border: 1px solid #fff; }
          .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] li:last-of-type {
            margin-right: 0; }
        .the-hub--members .searchandfilter [data-sf-field-input-type="radio"] label {
          display: flex;
          align-items: center; }
      @media (max-width: 800px) {
        .the-hub--members .searchandfilter {
          display: none; } }
  .the-hub .quick-links {
    margin: 0;
    padding-right: 0; }
    .the-hub .quick-links__card {
      flex-basis: calc(25% - 20px);
      max-width: calc(25% - 20px);
      margin-right: 26px;
      border-radius: 10px;
      border: #fff 1px solid;
      transition: all 0.6s ease; }
      .the-hub .quick-links__card:last-of-type {
        margin-right: 0; }
      @media (max-width: 1349.98px) {
        .the-hub .quick-links__card:nth-child(2) {
          margin-right: 0; } }
      @media (max-width: 1349.98px) {
        .the-hub .quick-links__card {
          flex-basis: calc(50% - 20px);
          max-width: calc(50% - 20px);
          margin-bottom: 26px; } }
      @media (max-width: 767.98px) {
        .the-hub .quick-links__card {
          flex-basis: 100%;
          max-width: 100%;
          margin-bottom: 26px;
          margin-right: 0; } }
      .the-hub .quick-links__card:hover {
        background-color: rgba(255, 255, 255, 0.3); }
        .the-hub .quick-links__card:hover .quick-links__link .btn--white {
          background-color: #00BAE1;
          text-decoration: none;
          border: 1px solid #00BAE1;
          color: #08384D !important; }
    .the-hub .quick-links__filllink {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%; }
      .the-hub .quick-links__filllink:hover {
        background: transparent; }
    .the-hub .quick-links:before {
      display: none; }
    @media (max-width: 1349.98px) {
      .the-hub .quick-links {
        padding-left: 0;
        margin: 4rem -15px 0; } }
    @media (max-width: 767.98px) {
      .the-hub .quick-links {
        padding: 0 15px; } }
  .the-hub .filter-toggle {
    color: #08384D; }
    .the-hub .filter-toggle:before, .the-hub .filter-toggle:after {
      background: #08384D; }
    .page-template-template-members-hub .the-hub .filter-toggle {
      color: #fff; }
      .page-template-template-members-hub .the-hub .filter-toggle:before, .page-template-template-members-hub .the-hub .filter-toggle:after {
        background: #fff; }
  @media (max-width: 767.98px) {
    .the-hub > .container {
      max-width: 100%; } }

.hub-section {
  margin-top: 20px; }

.pagination {
  flex: 1 0 100%;
  display: flex;
  justify-content: center;
  padding: 0 8rem;
  grid-column: 1 / -1; }
  .pagination .nav-links {
    display: inline; }
    .pagination .nav-links .page-numbers:hover {
      color: #525252; }
      .pagination .nav-links .page-numbers:hover::before {
        background-image: url(../assets/images/arrow_forward_24_steel.svg); }
      .pagination .nav-links .page-numbers:hover::after {
        background-image: url(../assets/images/arrow_forward_24_steel.svg); }
    .pagination .nav-links .prev,
    .pagination .nav-links .next {
      font-weight: 600;
      display: flex;
      align-items: center; }
    .pagination .nav-links .prev {
      position: absolute;
      left: 0; }
      .pagination .nav-links .prev::before {
        content: '';
        background-image: url(../assets/images/arrow_forward_24.svg);
        display: block;
        width: 100%;
        transform: rotate(180deg);
        width: 24px;
        height: 24px;
        margin-right: 10px; }
    .pagination .nav-links .next {
      position: absolute;
      right: 0; }
      .pagination .nav-links .next::after {
        content: '';
        background-image: url(../assets/images/arrow_forward_24.svg);
        display: block;
        width: 100%;
        width: 24px;
        height: 24px;
        margin-left: 10px; }
  .pagination .prev,
  .pagination .next {
    font-weight: 600;
    display: flex;
    align-items: center; }
  .pagination .next {
    position: absolute;
    right: 0; }
    .pagination .next::after {
      content: '';
      background-image: url(../assets/images/arrow_forward_24_white.svg);
      display: block;
      width: 100%;
      width: 24px;
      height: 24px;
      margin-left: 10px; }
  .pagination .prev {
    position: absolute;
    left: 0; }
    .pagination .prev::before {
      content: '';
      background-image: url(../assets/images/arrow_forward_24_white.svg);
      display: block;
      width: 100%;
      transform: rotate(180deg);
      width: 24px;
      height: 24px;
      margin-right: 10px; }
  .pagination a {
    display: block;
    float: left;
    padding: 4px 9px;
    margin-right: 7px;
    text-decoration: none; }
  .pagination span.current,
  .pagination a.current {
    display: block;
    float: left;
    padding: 4px 9px;
    margin-right: 7px;
    border: 1px solid #efefef; }
  .pagination span.dots,
  .pagination a.dots {
    display: block;
    float: left;
    padding: 4px 9px;
    margin-right: 7px; }

.divider {
  width: 23.5rem;
  height: 1px;
  background: currentColor;
  margin: 1rem 0; }

.post-content .divider {
  margin: 4rem 0; }

.post-content .mobile-only {
  display: none; }

.post-content p:last-of-type {
  margin-bottom: 0; }

.post-content > p {
  color: #08384D !important; }

@media (max-width: 767.98px) {
  .post-content .mobile-only {
    display: inline-block !important; } }

.single-post .post-content h2,
.single-post .post-content h3,
.single-post .post-content h4,
.single-post .post-content h5 {
  color: #08384D; }

iframe {
  margin: 4rem 0; }
  iframe[src*="vimeo"], iframe[src*="youtube"] {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9; }

.post-body__right > a {
  display: flex;
  font-weight: 600;
  text-decoration: none;
  align-items: center;
  margin-top: 64px; }

.post-body .row .post-content .theme--blue .content-heading {
  color: #08384D; }

.post-body .blockquote {
  margin: 9rem 0 4rem; }

.post-body .accordion {
  margin-top: 5rem; }

@media (max-width: 767.98px) {
  .post-body .post-back-btn {
    display: none !important; }
  .post-body .mobile-hide,
  .post-body .blockquote {
    display: none; } }

@media (max-width: 800px) {
  .post-body .post-body__left {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 8rem; }
  .post-body .post-body__right {
    flex-basis: 100%;
    max-width: 100%; } }

@media (max-width: 520px) {
  .post-body .post-body__left {
    margin-bottom: 0; } }

.post-meta {
  padding-bottom: 3rem; }
  body.single-business_groups .post-meta {
    margin-bottom: 15rem; }
    @media (max-width: 767.98px) {
      body.single-business_groups .post-meta {
        margin-bottom: 0; } }
  .post-meta__details {
    max-width: 30rem; }
  .post-meta.icon-big .icon {
    margin-bottom: 5rem; }
    .post-meta.icon-big .icon svg,
    .post-meta.icon-big .icon img {
      width: 12rem;
      height: 12rem; }
      @media (max-width: 767.98px) {
        .post-meta.icon-big .icon svg,
        .post-meta.icon-big .icon img {
          width: 8rem;
          height: 8rem; } }

.additional-text-box {
  margin: 6rem 0; }
  .additional-text-box p {
    overflow-wrap: anywhere; }

.sponsors img {
  width: 23rem;
  object-fit: contain;
  max-height: 76px;
  object-position: left; }
  .sponsors img + p {
    margin-top: 4rem; }
    @media (max-width: 767.98px) {
      .sponsors img + p {
        margin-top: 2rem; } }

.sponsors a + p {
  margin-top: 4rem; }
  @media (max-width: 767.98px) {
    .sponsors a + p {
      margin-top: 2rem; } }

.sponsors p {
  margin-bottom: 1.5rem; }

.icon-list {
  margin: 4rem 0;
  padding: 32px;
  background-color: #DCE7ED;
  border-radius: 10px; }
  .icon-list__heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .icon-list__heading > img {
      margin-right: 16px; }
    .icon-list__heading > p {
      font-weight: 700; }
  .icon-list ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .icon-list ul > li {
      width: 31%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline; }
      .icon-list ul > li > a {
        font-size: 16px;
        width: 100%;
        font-weight: 600; }
      @media (max-width: 767.98px) {
        .icon-list ul > li {
          width: 100%;
          margin-bottom: 16px; } }
    .icon-list ul li:not(:last-of-type) {
      margin-bottom: 1.5rem; }
    @media (max-width: 767.98px) {
      .icon-list ul {
        flex-wrap: wrap;
        flex-direction: column; } }
  .icon-list a {
    text-decoration: none; }
    .icon-list a img {
      margin-right: 2px; }
    .icon-list a:hover span {
      text-decoration: underline; }

.members-hub-back:before {
  content: "";
  background-image: url("../assets/images/arrow_forward_24_navy.svg");
  height: 24px;
  width: 24px;
  display: flex;
  transform: rotateY(180deg);
  margin-right: 8px; }

.big-loop-list {
  padding-left: 3rem;
  padding-right: 3rem; }
  .big-loop-list__post {
    margin-bottom: 4rem;
    padding-bottom: 3.2rem;
    border-bottom: 1px solid #005170; }
    .big-loop-list__post .eyebrow {
      margin-bottom: 16px; }
    .big-loop-list__post .heading {
      margin: 0; }
  .big-loop-list .p {
    margin-bottom: 3rem; }

.page-template-template-members-hub .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] input:checked + label:after {
  top: 50%;
  transform: translateY(-50%); }

@media (max-width: 1349.98px) {
  .page-template-template-members-hub .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] label:hover:after {
    left: 13px;
    width: 2rem;
    height: 2rem; } }

@media (max-width: 1819.98px) {
  .page-template-template-members-hub .the-hub__filters .searchandfilter [data-sf-field-input-type=radio] label:hover:after {
    top: 50%; } }

.block-form {
  padding: 8.5rem 0; }
  .block-form__pre {
    line-height: 1.2;
    padding-top: 1rem;
    padding-right: 3rem;
    border-top: 1px solid currentColor;
    display: inline-block; }
  .block-form__container {
    position: relative; }
    @media (min-width: 768px) {
      .block-form__container:before {
        content: "";
        height: 100%;
        width: 1px;
        background: #005170;
        display: block;
        position: absolute;
        left: -3rem;
        top: 0; } }
    @media (min-width: 1350px) {
      .block-form__container:before {
        left: -6rem; } }
  @media (min-width: 1350px) {
    .block-form__addons {
      position: absolute;
      bottom: 3.3rem;
      right: 1.5rem; } }
  .block-form__addons a {
    color: #636363; }
  .block-form__addons .sign-up {
    margin-left: 3rem; }
  .block-form--event {
    background-color: #DCE7ED; }
  .block-form--login {
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 4; }
    .block-form--login .container:before {
      content: "";
      background: #DCE7ED;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50vw; }
    @media (min-width: 768px) and (max-width: 1349.98px) {
      .block-form--login .container:before {
        width: 100%; } }
  .block-form__main-area {
    padding-top: 8rem;
    padding-bottom: 8rem; }

.form__wrapper {
  justify-content: space-around; }
  @media (min-width: 768px) {
    .form__wrapper {
      background: #DCE7ED;
      padding-top: 56px;
      padding-bottom: 56px;
      margin-top: 34px;
      border-radius: 8px; } }
  @media (max-width: 1349.98px) {
    .form__wrapper {
      flex-direction: column-reverse;
      padding: 0 15px;
      padding-top: 56px;
      margin-top: 20px;
      background: #DCE7ED; } }

.form-info-side {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  position: sticky;
  top: 200px;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) -8px 8px 32px); }
  .form-info-side > a {
    text-decoration: none;
    color: #08384D; }
    .form-info-side > a:hover {
      text-decoration: underline;
      color: #00BAE1; }
      .form-info-side > a:hover.info-side__email:before {
        background-image: url(../assets/images/events-mail.svg); }
  .form-info-side > p {
    margin-bottom: 0;
    color: #08384D; }
    .form-info-side > p:nth-child(4) {
      margin-bottom: 32px; }
  @media (max-width: 1349.98px) {
    .form-info-side {
      position: relative;
      top: 0;
      max-width: 100%;
      margin-bottom: 60px; } }

.info-side__title {
  color: #525252 !important;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 16px !important;
  letter-spacing: 2px; }

.info-side__help {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px; }

.info-side__email {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  .info-side__email:before {
    content: '';
    background-image: url(../assets/images/events-mail.svg);
    display: flex;
    height: 20px;
    width: 20px;
    margin-right: 8px; }

.info-side__tel {
  display: flex;
  align-items: center; }
  .info-side__tel:before {
    content: '';
    background-image: url(../assets/images/events-call.svg);
    display: flex;
    height: 20px;
    width: 20px;
    margin-right: 8px; }

.big-form {
  padding-bottom: 72px; }
  @media (min-width: 1350px) {
    .big-form.bg--blue {
      background: #ffffff; } }
  @media (max-width: 1539.98px) {
    .big-form {
      padding-top: 0;
      display: flex;
      justify-content: center;
      border-radius: 0;
      margin-bottom: 48px; } }
  .big-form .frm_fields_container .frm_section_heading.frm_half:first-of-type {
    position: relative; }
    @media (min-width: 586px) {
      .big-form .frm_fields_container .frm_section_heading.frm_half:first-of-type:after {
        content: "";
        background: #005170;
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        right: -2%; } }
  @media (min-width: 568px) {
    .big-form .frm_fields_container .frm_form_field,
    .big-form .frm_fields_container .frm_submit {
      margin-bottom: 30px; } }
  .big-form .frm_form_title,
  .big-form .frm_description,
  .big-form .frm_description p {
    font-size: 2.4rem !important;
    margin-bottom: 0 !important;
    text-align: center !important; }
  .big-form .frm_description p {
    max-width: 40ch;
    margin: auto; }
  .big-form .frm_form_title {
    font-weight: 600; }
  .big-form .frm_forms form .frm_submit {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap; }
  .big-form .frm_style_formidable-style.with_frm_style .frm_rootline input[type="button"] {
    border-radius: 50%;
    max-width: 30px;
    min-width: unset;
    max-height: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-bottom: 0 !important; }
  .big-form .frm_style_formidable-style.with_frm_style .frm_rootline .frm_current_page input[type="button"] {
    border-color: #08384D !important;
    background-color: #08384D !important; }
  .big-form .frm_current_page .frm_rootline_title {
    color: #08384D; }
  .big-form .frm_rootline.frm_show_lines > .frm_rootline_single:after {
    background-color: #525252; }
  .big-form .frm_two_col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .big-form .frm_two_col .frm_primary_label {
      width: 100%; }
  .big-form .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading h3[class*="frm_pos_"] {
    border-top: none !important;
    font-size: 32px !important;
    font-weight: 800;
    text-transform: uppercase;
    color: #005170 !important;
    margin-bottom: 30px; }
  .big-form .with_frm_style .frm_checkbox label {
    color: #08384D !important; }
  .big-form .with_frm_style .frm_checkbox input[type=checkbox]:checked {
    background: #00BAE1 !important; }
  .big-form .frm_style_formidable-style.with_frm_style p.description, .big-form .frm_style_formidable-style.with_frm_style div.description, .big-form .frm_style_formidable-style.with_frm_style div.frm_description, .big-form .frm_style_formidable-style.with_frm_style .frm-show-form > div.frm_description, .big-form .frm_style_formidable-style.with_frm_style .frm_error, .big-form .frm_style_formidable-style.with_frm_style .frm_pro_max_limit_desc {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-weight: 400; }

.accordion {
  margin-top: 3rem;
  padding-bottom: 8rem; }
  .accordion__header {
    width: 100%;
    border: none;
    background: none;
    padding: 3rem 6rem 3rem 0;
    position: relative;
    text-align: left;
    color: #636363; }
    .accordion__header:hover {
      background: #F5F5F5; }
    .accordion__header:before, .accordion__header:after {
      content: "";
      height: 1px;
      width: 4.5rem;
      display: block;
      background: #707070;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      will-change: transform;
      transition: transform .2s ease-in; }
    .accordion__header:after {
      transform: rotate(90deg); }
  .accordion__content > *:first-of-type {
    margin-top: 0; }
  .accordion__content > *:last-of-type {
    margin-bottom: 0;
    padding-bottom: 3rem; }
  .accordion__row {
    border-top: 1px solid currentColor; }
    .accordion__row:last-of-type {
      border-bottom: 1px solid currentColor; }
    .accordion__row[aria-expanded="true"] .accordion__header:after {
      transform: rotate(0); }

.slick-slider .slick-track {
  display: flex;
  height: auto; }

.slick-slider .slick-slide {
  height: auto; }
  .slick-slider .slick-slide > div {
    height: 100%; }

.slick-slider button.slick-arrow {
  background-color: transparent;
  font-size: 0;
  padding: 0;
  border: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer; }
  .slick-slider button.slick-arrow:hover {
    opacity: 0.7;
    background-color: transparent; }
  .slick-slider button.slick-arrow:before {
    content: "";
    font-size: 2rem;
    color: white;
    padding: 1rem;
    display: block;
    line-height: 1;
    height: 2.4rem;
    width: 2.4rem; }
  .slick-slider button.slick-arrow.slick-prev {
    left: -9rem; }
    .slick-slider button.slick-arrow.slick-prev:before {
      content: "";
      background: url("../assets/images/arrow_forward_slider.svg") no-repeat center center/24px;
      transform: rotate(180deg); }
  .slick-slider button.slick-arrow.slick-next {
    right: -9rem; }
    .slick-slider button.slick-arrow.slick-next:before {
      content: "";
      background: url("../assets/images/arrow_forward_slider.svg") no-repeat center center/24px; }
  @media (max-width: 1349.98px) {
    .slick-slider button.slick-arrow {
      display: none !important; } }

.slick-slider .slick-dots {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center; }
  .slick-slider .slick-dots li:not(:last-of-type) {
    margin-right: 15px; }
  .slick-slider .slick-dots button {
    background: transparent;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 0;
    border-radius: 50%;
    border: 1px solid white;
    height: 10px;
    width: 10px; }
  .slick-slider .slick-dots .slick-active button {
    background: white; }

@media (max-width: 767.98px) {
  #testimonial-block__slider .slick-list {
    padding: 0 !important; } }

.hub-section__body {
  margin-top: 8rem; }
  @media (max-width: 767.98px) {
    .hub-section__body {
      max-width: 100%;
      padding-bottom: 24px; } }
  @media (min-width: 768px) and (max-width: 1349.98px) {
    .hub-section__body {
      max-width: 100%;
      padding: 0 3rem;
      padding-bottom: 24px; } }
  body.single .hub-section__body {
    margin-bottom: 6rem; }

.hub-section .slick-slider {
  margin-top: -8rem; }
  .hub-section .slick-slider.slick-dotted {
    padding-bottom: 0; }

.hub-section .slick-list {
  margin-left: -15px;
  margin-right: -15px; }

.hub-section .slick-slide {
  padding: 0 15px;
  display: flex; }
  .hub-section .slick-slide > div {
    display: block;
    height: 100%; }
  .hub-section .slick-slide img {
    width: 100%;
    border-radius: 8px 8px 0 0; }

.hub-section .slick-dots {
  padding: 4rem 0 5rem; }
  @media (max-width: 1349.98px) {
    .hub-section .slick-dots {
      display: none !important; } }

.search-field {
  border: none !important;
  font-size: 4.8rem !important;
  color: #fff;
  padding-left: 0 !important; }
  .search-field:focus-visible {
    outline: none !important; }
  @media (max-width: 767.98px) {
    .search-field {
      font-size: 1.8rem !important; } }

.search-form {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff !important; }
  .search-form > input::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .search-form > input:before {
    content: '';
    display: flex;
    width: 1px;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.5); }

.search-submit {
  margin-bottom: 8px;
  display: flex;
  align-items: center; }
  .search-submit:after {
    content: '';
    background-image: url("../assets/images/arrow_forward_24_white.svg");
    height: 24px;
    width: 24px;
    display: flex;
    margin-left: 8px; }

#loading-spinner {
  position: absolute;
  bottom: -24px; }

.frm_rootline_single input {
  border: none !important; }

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
select,
textarea {
  border: 1px solid #08384D;
  border-radius: 4px;
  width: 100%;
  min-width: 100px;
  font-size: 16px;
  padding: 14px 18px;
  background: transparent;
  transition: all 0.6s ease; }
  input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):hover,
  select:hover,
  textarea:hover {
    border: 1px solid #00BAE1; }

input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
select {
  height: 64px; }

textarea {
  height: auto; }

select {
  font-size: 18px;
  padding: 12px 20px;
  max-width: 25rem;
  height: 64px; }

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 1px solid #cbcbc7;
  background: #fff;
  top: 3px;
  padding: .1rem;
  display: inline-block;
  vertical-align: middle;
  appearance: none; }
  input[type="checkbox"]:checked {
    background: red; }

.sf-field-search input.sf-input-text {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: 18px !important; }

.sf-field-search > label {
  border-bottom: 1px solid #005170 !important;
  width: 100%; }

::-webkit-input-placeholder {
  color: #636363; }

::-moz-placeholder {
  color: #636363; }

:-ms-input-placeholder {
  color: #636363; }

:-moz-placeholder {
  color: #636363; }

.form {
  padding: 4rem; }
  .form--filter ul {
    padding: 0; }

.frm_fields_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-right: -1.5rem; }
  .frm_fields_container > .frm_form_field,
  .frm_fields_container > .frm_submit {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }

.frm_form_field {
  max-width: 100%;
  flex-basis: 100%;
  margin-bottom: 2rem; }

.frm_none_container .frm_primary_label {
  display: none; }

.frm_screen_reader,
.frm_verify {
  display: none; }

.frm_error_style {
  margin-bottom: 3rem; }

.frm_error,
.frm_error_style {
  color: #fff !important;
  background-color: #CC012F !important;
  padding: 15px !important;
  margin-top: 8px;
  display: flex;
  align-items: center; }
  .frm_error:before,
  .frm_error_style:before {
    content: '';
    background-image: url(../assets/images/error.svg);
    display: flex;
    height: 20px;
    width: 20px;
    margin-right: 8px; }

.frm_forms .frm_message {
  background: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 2rem !important;
  text-align: center !important; }
  .frm_forms .frm_message p {
    font-size: 24px !important;
    color: #005170; }

.frm_forms form {
  --font: "Work Sans", sans-serif;
  --border-color: #9B9B9B;
  --form-desc-size: 18px;
  --check-font-size: 18px;
  --check-label-color: #636363; }
  .frm_forms form input,
  .frm_forms form select,
  .frm_forms form textarea {
    background: white !important;
    border: 1px solid #9B9B9B;
    font-size: 16px !important; }
    .frm_forms form input:hover,
    .frm_forms form select:hover,
    .frm_forms form textarea:hover {
      border: 1px solid #00BAE1; }
  .frm_forms form textarea {
    height: auto !important; }
  .frm_forms form input[type="checkbox"] {
    box-shadow: none;
    width: 20px !important;
    height: 20px !important;
    vertical-align: middle !important;
    margin: -4px 5px 0px 0 !important; }
  .frm_forms form .blue-heading > .frm_primary_label {
    font-weight: 600 !important;
    color: #005170 !important; }
  .frm_forms form .frm_opt_container .frm_checkbox {
    margin-bottom: 2rem; }
  .frm_forms form .frm_primary_label {
    font-size: 16px !important;
    font-weight: 600;
    color: #08384D !important;
    margin-bottom: 8px; }
  .frm_forms form .frm_submit {
    margin-top: 6rem; }
    .frm_forms form .frm_submit [type="submit"] {
      font-weight: 600 !important;
      background: #08384D !important;
      border: none !important;
      font-size: 16px !important;
      display: flex;
      align-items: center; }
      .frm_forms form .frm_submit [type="submit"]:hover {
        background: #00BAE1 !important;
        color: #005170 !important; }
        .frm_forms form .frm_submit [type="submit"]:hover.frm_button_submit:after, .frm_forms form .frm_submit [type="submit"]:hover.frm_prev_page:before {
          background-image: url("../assets/images/arrow_forward_24_deepblue.svg"); }
    .frm_forms form .frm_submit .frm_button_submit:after {
      content: '';
      background-image: url("../assets/images/arrow_forward_24_white.svg");
      display: flex;
      height: 24px;
      width: 24px;
      margin-left: 8px; }
    .frm_forms form .frm_submit .frm_prev_page {
      color: #fff !important;
      display: flex !important;
      text-transform: none !important;
      padding: 10px 20px !important; }
      .frm_forms form .frm_submit .frm_prev_page:before {
        content: '';
        background-image: url("../assets/images/arrow_forward_24_white.svg");
        transform: rotateY(180deg);
        display: flex;
        height: 24px;
        width: 24px;
        margin-right: 8px; }
  .frm_forms form .frm_section_heading h3 {
    font-size: 4.8rem !important;
    line-height: 116.66%;
    color: #08384D !important;
    border-bottom: 1px solid #08384D !important; }
  .frm_forms form .frm_html_container strong.color {
    display: block;
    margin-top: 4rem; }
  .frm_forms form .separated-header .frm_primary_label {
    margin: 3rem 0; }
  .frm_forms form .frm_radio {
    height: 88px;
    display: flex;
    align-items: center;
    background-color: #fff;
    width: 420px;
    padding: 32px 20px;
    border: 1px solid #000;
    border-radius: 8px;
    margin-bottom: 20px !important; }
    .frm_forms form .frm_radio > label {
      display: flex;
      align-items: center; }
    .frm_forms form .frm_radio.blue-border {
      border: 1px solid #00BAE1;
      background-color: #fff;
      background: rgba(0, 186, 225, 0.1); }
    .frm_forms form .frm_radio:hover {
      border: 2px solid #00BAE1;
      background: rgba(0, 186, 225, 0.1); }
    @media (max-width: 1539.98px) {
      .frm_forms form .frm_radio {
        width: 303px; } }

.frm_forms .frm_page_bar input {
  color: #525252 !important;
  background-color: #DCDCDC !important; }

.with_frm_style .frm_radio input[type=radio]:checked {
  border: 1px solid #00BAE1; }

.with_frm_style .frm_input_group {
  flex-wrap: nowrap !important; }

.with_frm_style .frm_inline_box {
  color: #fff !important;
  background-color: #08384D !important; }

body.page-template-template-form .big-form .frm_fields_container .frm_form_field .frm_primary_label {
  position: relative;
  transition: padding-left 0.5s ease;
  transition-delay: 1s; }
  body.page-template-template-form .big-form .frm_fields_container .frm_form_field .frm_primary_label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    transform: scale(0);
    background-image: url(/wp-content/themes/rouge-wp-start-theme/assets/images/valid-input.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.35s ease;
    transition-delay: 0.5s; }

body.page-template-template-form .big-form .frm_fields_container .frm_form_field.valid-input .frm_primary_label {
  padding-left: 32px;
  transition-delay: 0s; }
  body.page-template-template-form .big-form .frm_fields_container .frm_form_field.valid-input .frm_primary_label + input {
    border-color: #2A6508; }
  body.page-template-template-form .big-form .frm_fields_container .frm_form_field.valid-input .frm_primary_label:before {
    transform: scale(1) !important;
    background-image: url(/wp-content/themes/rouge-wp-start-theme/assets/images/valid-input.svg) !important; }

body.page-template-template-form .big-form .frm_fields_container .frm_form_field.frm_blank_field .frm_primary_label {
  padding-left: 32px;
  transition-delay: 0s; }
  body.page-template-template-form .big-form .frm_fields_container .frm_form_field.frm_blank_field .frm_primary_label + input {
    border-color: var(--color-red);
    color: #636363;
    border-width: 2px; }
  body.page-template-template-form .big-form .frm_fields_container .frm_form_field.frm_blank_field .frm_primary_label:before {
    transform: scale(1);
    background-image: url(/wp-content/themes/rouge-wp-start-theme/assets/images/invalid-input.svg);
    animation: shake 0.25s;
    animation-delay: 0.75s;
    animation-iteration-count: 1; }

@keyframes shake {
  0% {
    transform: translate(0.25px, 0.25px) rotate(0deg); }
  10% {
    transform: translate(-0.25px, -0.5px) rotate(-1deg); }
  20% {
    transform: translate(-0.75px, 0px) rotate(1deg); }
  30% {
    transform: translate(0.75px, 0.5px) rotate(0deg); }
  40% {
    transform: translate(0.25px, -0.25px) rotate(1deg); }
  50% {
    transform: translate(-0.25px, 0.5px) rotate(-1deg); }
  60% {
    transform: translate(-0.75px, 0.25px) rotate(0deg); }
  70% {
    transform: translate(0.75px, 0.25px) rotate(-1deg); }
  80% {
    transform: translate(-0.25px, -0.25px) rotate(1deg); }
  90% {
    transform: translate(0.25px, 0.5px) rotate(0deg); }
  100% {
    transform: translate(0.25px, -0.5px) rotate(-1deg); } }

.frm_rootline_single:nth-child(1) {
  align-items: flex-start !important; }
  .frm_rootline_single:nth-child(1) > input {
    margin: 0 30px !important; }
    @media (max-width: 767.98px) {
      .frm_rootline_single:nth-child(1) > input {
        margin: 0 5px !important; } }

.frm_rootline_single:nth-child(3) {
  align-items: flex-end !important; }
  .frm_rootline_single:nth-child(3) > input {
    margin: 0 20px !important; }
    @media (max-width: 767.98px) {
      .frm_rootline_single:nth-child(3) > input {
        margin: 0 5px !important; } }

.frm_rootline.frm_show_lines > .frm_rootline_single:after {
  left: 20% !important;
  width: 160% !important; }
  @media (max-width: 767.98px) {
    .frm_rootline.frm_show_lines > .frm_rootline_single:after {
      left: 40% !important;
      width: 125% !important; } }

.frm_message > h4 {
  font-weight: 800;
  color: #005170;
  text-align: left !important;
  border-bottom: 1px solid #005170;
  padding-bottom: 16px;
  margin-bottom: 32px;
  text-transform: uppercase; }

.frm_message > p:first-of-type {
  text-align: left;
  margin-bottom: 80px; }
