// Testimonials
// -----------------------------------------------------------------------------

.testimonial-block {
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 16px;
	color: #fff;
	padding: 210px 450px;
	margin-top: 0;

	&__wrapper {
		margin-bottom: 180px;

		@include media-breakpoint-down(md) {
			margin-bottom: 100px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 80px;
		}
	}

	&__overlay {
		background: linear-gradient(278.03deg, rgba(27, 27, 51, 0.8) 0%, rgba(0, 81, 112, 0.8) 98.79%);
		background-blend-mode: multiply;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		@include media-breakpoint-down(sm) {
			background: none;
		}
	}

	&__content {
		padding: 0x 0;
		text-align: center;

		@include media-breakpoint-down(sm) {
			padding-top: 4rem;
		}
	}

	&__image {
		position: absolute;
		z-index: 2;
		right: 0;
		height: 100%;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 10rem;
			mix-blend-mode: multiply;
			background: $green;
		}

		&:before {
			content: "";
			background: url('../assets/images/testimonial-line-mask.png') no-repeat right bottom;
			height: 394px;
			width: 209px;
			display: block;
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: 1px;
			transition: all .4s;
			transition-delay: 0.6s;
		}

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	blockquote {
		color: $mint;
		text-align: center;

		.bg--green & {
			color: $navy;
		}

		.quote {
			font-size: 3.2rem;
			text-transform: uppercase;
			font-weight: 800;
		}

		@include media-breakpoint-down(sm) {
			color: $deep-blue;
		}

	}

	&--slider {
		padding: 0;
		margin: 0 auto;
		blockquote {
			margin-bottom: auto;

			+p {
				margin-top: 5rem;
				overflow-wrap: break-word;
				display: flex;
				align-items: flex-end;
				color: $navy;
			}
		}

		.blockquote {
			padding: 0 15px;
			height: 100%;

			@include media-breakpoint-down(sm) {
				padding-right: 0;
			}

			>div {
				padding: 3.5rem 3.5rem 2.5rem;
				height: 100%;
				display: flex;
				flex-direction: column;
			}
		}

		.slick-list {
			margin-left: -15px;
			margin-bottom: 2.5rem;
		}

		.slick-slide {
			>div {
				height: 100%;
			}
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&--standard {
		&.testimonial-block--standard--animated {
			.testimonial-block__image {
				&:before {
					left: -209px;
				}
			}
		}
	}

	.line-mask {
		position: absolute;
	    z-index: 3;
	    top: 0;
	    right: -240px;
	    left: unset;
	    height: 100%;
	    transform: rotateY(180deg);
	    overflow: hidden;
	    scale: 1.5;
	}

	@include media-breakpoint-down(lg) {
		padding: 105px 225px;
	}

	@include media-breakpoint-down(md) {
		padding: 55px 110px;
	}

	@include media-breakpoint-down(sm) {
		background-image: none!important;
		padding: 0;
	}
}

.post-content blockquote p {
	margin-bottom: 1em !important;
}

.blockquote {

	img,
	svg {
		width: 53px;
		margin-bottom: 32px;
		
		@include media-breakpoint-down(sm) {
			path {
				fill: $deep-blue;
			}
		}
		
	}

	&__author {
		margin-bottom: 0;
		text-align: center;
	}

	.bg--green {
		> blockquote {
			color: $navy;
		}
	}

	
}