//******************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//******************************************************************************

.main-nav {
    .navbar-brand {
        padding: 0;

        @include media-breakpoint-down(md) {
            margin-right: 37rem;

            .logged-in & {
                margin-right: 25rem;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-right: 58px;

            .logged-in & {
                margin-right: 35px;
            }
        }
    }

    .navbar {
        width: 100%;
        position: initial;
        justify-content: flex-start;

        .navbar--primary {
            height: 100%;
            align-items: baseline;
            column-gap: 56px;

            // MAIN NAVIGATION
            .menu-item {
                // MAIN LINK
                &.menu-item--level-0 {

                    a {
                        text-decoration: none;
                        font-size: 1.8rem;
                        font-weight: 600;
                        line-height: 155.55%;
                        $color: $navy;

                        .modal {
                            color: #fff;
                        }

                        &:hover {
                            text-decoration: underline;

                            svg {
                                circle {
                                    fill: $font-clr;
                                }
                            }
                        }

                        img,
                        svg {
                            margin-right: 1rem;

                            path {
                                .modal-open & {
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    &.current_page_item,
                    &.current_page_parent {
                        > a {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .mobile-pull-out {
            display: none;

            > a {
                svg {
                    margin-right: 1rem;
                    path {
                        .modal-open & {
                            fill: #fff;
                        }
                    }
                }
            }

            .members-area-link {

                > svg {
                    margin-right: 0;
                }
                
            }

        }

        @include media-breakpoint-down(sm) {
            .mobile-pull-out {
                > a {

                    > p {
                        display: none;
                    }
                }
            }

            .mobile-hide {
                display: none;
            }
        }

        @include media-breakpoint-down(md) {
            .mobile-pull-out {
                position: revert;
                display: flex;
                margin-right: 24px;

                > a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;

                    > p {
                        font-weight: 600;
                        margin-bottom: 0;
                        margin-left: 8px;

                        .modal-open & {
                            color: #fff;
                        }
                    }
                }
            }

            .mobile-hide {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .dropdown {
            position: initial;

            &-menu {
                background: transparent;
                padding: 0;
                margin: 0;
                list-style: none;
                font-weight: 300;
                width: -webkit-fill-available;
                width: -moz-available;
                top: 99%;

                li,
                ul {
                    list-style: none;
                    font-size: 2.4rem;
                    flex-direction: column-reverse;
                }

                li:not(:last-of-type) {
                    margin-bottom: 1.4rem;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {

    }
}

.search,
.search-results {
    .current_page_parent {
        a {
            font-weight: 400 !important;
        }
    }
}

.modal-open {
    color: #fff;
}

.menu-cta {
    max-width: 85%;
    margin: 0 auto;
    > h2 {
        > a {
            font-size: 4.8rem!important;
            font-weight: 800!important;
            text-transform: uppercase;
        }
    }
}

@include media-breakpoint-up(lg) {
    .fixed-nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        width: -webkit-fill-available;
        width: -moz-available;
        padding: 6rem 0 7rem;

        &__heading {

            @include media-breakpoint-up(lg) {
                margin-left: calc(41.66667% + 1.5rem);
            }
        }

        .menu-item {
            padding: 0;
        }

        .nav-megamenu__menu {
            padding: 0;

            ul {
                padding: 0;
            }
        }

        .row {
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
            padding: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .fixed-nav {
        &__heading,
        h2 {
            display: none;
        }
    }

    #primary-navigation {
        background: $blue-dark;
        color: white;
        width: -webkit-fill-available;
        width: -moz-available;
        position: absolute;
        top: 100%;
        left: 0;
        height: calc(100vh - 145px) !important;
        overflow: scroll;

        &>div {
            padding: 4rem 3rem;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        .dropdown {
            &.show {
                .mega-dropdown-toggle {
                    border-bottom: 1px solid white;
                }
            }
        }

        .dropdown-menu {
            background: transparent;
            padding: 0;
            margin: 0;
            color: inherit;
            border: none;
            font-size: 18px;
            display: none;

            ul {
                margin-top: 1rem;
                margin-left: 0;
            }
        }

        .col-lg-7 {
            padding: 0;
            margin: 0;
        }

        ul {
            margin: 0;
        }

        .nav-megamenu__menu {
            margin-bottom: 2rem;
        }

        ul,
        li {
            list-style: none;
            padding: 0;
        }

        li {
            margin-bottom: 1rem;
            margin-right: 0;
            width: 100%;
            position: relative;
        }

        &>div>ul {
            > li {
                border-bottom: 1px solid #fff;
                padding-bottom: 20px;
                padding-top: 20px;

                 &:after {
                    content: ">";
                    margin-left: 7px;
                    position: absolute;
                    right: 0;
                    top: 20px;
                    transition: all 0.6s ease;
                }

                &.open {
                    
                    &:after {
                        transform: rotateZ(90deg);
                    }
                    
                }

            }

        }
    }

    
}

// MAIN NAVIGATION TOGGLE
.navbar-toggler {
    display: none !important;
    @include media-breakpoint-between(xs, md) {
        padding: 0;
        border: 0;
        border-radius: 0;
        margin: auto 0;
        height: 3rem;
        top: 6.8rem;
        right: 4.5rem;
        display: block !important;
        .navbar-toggler-icon {
            position: relative;
            display: inline-block;
            width: 2rem;
            height: 0.25rem;
            background: $blue-dark;
            transition: all .2s ease-in-out;
            .modal-open & {
                background: #fff;
            }
            &:before,
            &:after {
                background: $blue-dark;
                content: '';
                display: block;
                width: 2rem;
                height: 0.25rem;
                position: absolute;
                transition: all .2s ease-in-out;
                .modal-open & {
                    background: #fff;
                }
            }
            &:before {
                top: -0.9rem;
            }
            &:after {
                top: 0.9rem; 
            }
        }
        &[aria-expanded="true"]{
            .navbar-toggler-icon {
                background: transparent;
                &:before,
                &:after {
                    top: 0;
                }
                &:before {    
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &:after {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }  
        }

        &:hover {
            background: transparent;
        }
    }
}

.single-business_groups {
    .main-nav .navbar .navbar--primary .menu-item.menu-item--level-0.current_page_parent>a {
        font-weight: 400;
    }

    .main-nav .navbar .navbar--primary .menu-item.menu-item-685>a {
        font-weight: 600;
    }
}