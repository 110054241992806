@import "../bootstrap_overrides/_variables";

// Quick links
// -----------------------------------------------------------------------------

.quick-links {
	margin-top: 10rem;
	margin-bottom: 11rem;
	position: relative;

	&:before {
		content: "";
		background: rgba($font-clr, .1);
		bottom: -1rem;
		left: 0;
		height: calc(50% + 3rem);
		width: 100%;
		display: block;
		position: absolute;

		@include media-breakpoint-down(md) {
			height: 75%;
		}

		@include media-breakpoint-down(sm) {
			height: 91%;
		}
	}

	&-section {
		&__title {
			color: $mint;
			text-transform: uppercase;
			margin-bottom: 48px;
			font-weight: 800;
		}
	}

	&__title {
		color: $mint;
		margin-bottom: 16px;
		min-height: 56px;
		display: block;
		font-weight: 600;

		.quick-links__top & {
			margin-bottom: 50px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
	}

	&__description {
		color: #fff;
		> p {
			color: #fff;
		}
		
	}

	&__inner {
		padding: 0;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 4rem;
		
	}

	&__row-item {
		max-width: calc(25% - 20px);
		flex-basis: calc(25% - 20px);
		margin-right: 25px;
		// height: 294px;
		height: 220px;

		&:last-of-type {
			margin-right: 0;
		}

		.single-members & {
			height: 345px;
		}

		@include media-breakpoint-between(sm, lg) {
			display: flex;
		}

		@include media-breakpoint-down(md) {
			max-width: calc(50% - 20px);
			flex-basis: calc(50% - 20px);

			&:nth-of-type(2) {
				margin-right: 0;
				margin-bottom: 30px;
			}

		}

		@include media-breakpoint-down(sm) {
			max-width: 100%;
			flex-basis: 100%;
			margin-bottom: 30px;
			margin-right: 0;
			height: 175px;
		}
	}

	&__link {
		padding: 32px 25px;
		position: relative;
		height: auto;
		width: 100%;
		height: 100%;

		.single-members & {
			border-radius: 0;
		}

		.search & {
			> a {
				color: #fff!important;
			}
		}


		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
			padding: 20px;
		}

		> h3 {
			margin-bottom: 36px;
			font-size: 3rem;
		}

		> p {
			color: #fff;
			margin-bottom: 90px;
		}

		.why-nuclear,
		.what-is-nuclear-energy,
		.people-in-nuclear,
		.downloads,
		.industry-opportunities {
			color: #fff!important;
		}
		
		a {
			text-decoration: none;
			display: flex;
			color: $navy;
			align-items: center;
			width: fit-content;
			position: absolute;
			bottom: 32px;
			transition: all 0.6s ease;



			.single-members & {
				color: #fff;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				height: 100%;
				width: 100%;
				display: block;
				background: 0 0;
			}

			@include media-breakpoint-down(sm) {
				position: relative;
				bottom: 0;
			}

		}

		&__lines {
			position: absolute;
			bottom: 0;
			right: 0;
			height: 75%;
			width: 10rem;
			max-height: 24rem;
			overflow: hidden;

			img {
				position: absolute;
				bottom: 0;
				right: 0;
				height: 100%;
				mix-blend-mode: overlay;
			}
		}
	}

	@media(max-width: 1345px) {
		h3 {
			margin-bottom: 3rem ;
		}
	}

	@include media-breakpoint-down(xl) {
		h3 {
			@include fluid-type(2.4rem, 3rem);
		}

		a {
			font-size: 1.6rem;
		}
	}

	&-bottom {
		&__wrapper {
			margin-top: 80px;
			padding-top: 80px;
			padding-bottom: 80px;
			border-radius: 8px;
			// @include media-breakpoint-down(md) {
			// 	margin-top: 4rem;
				
			// 	&__row-item:nth-of-type(4) {
			// 		display: none;
			// 	}
			// }
		}
	}
}