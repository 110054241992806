//******************************************************************************
// TYPOGRAPHY
//******************************************************************************

h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon,
.zeta {
	font-weight: 300;
	margin-bottom: 0;
	//overflow-wrap: break-word;
}

.display-1 {
	font-size: 9.6rem;
	line-height: 100%;
}

.display-2 {
	font-size: 8rem;
	line-height: 100%;

	@include media-breakpoint-down(md) {
        font-size: 4.4rem;
    }
}

.display-3 {
	font-size: 7.2rem;
	line-height: 105.55%;
}

h1,
.alpha {
	font-size: 6.4rem;
	line-height: 106.25%;
	@include fluid-type(3.6rem, 5rem);
	
}

h2,
.beta {
	font-size: 5.6rem;
	line-height: 107.14%;
	font-weight: 300;

	&--bold {
		font-weight: 800;
	}

	&:hover {
		a {
			text-decoration: none !important;
		}
	}
}

.home-hero-slider__header {
	text-transform: uppercase;
	@include fluid-type(3.6rem, 8rem);
	font-weight: 800;
	color: #DCF2E3;

	strong {
		font-weight: 800;
	}
}

h3,
.gamma {
	font-size: 4.8rem;
	line-height: 116.66%;
	font-weight: 600;

	&--bold {
		font-weight: 800;
	}

	&.heading {
		font-weight: 800;
	}

	& strong {
		font-weight: 800;
	}

	&.post-heading {
		font-weight: 600;
		@include fluid-type(2rem, 2rem);
	}

	@include media-breakpoint-down(md) {
        font-size: 3.6rem;
    }
}

h4,
.delta {
	font-size: 3.2rem;
	font-weight: 600;
	line-height: 125%;
}

h5,
.epsilon {
	font-size: 2.8rem;
	line-height: 128.57%;
	@include fluid-type(1.8rem, 2.8rem);
	line-height: 1.2;
}

h6,
.zeta {
	font-size: 2.4rem;
	line-height: 133.33%;
}

.font-size--twenty {
	@include fluid-type(2rem, 2rem);
}

h7, 
.jones {
	font-size: 2rem;
	line-height: 140%;
}

.p {
	font-size: 1.8rem;

	&-middle {
		line-height: 1.3;
	}

	&-max {
		max-width: 60ch;
	}

	&--large {
		@include fluid-type(2rem, 2.4rem);
	}
}

.content-heading {
	margin-bottom: 24px;
}

.standfirst {
	font-size: 2.4rem;
	line-height: 133.33%;
}

.caption {
	font-size: 1.4rem;
	line-height: 142.85%;
	font-weight: 700;
}

.body-large {
	font-size: 1.8rem;
	line-height: 111.11%;
}

.body {
	font-size: 1.6rem;
	line-height: 150%;
}

.body-bold {
	font-size: 1.6rem;
	line-height: 150%;
}

.searchresults-eyebrow {
	color: $steel!important;
	font-size: 12px!important;
	font-weight: 700!important;
	line-height: 15px!important;
	margin-bottom: 16px!important;
}

.font-weight {
	&--semibold {
		font-weight: 600;
	}

	&--bolder {
		font-weight: 700;
	}
}

.post-content {
	p {
		strong {
			color: $blue-dark;
		}
	}

	ul,
	ol {
		padding-left: 2rem;
		margin-bottom: 4rem;

		ul,
		ol {
			padding-left: 4rem;
		}
	}

	ol {
		list-style: none;
		counter-reset: numbers;
		padding-left: 32px;

		li {
			counter-increment: numbers;

			&:before {
				content: counters(numbers, ".") ". ";
			}
		}
	}

	blockquote {
		@extend .zeta;
		color: $navy;
		p {
			font-weight: 600;
			text-indent: -0.5em;
			
		}
	}
}