@import "../bootstrap_overrides/_variables";
@import '../modules/_feature-section.scss';

.home-hero-slider {
	position: relative;

	.slick-dots {
		right: 7rem;
		bottom: 4rem;
		position: absolute;

		@media(max-width: 767px) {
            display: none!important;
        }
	}

	.container {
		position: relative;
		z-index: 1;
		padding-top: 142px;
		padding-bottom: 60px;
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&__overlay {
		background: radial-gradient(100% 709.26% at 100% 100%, rgba(27, 27, 51, 0.5) 0%, rgba(0, 81, 112, 0.5) 100%);
		background-blend-mode: multiply;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.eyebrow {
		font-size: 1.2rem !important;
		line-height: 125%;
		font-weight: 700;
	}

	&__header {
		&.alpha {
			margin-bottom: 64px;
			max-width: 16ch;
		}
	}

	.promo-button {
		margin-top: 12px;
	}

	.post-card {
		min-width: 300px;
		max-width: 420px;
	}

	.scroll-down {
		color: white;
		writing-mode: vertical-lr;
		line-height: 1;
		text-decoration: none;
		text-transform: uppercase;
		position: absolute;
		bottom: 23rem;
		right: 7rem;

		svg {
			vertical-align: baseline;
			margin-top: 10px;
			border-radius: 50%;
		}

		&:hover {
			svg {
				[fill="none"] {
					path {
						fill: $blue-dark;
					}
				}
			}
		}
	}

	.line-mask {
		img {
			height: 100%;
			width: 75%;
			transition-delay: 1s;
		}
	}

	@media(max-width: 768px) {
		.scroll-down {
			display: none;
		}

		.home-hero-slider__promo {
			display: none !important;
		}

		.home-hero-slider__header {
			margin-bottom: 0;
		}

		.container {
			padding-top: 16rem;
			padding-bottom: 16rem;
		}
	}
}