// PAGES
// -----------------------------------------------------------------------------

.map-block {
	margin-top: 20rem;

	#map {
		margin: -10rem 0 10rem;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 10rem;

		#map {
			margin: -4rem 2rem 4rem;
			height: 30rem !important;
		}
	}
}

.contact-sections {
	margin-top: 10rem;

	@include media-breakpoint-down(lg) {
		margin-top: 6rem;
	}

	&__details {
		margin-left: 0;
		margin-right: 0;
	}

	&__section {
		padding-left: 3rem;
		border-left: 1px solid $font-clr;
		min-width: 28rem;

		&:nth-of-type(1) {
			padding-left: 0;
			border-left: 0;
			min-width: auto;
		}

		@include media-breakpoint-down(md) {
			&:nth-of-type(1) {
				padding-left: 3rem;
				border-left: 1px solid $font-clr;
			}
		}

		@include media-breakpoint-only(md) {
			min-width: 50%;
			max-width: 50%;

			&:nth-of-type(1),
			&:nth-of-type(2) {
				margin-bottom: 3rem;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.col {
			min-width: 100%;
		}

		.row {
			flex-direction: column;
			align-items: center;
		}

		&__section {
			margin-top: 3rem;
			padding-left: 15px;
			min-width: 0 !important;
			width: 28rem;
		}
	}
}

.error404 {
	.hero {
		padding-top: 26rem;
		padding-bottom: 10rem;

		@include media-breakpoint-down(lg) {
			padding-top: 8rem;
			padding-bottom: 8rem;
		}
	}

	.hero__error404 {
		a {
			margin-top: 12rem;
		}
	}
}

.page-template-template-general-content {
	.hub-section {
		margin-bottom: 8rem;

		@include media-breakpoint-down(lg) {
			margin-bottom: 4rem;
		}
	}
}