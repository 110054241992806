// Block header
// -----------------------------------------------------------------------------

.block-header {
	margin-bottom: 48px;
	
	&__line {
		flex-shrink: 0;
		flex-basis: 100%;
		height: 1px;
		display: block;
		margin: 0 0 17px 0;
	}

	&__extra {
		font-weight: 700;
		color: $steel;
		font-size: 12px;
		text-transform: uppercase;
		margin-bottom: 24px;
		max-width: none;
		letter-spacing: 2px;
	}

	.heading {
		flex-grow: 0;
		flex-basis: 65%;
		margin-bottom: 16px;
		text-transform: uppercase;
	}

	.p-large {
		flex-grow: 0;
		flex-basis: 35%;
		margin-top: 0;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 3rem;
		
		.heading {
			flex-basis: 100%;
			flex-grow: 1;
			margin-bottom: 2rem;
			order: 1;
		}

		.btn {
			order: 1;
		}

		.p-large {
			flex-basis: 100%;
			flex-grow: 1;
			order: 3;
		}

		.block-header__extra {
			order: 3;
		}

		.block-header__line {
			order: 2;
		}
	}
}