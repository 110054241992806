// Accordion
// -----------------------------------------------------------------------------

.accordion {
	margin-top: 3rem;
	padding-bottom: 8rem;

	&__header {
		width: 100%;
		border: none;
		background: none;
		padding: 3rem 6rem 3rem 0;
		position: relative;
		text-align: left;
		color: $font-clr;

		&:hover {
			background: #F5F5F5;
		}

		&:before,
		&:after {
			content: "";
			height: 1px;
			width: 4.5rem;
			display: block;
			background: #707070;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			will-change: transform;
			transition: transform .2s ease-in;
		}

		&:after {
			transform: rotate(90deg);
		}
	}

	&__content {

		> *:first-of-type {
			margin-top: 0;
		}

		> *:last-of-type {
			margin-bottom: 0;
			padding-bottom: 3rem;
		}
	}

	&__row {
		border-top: 1px solid currentColor;

		&:last-of-type {
			border-bottom: 1px solid currentColor;
		}

		&[aria-expanded="true"] {
			.accordion__header {
				&:after {
					transform: rotate(0);
				}
			}
		}
	}
}