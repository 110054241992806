//******************************************************************************
// SLIDER
// Carousel styles
//******************************************************************************

.slick-slider {
	.slick-track {
		display: flex;
		height: auto;
	}

	.slick-slide {
		height: auto;

		> div {
			height: 100%;
		}
	}

	button.slick-arrow {
		background-color: transparent;
		font-size: 0;
		padding: 0;
		border: none;
		position: absolute;
		z-index: 1;
		top: 50%;
		transform: translate(0, -50%);
		cursor: pointer;

		&:hover {
			opacity: 0.7;
			background-color: transparent;
		}

		&:before {
			content: "";
			font-size: 2rem;
			color: white;
			padding: 1rem;
			display: block;
			line-height: 1;
			height: 2.4rem;
			width: 2.4rem;
		}

		&.slick-prev {
			left: -9rem;

			&:before {
				content: "";
				background: url('../assets/images/arrow_forward_slider.svg') no-repeat center center / 24px;
				transform: rotate(180deg);
			}
		}

		&.slick-next {
			right: -9rem;

			&:before {
				content: "";
				background: url('../assets/images/arrow_forward_slider.svg') no-repeat center center / 24px;
				
			}
		}

		@include media-breakpoint-down(md) {
			display: none !important;
		}
	}

	.slick-dots {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		justify-content: center;

		li:not(:last-of-type) {
			margin-right: 15px;
		}

		button {
			background: transparent;
			padding: 0;
			margin: 0;
			line-height: 1;
			font-size: 0;
			border-radius: 50%;
			border: 1px solid white;
			height: 10px;
			width: 10px;
		}

		.slick-active {
			button {
				background: white;
			}
		}
	}
}

#testimonial-block__slider {
	.slick-list {
		// padding: 0 10% 0 0 !important;

		@include media-breakpoint-down(sm) {
			padding: 0 !important;
		}
	}
}

.hub-section {
	&__body {
		margin-top: 8rem;

		@include media-breakpoint-down(sm) {
			max-width: 100%;
			padding-bottom: 24px;
		}

		@include media-breakpoint-only(md) {
			max-width: 100%;
			padding: 0 3rem;
			padding-bottom: 24px;
		}

		body.single & {
			margin-bottom: 6rem;
		}
	}

	.slick-slider {
		margin-top: -8rem;

		&.slick-dotted {
			padding-bottom: 0;
		}
	}

	.slick-list {
		margin-left: -15px;
		margin-right: -15px;
	}

	.slick-slide {
		padding: 0 15px;
		display: flex;

		>div {
			display: block;
			height: 100%;
		}

		img {
			width: 100%;
			border-radius: 8px 8px 0 0;
		}
	}

	.slick-dots {
		padding: 4rem 0 5rem;

		@include media-breakpoint-down(md) {
			display: none!important;
		}
	}
}